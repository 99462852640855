import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PhalaWalletsSummary from '../phalaWallets/phalaWalletsSummary';
import PhalaWalletsTable from '../phalaWallets/phalaWalletsTable';
import {
  updateSelAccount,
  getAccountDetails,
  setShowOrgs
} from '../phalaSlice';
import { doAddAccount, doAddOrgAccess } from '../../modal/modalSlice';

const PhalaStakingContainer = () => {
  const dispatch = useDispatch();
  const [activeView, setActiveView] = useState('progressBars'); // Default view
  let contentView;
  switch (activeView) {
    case 'progressBars':
      contentView = <PhalaWalletsSummary />;
      break;
    case 'tableView':
      contentView = <PhalaWalletsTable />;
      break;
    default:
      contentView = <PhalaWalletsSummary />;
  }
  const addAccountClick = () => {
    dispatch(doAddAccount());
  };

  const addAccessClick = () => {
    dispatch(doAddOrgAccess());
  };
  return (
    <div className="account-card" style={{ position: 'relative' }}>
      <div
        className="d-flex justify-content-end pt-1"
        style={{ position: 'absolute', top: 0, right: 0, zIndex: 1000 }}
      >
        {activeView !== 'progressBars' && (
          <button className="btn" onClick={() => setActiveView('progressBars')}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-bar-chart-steps"
              viewBox="0 0 16 16"
            >
              <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
            </svg>
          </button>
        )}
        {activeView !== 'tableView' && (
          <button className="btn" onClick={() => setActiveView('tableView')}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-table"
              viewBox="0 0 16 16"
            >
              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z" />
            </svg>
          </button>
        )}
        <Dropdown>
          <Dropdown.Toggle
            variant="white"
            id="dropdown-basic"
            className="btn no-caret"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-three-dots-vertical"
              viewBox="0 0 16 16"
            >
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
            </svg>
          </Dropdown.Toggle>

          <Dropdown.Menu className="custom-dropdown-menu">
            <Dropdown.Item href="#/action-1" onClick={() => addAccountClick()}>
              Add Wallet
            </Dropdown.Item>
            <Dropdown.Item href="#/action-2" onClick={() => addAccessClick()}>
              Add Member
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {contentView}
    </div>
  );
};
export default PhalaStakingContainer;
