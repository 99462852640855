import React, {useState } from 'react';


// Import charts, all with Chart suffix
import { useDispatch, useSelector } from 'react-redux';
import { setWorkerChart } from '../phalaSlice';

import Tooltip from '@mui/material/Tooltip';

const WorkerWidgets = () => {
    const dispatch = useDispatch();
    

    const { aprByLevel , rewardsByHour,} = useSelector(
        store => store.phala
      );
      const avgAprLvlWidget = aprByLevel.reduce((acc, cur) => acc + cur.averageApr, 0) / aprByLevel.length;
      const lvl1RewardWidget = rewardsByHour.reduce((acc, cur) => acc + cur.rewardsLevel1, 0);
      const lvl2RewardWidget = rewardsByHour.reduce((acc, cur) => acc + cur.rewardsLevel2, 0);
      const lvl3RewardWidget = rewardsByHour.reduce((acc, cur) => acc + cur.rewardsLevel3, 0);
      const lvl4RewardWidget = rewardsByHour.reduce((acc, cur) => acc + cur.rewardsLevel4, 0);
      const lvl5RewardWidget = rewardsByHour.reduce((acc, cur) => acc + cur.rewardsLevel5, 0);
      const totalRewardByLvl = lvl1RewardWidget + lvl2RewardWidget + lvl3RewardWidget + lvl4RewardWidget + lvl5RewardWidget;
   
      const setWorkerChartHandler = (chartWorker) => {
        dispatch(setWorkerChart(chartWorker));
      };
   
    return (
        <section class='ms-5'>
        <Tooltip title="Select card for additional data." placement="top"><h1 className='text-center mb-3'>Worker Data<svg xmlns="http://www.w3.org/2000/svg" width="10"fill="currentColor" class="bi bi-info-circle mt-3 ms-1" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg></h1></Tooltip>
        <div className='row text-center d-flex justify-content-start '>
            <div className='col-5 card p-0 me-4 col-md-4 col-sm-5 standard-card m-0' onClick={() => setWorkerChartHandler("Apr")}>
            <div className='row card-body'>
                <Tooltip title="Calculated based on rewards received by active workers. Workers that are unresponsive are not included in the APR calculation." placement="top" className="header-subtle">
                    <h6><small>Average APR</small>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10"fill="currentColor" class="bi bi-info-circle mt-1 ms-1" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                    </h6>
                </Tooltip>
                <div className='col'>
                    <h6></h6>
                    <h3>{Number((avgAprLvlWidget).toFixed(2)).toLocaleString("en-US")  + "%"}</h3>
                </div>
                </div>
                <p class='m-0 d-flex justify-content-end pe-3 pb-1 text-success'>More<svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" fill="currentColor" class="bi bi-arrow-right ms-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></p>
            </div>
            <div className='col-6 card p-0 col-lg-6 col-md-5 col-sm-5 standard-card m-0' onClick={() => setWorkerChartHandler("Rewards")}>
                
            <div className='row card-body'>
                <Tooltip title="Total PHA distributed as rewards for the selected range." placement="top" className="header-subtle">
                    <h6><small>Rewards</small>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10"fill="currentColor" class="bi bi-info-circle mt-1 ms-1" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                    </h6>
                </Tooltip>
                <div className='col'>
                    
                    <h3>{Number((totalRewardByLvl).toFixed(0)).toLocaleString("en-US")}<sub class="ps-1 text-secondary"><small>PHA</small></sub></h3>
                    
                </div>
                </div>
                <p class='m-0 d-flex justify-content-end pe-3 pb-1 text-success'>More<svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" fill="currentColor" class="bi bi-arrow-right ms-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></p>
            </div>

        </div>
        </section>
    );
};
    export default WorkerWidgets;