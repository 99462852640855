import React, { useEffect, useState } from 'react';

import { ButtonGroup, Button } from 'react-bootstrap';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAprByStake,
  getAprByLevel,
  setAprChartType,
  setWorkerChartTitle
} from '../../phalaSlice';
import AprByLevel from '../apr/phala.widget.AprByLevel';

const AprByStake = () => {
  const dispatch = useDispatch();
  const [categoryArray, setCategory] = useState([]);

  const [Stake1Array, setStake1] = useState([]);
  const [Stake2Array, setStake2] = useState([]);
  const [Stake3Array, setStake3] = useState([]);
  const [Stake4Array, setStake4] = useState([]);
  const [Level1Array, setLevel1] = useState([]);
  const [Level2Array, setLevel2] = useState([]);
  const [Level3Array, setLevel3] = useState([]);
  const [Level4Array, setLevel4] = useState([]);
  const [Level5Array, setLevel5] = useState([]);
  const [AvgArray, setAvg] = useState([]);

  const [titleText, setTitle] = useState('24h');
  const [chartInterval, setChartInterval] = useState('24h');
  const [showAprByLevel, setShowAprByLevel] = useState(false);

  //let miningArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,24,24];

  const { aprByStake, aprByLevel, aprByStakeIsLoading, workerChartTitle } =
    useSelector(store => store.phala);

  const avgAprWidget =
    aprByStake.reduce((acc, cur) => acc + cur.averageApr, 0) / AvgArray.length;
  const stake1AprWidget =
    aprByStake.reduce((acc, cur) => acc + cur.apr_stake_lte5, 0) /
    Stake1Array.length;
  const stake2AprWidget =
    aprByStake.reduce((acc, cur) => acc + cur.apr_stake_5_10, 0) /
    Stake2Array.length;
  const stake3AprWidget =
    aprByStake.reduce((acc, cur) => acc + cur.apr_stake_10_15, 0) /
    Stake3Array.length;
  const stake4AprWidget =
    aprByStake.reduce((acc, cur) => acc + cur.apr_stake_gt15, 0) /
    Stake4Array.length;

  const avgAprLvlWidget =
    aprByLevel.reduce((acc, cur) => acc + cur.averageApr, 0) / AvgArray.length;
  const level1AprWidget =
    aprByLevel.reduce((acc, cur) => acc + cur.level1, 0) / Level1Array.length;
  const level2AprWidget =
    aprByLevel.reduce((acc, cur) => acc + cur.level2, 0) / Level2Array.length;
  const level3AprWidget =
    aprByLevel.reduce((acc, cur) => acc + cur.level3, 0) / Level3Array.length;
  const level4AprWidget =
    aprByLevel.reduce((acc, cur) => acc + cur.level4, 0) / Level4Array.length;
  const level5AprWidget =
    aprByLevel.reduce((acc, cur) => acc + cur.level5, 0) / Level5Array.length;

  const setAprChartHandler = AprChartType => {
    dispatch(setAprChartType(AprChartType));
  };

  const eChartsOption = {
    tooltip: {
      trigger: 'axis',
      position: 'bottom', // set the tooltip position to top
      offset: [0, 10], // set the vertical offset to 10 pixels
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: '#c8e2f7',
      textStyle: {
        color: '#000'
      }
    },
    toolbox: {
      feature: {},
      iconStyle: {
        borderColor: 'white'
      }
    },

    legend: {
      data: [
        'Average',
        'Less 5k',
        '5k - 10k',
        '10k - 15k',
        'Greater 15K',
        'Level 5'
      ],
      top: '25',
      inactiveColor: '#555555',
      top: 'auto',
      selected: {
        'Less 5k': false,
        '5k - 10k': false,
        '10k - 15k': false,
        'Greater 15K': false,
        'Level 5': false,
        Average: true
      },
      textStyle: {
        color: 'white'
      }
    },
    textStyle: {
      color: '#fff'
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },

    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          opacity: 0.08 // set the opacity of the line to 50%
        }
      },
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },
    grid: [
      {
        left: '10%',
        right: '5%',
        height: '70%'
      }
    ],
    series: [
      {
        name: 'Less 5k',
        data: Stake1Array,
        type: 'line',
        smooth: false,
        showSymbol: false,
        color: '#5C3ABD'
      },
      {
        name: '5k - 10k',
        data: Stake2Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#4FB3BF'
      },
      {
        name: '10k - 15k',
        data: Stake3Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#D1BE65'
      },
      {
        name: 'Greater 15K',
        data: Stake4Array,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#E88834'
      },
      {
        name: 'Average',
        data: AvgArray,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#4DBD74'
      }
    ]
  };
  useEffect(() => {
    console.log('data Changed');
    let s1 = [];
    let s2 = [];
    let s3 = [];
    let s4 = [];
    let l1 = [];
    let l2 = [];
    let l3 = [];
    let l4 = [];
    let l5 = [];
    let avg = [];
    let tcat = [];
    for (let m of aprByStake) {
      s1.push(parseInt(m.apr_stake_lte5));
      s2.push(parseInt(m.apr_stake_5_10));
      s3.push(parseInt(m.apr_stake_10_15));
      s4.push(parseInt(m.apr_stake_gt15));
      l1.push(parseInt(m.level1));
      l2.push(parseInt(m.level2));
      l3.push(parseInt(m.level3));
      l4.push(parseInt(m.level4));
      l5.push(parseInt(m.level5));
      avg.push(parseInt(m.averageApr));

      let dt = new Date(Math.floor(m.minTime));
      console.log(dt);
      //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
      if (chartInterval === 'hour') {
        const month = String(dt.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        const day = String(dt.getDate()).padStart(2, '0');
        const year = dt.getFullYear();

        let hours = dt.getHours();
        const minutes = String(dt.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = String(hours).padStart(2, '0');

        tcat.push(`${month}/${day}/${year}\n${hours}:${minutes} ${ampm}`);
      } else {
        tcat.push(
          dt.toLocaleString('en-us', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      }
    }

    setCategory(tcat);
    setStake1(s1);
    setStake2(s2);
    setStake3(s3);
    setStake4(s4);
    setLevel1(l1);
    setLevel2(l2);
    setLevel3(l3);
    setLevel4(l4);
    setLevel5(l5);
    setAvg(avg);
  }, [aprByStake]);

  useEffect(() => {
    let end = Math.floor(new Date().getTime());
    let start = Math.floor(end - 604800000); // 1 week
    let data = {};
    if (workerChartTitle === '24h') {
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
    }
    if (workerChartTitle === '7d') {
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
    }
    if (workerChartTitle === '30d') {
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
    }
    if (workerChartTitle === '90d') {
      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
    }
    dispatch(getAprByStake(data));
    dispatch(getAprByLevel(data));
  }, []);

  const handleSelect = e => {
    console.log(e);
    setChartInterval(e);
    let data = {};
    let end = Math.floor(new Date().getTime());
    if (e === '24h') {
      setTitle('24h');
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('24h');
    }
    if (e === '7d') {
      setTitle('7d');
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('7d');
    }
    if (e === '30d') {
      setTitle('30d');
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('30d');
    }
    if (e === '90d') {
      setTitle('90d');

      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('90d');
    }
    dispatch(getAprByStake(data));
    dispatch(getAprByLevel(data));

    dispatch(setWorkerChartTitle(e));
  };

  return (
    <section className="leftChartCard pb-0">
      <div className="row">
        <h3 className="text-start col-10 ps-4">Delegation APR</h3>
        <div className="col-2 d-flex justify-content-end">
          <ButtonGroup
            className="timeframe-btn-group active btn btn-group-sm-mobile"
            aria-label="Timeframe Selection"
          >
            <Button
              variant="outline-complementary "
              onClick={() => handleSelect('24h')}
              className={chartInterval === '24h' ? 'active' : ''}
            >
              24h
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('7d')}
              className={chartInterval === '7d' ? 'active' : ''}
            >
              7d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('30d')}
              className={chartInterval === '30d' ? 'active' : ''}
            >
              30d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('90d')}
              className={chartInterval === '90d' ? 'active' : ''}
            >
              90d
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div>
        {aprByStakeIsLoading === true ? (
          <div class="position-absolute bottom-50 end-50">
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        ) : (
          ''
        )}

        <div class="mt-3">
          {showAprByLevel ? (
            <AprByLevel />
          ) : (
            <ReactEChart option={eChartsOption} />
          )}
        </div>
      </div>
    </section>
  );
};

export default AprByStake;
