import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Icons} from 'react-bootstrap';
import '../../App.css';

import { doUserLogin, updateUserLogin } from './userSlice';
import { closeModal } from '../modal/modalSlice';

const Login = () => {
  const { userLogin, currentUser } = useSelector(store => store.user);
  const dispatch = useDispatch();

  const handleFormSubmit = e => {
    e.preventDefault();
    console.log('Before dispatch');
    dispatch(doUserLogin(userLogin));
    dispatch(closeModal());
    console.log(userLogin);
  };
  const handleFormChange = ({ currentTarget: input }) => {
    console.log(input.value);

    const login = {
      email: userLogin.email,
      password: userLogin.password
    };
    login[input.name] = input.value;
    //console.log(this.state);
    //this.setState({ login });
    console.log(login);
    dispatch(updateUserLogin(login));
  };

  return (
    <div className="app-header">
      <form onSubmit={handleFormSubmit}>
        <div class="input-group mb-3">
          <span class="input-group-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.5rem"
              fill="currentColor"
              class="bi bi-person-fill"
              viewBox="0 0 16 16" 
            >
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
            </svg>
          </span>
          <input
            value={userLogin.email}
            id="email"
            name="email"
            onChange={handleFormChange}
            type="text"
            placeholder="Email Address"
            class="form-control subtle-input pr-5"
          />
        </div>
        <div class="input-group mb-3 input-group-secondary">
          <span class="input-group-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.5rem"
              fill="currentColor"
              class="bi bi-key-fill"
              viewBox="0 0 16 16"
            >
              <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
            </svg>
          </span>
          <input
            value={userLogin.password}
            id="password"
            name="password"
            onChange={handleFormChange}
            type="password"
            placeholder="Password"
            class="form-control subtle-input"
          />
          <div className="d-flex justify-content-end w-100">
    <button type="button" className="btn btn-link btn-sm text-muted align-self-end small-text">
      Forgot Password?
    </button>
  </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
      <button type="submit" className="btn btn-success rounded-pill px-4 wide-button">
        Enter
      </button>
    </div>
    <div className="d-flex justify-content-center">
      <small className="text-muted mt-1 small-text">Don't have an account?</small>
      <button type="button" className="btn btn-link btn-sm text-muted text-primary small-text">
        <small>Register</small>
      </button>
    </div>
    
      </form>
    </div>
  );
};

export default Login;
