import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Dropdown, DropdownButton } from 'react-bootstrap';
import Header from '../../Header';
import '../../App.css';
import Footer from '../../footer';
import {
  getAllPhala,
  updateSelPhala,
  rightMenuShow,
  rightMenuHide,
  setSearchQuery,
  setSearchState,
  setLastTime,
  setShowOrgs,
  getOrgProfile,
  togglePhalaSelected,
  addAllPhalaSelected,
  removeAllPhalaSelected
} from './phalaSlice';
import { StyledEngineProvider } from '@mui/material';
import { doBulkCommand, doAddWorker } from '../modal/modalSlice';
import { MDBTooltip } from 'mdb-react-ui-kit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';
import { faGear, faPlus, faRefresh, faTerminal, faCheckDouble, faXmark } from '@fortawesome/free-solid-svg-icons';
import {
  phalaListColumns,
  phalaListColumnMd,
  phalaListColumnSm
} from './phalaListFields';
import { current } from '@reduxjs/toolkit';

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};
function truncString(str) {
  return String(str).substring(0, 4) + ' ... ' + String(str).slice(-5);
}
function diff_dates_sec(dt1, dt2) {
  var mdt = new Date(dt2);
  var mdt1 = new Date(dt1);
  var diff = mdt - mdt1; // / 1000;
  diff /= 3600;
  return Math.abs(Math.round(diff));
}
function diff_seconds(dt1) {
  var now = new Date();
  var dt = new Date(dt1);
  var diff = now - dt; // / 1000;
  diff /= 3600;
  return Math.abs(Math.round(diff));
}
// function that formats a decimal but returns a 0 if the value is null
function formatDecimal(value, decimals) {
  if (value === null) {
    return 0;
  } else {
    return value.toFixed(decimals);
  }
}

const PhalaList = sz => {
  let navigate = useNavigate();
  const targetRef = useRef();
  const [isRightSide, setisRightSide] = useState(false);
  const [phalaRow, setphalaRow] = useState({});
  const sideTop = String(window.pageYOffset + 30) + 'px';
  const slideWidth = '700px';
  const slideLeft = String(window.innerWidth - 700 - 17) + 'px';
  const [selectedHeading, setSelectedHeading] = useState(null);
  const [sortField, setSortField] = useState("serviceName");
  const [sortOrder, setSortOrder] = useState('asc');

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 767 });
    return isDesktop ? children : null;
  };
  const Midsize = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 500, maxWidth: 767 });
    return isDesktop ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 500 });
    return isMobile ? children : null;
  };

  const dispatch = useDispatch();
  const {
    phala,
    isLoading,
    hasLoaded,
    rightMenu,
    searchQuery,
    searchState,
    lastTime,
    orgProfile,
    phalaSelected
  } = useSelector(store => store.phala);
  const [localphala, setLocalPhala] = useState(phala);

  const [searchQueryField, setSearchQueryField] = useState('');

  const { currentUser } = useSelector(store => store.user);

  const TotalWorkerWidget = phala.reduce((acc, cur) => acc + 1, 0);
  const IdleWorkerWidget = phala.reduce(
    (acc, cur) => acc + (cur.state === 'WorkerIdle' || 0),
    0
  );
  const UnresponsiveWorkerWidget = phala.reduce(
    (acc, cur) => acc + (cur.state === 'WorkerUnresponsive' || 0),
    0
  );
  const ReadyWorkerWidget = phala.reduce(
    (acc, cur) => acc + (cur.state === 'Ready' || 0),
    0
  );
  const WorkerCoolingWidget = phala.reduce(
    (acc, cur) => acc + (cur.state === 'WorkerCoolingDown' || 0),
    0
  );
  const WorkerUnregisteredWidget = phala.reduce(
    (acc, cur) => acc + (cur.state === 'Unregistered' || 0),
    0
  );

  const updatePhala = () => {
    if (isLoading === false) {
      let data = {
        token: currentUser.token,
        org: orgProfile
      };
      dispatch(getAllPhala(data));
    }
  };

  useEffect(() => {
    
    dispatch(getOrgProfile(currentUser.token));
    /* let data = {
      token: currentUser.token,
      org: orgProfile
    }; */
    dispatch(setShowOrgs(true));
    //dispatch(getAllPhala(data));
    const intervalId = setInterval(() => {
      //updatePhala();
       let data = {
        token : currentUser.token,
        org : orgProfile
      }
      console.log("firing");
      console.log("data: " + JSON.stringify(data))
      dispatch(getAllPhala(data)); 
    }, 30000);

    return () => {
      clearInterval(intervalId);
      dispatch(setShowOrgs(false));
    };
  }, []);

  useEffect(() => {
    let data = {
      token: currentUser.token,
      org: orgProfile
    };
    dispatch(getAllPhala(data));
  }, [orgProfile]);

  useEffect(() => {
    //setLocalPhala(phala);
    updateSelected();
    filterPhala();
    

    console.log('phala useEffect: ');
  }, [phala]);

  useEffect(() => {
    filterPhala();
  }, [searchQuery]);
  useEffect(() => {
    filterPhala();
  }, [searchState]);
  useEffect(() => {
    filterPhala();
  }, [sortField]);
  useEffect(() => {
    filterPhala();
  }, [sortOrder]);
  
  // Utility function to get nested property value
const getValueByField = (obj, field) => {
  const fields = field.split('.');
  let currentObj = obj;
  for (let f of fields) {
    if (currentObj[f] === undefined) return undefined;
    currentObj = currentObj[f];
  }
  return currentObj;
};

function sortPhala(arr) {
  console.log("sort order: " + sortOrder + " sortField: " + sortField);
  // Custom comparison function to sort by embedded number and then alphabetically
  const customCompare = (a, b) => {
    const valueA = getValueByField(a, sortField);
    const valueB = getValueByField(b, sortField);
  
    // Handle empty, null, or undefined values
    if (!valueA && !valueB) return 0; // both are empty, so they're equal
    if (!valueA) return sortOrder === 'asc' ? -1 : 1; // A is empty, so it should come first in ascending order
    if (!valueB) return sortOrder === 'asc' ? 1 : -1; // B is empty, so it should come first in ascending order
  
    let numA, numB;
  
    if (typeof valueA === 'number') {
      numA = valueA;
    } else {
      const regex = /(\d+)/;
      numA = (valueA.toString().match(regex) || []);
      numA = parseInt(numA[0] || 0);
    }
  
    if (typeof valueB === 'number') {
      numB = valueB;
    } else {
      const regex = /(\d+)/;
      numB = (valueB.toString().match(regex) || []);
      numB = parseInt(numB[0] || 0);
    }
  
    // Sort based on the embedded number or the numeric value
    if (numA !== numB) {
      return sortOrder === 'asc' ? numA - numB : numB - numA;
    }
  
    // If embedded numbers are the same or the numeric values are the same, sort alphabetically
    const aName = getValueByField(a, 'serviceName') || '';
    const bName = getValueByField(b, sortField) || '';
    return sortOrder === 'asc' ? aName.localeCompare(bName) : bName.localeCompare(aName);
  };
  
  
  

  // Sort the array using the custom comparison function
  const sortedArray = arr.slice().sort(customCompare);

  return sortedArray;
}

const updateSelected = () => {
  let newPhala = [];
  for (let i = 0; i < localphala.length; i++) {
    console.log('tphala');
    let tphala = Object.assign({}, localphala[i]);
    const serviceName = getValueByField(tphala, 'serviceName');
    if (tphala.selected === undefined) {
      tphala.selected = false;
    }
    tphala.selected = phalaSelected.includes(serviceName);
    newPhala.push(tphala);
  }
  // console.log('newPhala: ' + JSON.stringify(newPhala));
  setLocalPhala(newPhala);
};


  const filterPhala = () => {
    const filteredPhala = phala.filter(tphala => {
      return (
        tphala.hostName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tphala.serviceName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tphala.pubkey?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    const filteredPhalaState = filteredPhala.filter(tphala => {
      let tstate = searchState;

      if (searchState === 'Idle') {
        tstate = 'WorkerIdle';
      }
      if (searchState === 'Unresponsive') {
        tstate = 'WorkerUnresponsive';
      }

      if (searchState === 'All') {
        tstate = '';
      }
      return tphala.state?.includes(tstate);
    });
    const sortedData = sortPhala(filteredPhalaState);
    setLocalPhala(sortedData);
  };
  useEffect(() => {
    updateSelected();
  }, [phalaSelected]);

  const handleSearch = e => {
    e.preventDefault();
    console.log('Search');
    dispatch(setSearchQuery(searchQueryField));
    console.log('handle search');
    filterPhala();
  };

  const handleSelect = e => {
    
    dispatch(setSearchState(e));
    filterPhala();
};

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      const targetColumn = e.target.getAttribute('data-field');

      if (targetColumn === 'selected') {
        dispatch(togglePhalaSelected(row.serviceName))
        console.log('targetColumn: ' + row.selected);
        updateSelected();
      } else {
        dispatch(updateSelPhala(row));
        navigate('/phaladetail');
      }
    }
  };
  const bulkCommand = () => {
    dispatch(doBulkCommand());
  };
  const selectAll = () => {
    dispatch(addAllPhalaSelected(localphala));
  };
  const unselectAll = () => {
    dispatch(removeAllPhalaSelected());
  };
  const refreshClick = () => {
    updatePhala();
    
    
  };
  const addWorkerClick = () => {
    dispatch(doAddWorker());
  };

  const handleSearchInputChange = e => {
    setSearchQueryField(e.target.value);
    dispatch(setSearchQuery(e.target.value));
  };
  function handleSort(field) {
    console.log('handleSort: ' + field);
    if (sortField === field) {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
      
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
    const tphala = sortPhala(localphala);
    setLocalPhala(tphala);
  }
  const columnsWithEvents = phalaListColumns.map(column => ({
    ...column,
    headerEvents: {
      onClick: () => handleSort(column.dataField),
    },
  }));
  return (
    <>
      <Header />
      <section className="main-layout">
      <div className="row ms-3 me-3 d-flex justify-content-center">
        <section class="text-white row text-center p-0 col">
          <div class="card workerContainers">
            <div class="row pt-3 text-white row text-center p-0 col no-focus-border">
            <button class="btn btn-outline-white  border-0 col" onClick={() => handleSelect('All')}>
                <h5>Workers</h5>
                <div class="h3">{TotalWorkerWidget}</div>
              </button>
              <button class="btn btn-outline-white  border-0 col" onClick={() => handleSelect('WorkerIdle')}>
                <h5>Idle</h5>
                <div class="h3">{IdleWorkerWidget}</div>
              </button>
              <button class="btn btn-outline-white  border-0 col" onClick={() => handleSelect('Unresponsive')}>
                <h5>Unresponsive</h5>
                <div class="h3">{UnresponsiveWorkerWidget}</div>
              </button>
              <button class="btn btn-outline-white  border-0 col" onClick={() => handleSelect('Ready')}>
                <h5>Ready</h5>
                <div class="h3">{ReadyWorkerWidget}</div>
              </button>
              <button class="btn btn-outline-white  border-0 col" onClick={() => handleSelect('WorkerCoolingDown')}>
                <h5>Cooling</h5>
                <div class="h3">{WorkerCoolingWidget}</div>
              </button>
              <button class="btn btn-outline-white border-0 col" onClick={() => handleSelect('Unregistered')}>
                <h5>Unregistered</h5>
                <div class="h3">{WorkerUnregisteredWidget}</div>
              </button>
            
            </div>
            <p className='text-start ps-2 text-subtle'>Select to Filter</p>
          </div>
        </section>
        <section class="row mt-4 mb-2">
          
          <div class="col d-flex justify-content-end ">
          
            <form onSubmit={handleSearch}>
              <input
                type="text"
                class="form-control search-input"
                value={searchQueryField}
                style={{ width: '50em' }}
                placeholder="Search"
                onChange={handleSearchInputChange}
              />
            </form>
            </div>
            <div class="d-flex flex-row justify-content-center mt-1 m-0 col">
            <button
              type="button"
              class="btn btn-outline-success me-3 border-0"
              onClick={() => selectAll(localphala)}
            >
              <FontAwesomeIcon icon={faCheckDouble} />
              <small class="ps-2">Select</small>
            </button>
            <button
              type="button"
              class="btn btn-outline-danger me-3 border-0"
              onClick={() => unselectAll()}
            >
              
              <FontAwesomeIcon icon={faXmark} />
              <small class="ps-2">Unselect</small>
            </button>
            <button
              type="button"
              class="btn btn-outline-primary me-3 border-0"
              onClick={() => bulkCommand()}
            >
              
              <FontAwesomeIcon icon={faTerminal} />
              <small class="ps-2">Commands</small>
            </button>
          
          
          
            <button
              type="button"
              class="btn btn-outline-warning me-3 border-0"
              onClick={() => refreshClick()}
            >
              <FontAwesomeIcon icon={faRefresh} />
              <small class="ps-2">Refresh</small>
            </button>
            <button
              type="button"
              class="btn btn-outline-success me-4 border-0"
              onClick={() => addWorkerClick()}
            >
              <FontAwesomeIcon icon={faPlus} size="lg" />
              <small class="ps-2">Add Worker</small>
            </button>
          </div>
        </section>
      </div>
      <Desktop>
        <div className="card workerContainers ms-3 me-3">
          <BootstrapTable
            wrapperClasses="container-fluid p-0"
            headerClasses="text-light"
            rowClasses="text-light"
            keyField="_id"
            data={localphala}
            columns={columnsWithEvents}
            rowEvents={rowEvents}
            striped
            hover
            bordered={false}
            bodyStyle={{ wordBreak: 'break-all' }}
          />
        </div>
        
      </Desktop>
      <Midsize>
        <div>
          <button type="button" class="btn btn-outline-warning me-3 border-0">
            <FontAwesomeIcon icon={faRefresh} onClick={() => refreshClick()} />
            <small class="ps-2">Refresh</small>
          </button>
          <button type="button" class="btn btn-outline-success me-4 border-0">
            <FontAwesomeIcon
              icon={faPlus}
              size="lg"
              onClick={() => addWorkerClick()}
            />
            <small class="ps-2">Add Worker</small>
          </button>
        </div>

        <BootstrapTable
          rowClasses="text-light table-dark"
          keyField="phala_server_id"
          data={phala}
          columns={phalaListColumnMd}
          rowEvents={rowEvents}
          striped
          hover
          bordered={false}
          bodyStyle={{ wordBreak: 'break-all' }}
        />
      </Midsize>
      <Mobile>
        <BootstrapTable
          rowClasses="text-light table-dark"
          keyField="phala_server_id"
          data={phala}
          columns={phalaListColumnSm}
          rowEvents={rowEvents}
          striped
          hover
          bordered={false}
          bodyStyle={{ wordBreak: 'break-all' }}
        />
      </Mobile>
      
      </section>
      <Footer />
    </>
  );
};

export default PhalaList;
