import React from 'react';
import { useSelector } from "react-redux";

import { Card } from 'react-bootstrap';
import { MDBTooltip } from 'mdb-react-ui-kit';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';




const PhalaTransferFromList = () => {
  const { accountDetails } = useSelector((store) => store.phala);
  const options = {
    
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  function truncString(str) {
    return String(str).substring(0, 4) + " ... " + String(str).slice(-5);
  }
   
  return (
      <section className='card bg-dark'>
        <div className='card-header text-white'>
          <h2>Transfer From</h2>
        </div>
        <table className='table table-dark table-striped table-hover'>
          <thead>
            <tr>
              <th scope='col'>Date</th>
              <th scope='col'>Account</th>
              <th scope='col'>Total</th>
            </tr>
          </thead>
          <tbody>
            {accountDetails.transferFrom.map((tr) => (
            <tr key={tr._id}>
              <td>{new Date(tr.timestamp).toLocaleDateString("en-US", options)} { new Date(tr.timestamp).toLocaleTimeString("en-US")}</td>
              <td><MDBTooltip tag="a" title={tr.toAccount}>{truncString(tr.toAccount)}</MDBTooltip></td>
              <td>{Number(tr.pha).toLocaleString("en-US")}</td>
            </tr>))} 
          </tbody>
        </table>
      </section>
  );
};


export default PhalaTransferFromList;
