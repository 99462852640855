import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from 'react-bootstrap'
import Header from "../../Header";
import "../../App.css";

import { getAllHelium } from './heliumSlice';

function diff_seconds(dt1) {
  var now = new Date();
  var dt = new Date(dt1);
  var diff = now - dt; // / 1000;
  diff /= 3600;
  return Math.abs(Math.round(diff));
}
const HeliumList= () => {
  const dispatch = useDispatch();
  const { helium,isLoading } = useSelector((store) => store.helium);

 

  useEffect(() => {
    if (isLoading === false)
      dispatch(getAllHelium('random'));
    //dispatch(getAllHelium('random'));
    //dispatch(refreshHelium());
   // if (isLoading === false)
   //   setInterval(getAllHelium('random'), 30000);
    
  });


    return (
      <div className="app-header">
        <Header />
        <Table responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Helium Id</th>
              <th>24h</th>
              <th>7d</th>
              <th>30d</th>
              <th>Sync</th>
              <th>Temp</th>
              <th>Bat</th>
              <th>Solor</th>
              <th>Load</th>
              <th>Update</th>
              <th>Daemon</th>
              <th>API</th>
            </tr>

          </thead>
          <tbody>
            {helium.map(hlm => (
              <tr key={hlm.Helium_Rig_Id}>
                <td> {hlm.Helium_Rig_Id}</td>
                <td> {hlm.Helium_Name}</td>
                <td style={{ backgroundColor: "lightgray" }}>{Number(hlm.Tokens_24).toFixed(1)}</td>
                <td style={{ backgroundColor: "lightgray" }}>{Number(hlm.Tokens_7).toFixed(1)}</td>
                <td style={{ backgroundColor: "lightgray" }}>{Number(hlm.Tokens_30).toFixed(1)}</td>
                <td style={{ backgroundColor: hlm.Sync_Gap > 5?  "red" : "" }}>{hlm.Get_Helium_Sync ? hlm.Sync_Gap > 0? Number(hlm.Sync_Gap).toFixed(0):"in sync": ""}</td>
                <td>{Number(hlm.Equip_Enclosure_Temp).toFixed(0)}</td>
                <td style={{ backgroundColor: hlm.Bat_Volt < 11?  "red" : "lightgray" }}> {Number(hlm.Bat_Volt).toFixed(1)}</td>
                <td style={{ backgroundColor: hlm.Solar_Volt < 11?  "red" : "lightgray" }}> {Number(hlm.Solar_Volt).toFixed(1)}</td>
                <td style={{ backgroundColor: hlm.Load_Volt < 11?  "red" : "lightgray" }}> {Number(hlm.Load_Volt).toFixed(1)}</td>
                <td style={{ backgroundColor: diff_seconds(hlm.Update_Time) >120 ?  "red" : "" }}>{diff_seconds(hlm.Update_Time)}</td>
                <td style={{ backgroundColor: diff_seconds(hlm.Daemon_Time) >2000 ?  "red" : "" }}> {diff_seconds(hlm.Daemon_Time)}</td>
                <td>{hlm.Api_Version}</td>
              </tr>

            ))}

          </tbody>
        </Table>
      </div>
    );
  
};

export default HeliumList;
