import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rightMenuHide,getWorkerSettlement } from './phalaSlice';
import '../../App.css';
import { Container, Col, Row, Accordion, Card, Nav } from 'react-bootstrap';
import Header from '../../Header';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { doPhalaDetailWorker } from '../modal/modalSlice';
import PhalaControl from './phalaControl';
import { Table } from 'react-bootstrap';
import WorkerLogs from  './workerLogs'
import WorkerApr from  './minerCharts/phalaWorkerApr'
import WorkerVscore from  './minerCharts/phalaWorkerVscore'
import WorkerRewards from  './minerCharts/phalaWorkerRewards'

const PhalaDetailWorker = ({ tPhala }) => {
  const dispatch = useDispatch();
  const [activeChart, setActiveChart] = useState('APR');
  const { selPhala } = useSelector(store => store.phala);
  const { currentUser } = useSelector(store => store.user);
  function truncString(str) {
    return String(str).substring(0, 4) + ' ... ' + String(str).slice(-5);
  }
  function diff_dates_sec(dt1, dt2) {
    var mdt = new Date(dt2);
    var mdt1 = new Date(dt1);
    var diff = mdt - mdt1; // / 1000;
    diff /= 3600;
    return Math.abs(Math.round(diff));
  }
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  useEffect(() => {
    if (selPhala?.prb?.minerAccountId) { // Added this check
        let data = {
            token: currentUser.token,
            phala: { 
                workerId: selPhala.prb.minerAccountId
            }
        }
        dispatch(getWorkerSettlement(data));
    }
}, []);

  const handleClick = tph => {
    dispatch(rightMenuHide());
  };
  const handleControlClick = () => {
    dispatch(doPhalaDetailWorker(selPhala));
  };
  return (
    <>
  <section className="row text-light mt-3">
    <div className="col ms-3">
      <h5>Type: {selPhala?.monitorType}</h5>
      <h5>Name: {selPhala?.serviceName}</h5>
    </div>
    <div className="col">
      <button
        onClick={() => {
          handleControlClick();
        }}
        className="btn btn-outline-warning float-end me-5 border-0"
      >
        {/* SVG remains unchanged */}
        Settings
      </button>
    </div>
  </section>
  <section className="row g-3 ms-2 me-2">
    <Col className="col-lg-6">
      <Card className="card text-white standard-card">
        <Card.Header>
          <h3>Worker</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={6}>
              <h5 className="text-secondary">Khala Pruntime</h5>
              <p>Current: {Number(selPhala?.prb?.paraHeaderSynchedTo).toFixed(0)}</p>
              <p>Block Height: {Number(selPhala?.khalaBlockHeight).toFixed(0)}</p>
              <p>Out: {Number(selPhala?.khalaBlockHeight - selPhala?.prb?.paraHeaderSynchedTo).toFixed(0)}</p>
            </Col>
            <Col md={6}>
              <h5 className="text-secondary">Kusama Pruntime</h5>
              <p>Current: {Number(selPhala?.prb?.parentHeaderSynchedTo).toFixed(0)}</p>
              <p>Block Height: {Number(selPhala?.kusamaBlockHeight).toFixed(0)}</p>
              <p>Out: {Number(selPhala?.kusamaBlockHeight - selPhala?.prb?.parentHeaderSynchedTo).toFixed(0)}</p>
            </Col>
          </Row>
          {/* ... rest of the code remains unchanged, just add ?. wherever needed */}
        </Card.Body>
      </Card>
    </Col>
    {/* ... rest of the components */}
  </section>
</>

  );
};

export default PhalaDetailWorker;
