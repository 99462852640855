import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../App.css';
import { Container, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { HasPrivilege } from '../user/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCopy } from '@fortawesome/free-solid-svg-icons';
import { ToggleButton, ButtonGroup } from 'react-bootstrap';
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import {
  updateSetSendNotification,
  updateSetAutoRestart,
  sendServerCommandList,
  bulkServerCommands
} from './phalaSlice';
import { prbWorkerCmd } from './serverCmd';
const PhalaControl = () => {
  const { selPhala,phalaSelected,orgProfile } = useSelector(store => store.phala);
  const { currentUser } = useSelector(store => store.user);
  const [restartValue, setRestartValue] = useState('0');
  const [notificationValue, setNotificationValue] = useState(false);
  const [selectedCommand, setSelectedCommand] = useState([]);
  const dispatch = useDispatch();

  
  

  const handleSendCommandList = () => {
    let cmds = [];
    for (let x = 0; x < selectedCommand.length; x++) {
      let tcmd = selectedCommand[x];
      cmds.push(tcmd);
    }
    let dt = {
      token: currentUser.token,
      cmds: {
        orgId: orgProfile.org_id,
        serviceNames: phalaSelected,
        commands: cmds
      }
    };
    console.log(dt);
    const updatedSelectedCommand = []

    dispatch(bulkServerCommands(dt));
    setSelectedCommand(updatedSelectedCommand)
  };

  const handleSelectCmd = r => {
    console.log(r);
    setSelectedCommand([...selectedCommand, r]);
  };
  const handleRemoveCmd = r => {
    const updatedSelectedCommand = selectedCommand.filter(cmd => cmd !== r);
    setSelectedCommand(updatedSelectedCommand);
  };

  return (
    <Container>
      
      
      <div class="row pt-3">
        <div class="row pt-3">
          <div class="col-6">
            {prbWorkerCmd.map(ph => (
              <Row>
                <Col onClick={() => handleSelectCmd(ph)}>{ph.name}</Col>
              </Row>
            ))}
          </div>
          <div class="col-6">
            {selectedCommand.map(ph => (
              <Row key={ph.name}>
                <Col onClick={() => handleRemoveCmd(ph)}>{ph.name}</Col>
              </Row>
            ))}
            {selectedCommand.length > 0 && (
              <Row>
                <Col>
                  <ToggleButton
                    key="0"
                    id="sendreset"
                    type="button"
                    name="sendreset"
                    value="0"
                    className="btn-danger"
                    onClick={e => handleSendCommandList()}
                  >
                    Send Commands
                  </ToggleButton>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PhalaControl;
