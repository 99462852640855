import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getRewardsByHour } from '../../phalaSlice';

const HalvingProgress = () => {
  const dispatch = useDispatch();
  const [nextHalvingDate, setNextHalvingDate] = useState(new Date("2023-12-09T12:00:00"));
  const { rewardsByHour } = useSelector(store => store.phala);
  const [currentHalvingStartDate, setCurrentHalvingStartDate] = useState(new Date("2023-06-12T12:00:00"));

  const calculateNextHalving = (fromDate) => {
    const halfPeriod = 180 * 24 * 60 * 60 * 1000;
    const nextHalvingTime = new Date(fromDate.getTime() + halfPeriod);
    nextHalvingTime.setHours(12, 0, 0, 0);
    return nextHalvingTime;
  };

  const calculateProgress = (fromDate, toDate) => {
    const halfPeriod = 180 * 24 * 60 * 60 * 1000; // 180 days in milliseconds
    const elapsedTime = toDate - fromDate;
    const progress = Math.round((elapsedTime / halfPeriod) * 100);
    return Math.min(progress, 100); // Ensures progress does not exceed 100%
  };
  
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const updateHalvingDate = () => {
      const currentTime = new Date();
      if (currentTime >= nextHalvingDate) {
        setCurrentHalvingStartDate(nextHalvingDate); // Set the start date to the last halving date
        setNextHalvingDate(calculateNextHalving(nextHalvingDate)); // Calculate the next halving date
      }
    };
    
    const updateProgress = () => {
      const newProgress = calculateProgress(currentHalvingStartDate, new Date());
      setProgress(newProgress);
    };
    

    // Update the halving date and progress when the component mounts
    updateHalvingDate();
    updateProgress();

    // Check every minute if the halving date has been reached
    const intervalId = setInterval(() => {
      updateHalvingDate();
      updateProgress();
    }, 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [nextHalvingDate, currentHalvingStartDate]);

  return (
    <div className='leftCard p-3'>
      <h3 className='pb-2 ps-1'>Halving Progress</h3>
      <div className="progress" style={{backgroundColor: "#333"}}>
        <div className="progress-bar" role="progressbar" style={{width: `${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
      </div>
      <div className='p-4 '>
      <h5>{nextHalvingDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</h5>
      </div>
    </div>
  );
};

export default HalvingProgress;
