import React, { useEffect, useState } from 'react';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setWorkerChart } from '../../phalaSlice';
import ResponsiveByHour from '../workers/phala.widget.ResponsiveByHour';
import WorkersStartStop from '../workers/phala.widget.workersStartStop';
import Vscore1h from '../workers/phala.widget.1hVscore';
import Vscore24h from '../workers/phala.widget.24hVscore';
import '../../../../App.css';

const Workers = () => {
  const dispatch = useDispatch();

  const { hourlyStats, workerChart } = useSelector(store => store.phala);

  const { responsiveByHour, responsiveIsLoading, networkChartTitle } =
    useSelector(store => store.phala);

  const totalEnterResponsive = hourlyStats.reduce(
    (acc, cur) => acc + cur.responsive,
    0
  );
  const totalEnterUnresponsive = hourlyStats.reduce(
    (acc, cur) => acc + cur.unresponsive,
    0
  );
  const difResponse = totalEnterResponsive - totalEnterUnresponsive;

  const totalStart = hourlyStats.reduce(
    (acc, cur) => acc + cur.sumWorkerStarted,
    0
  );
  const totalStop = hourlyStats.reduce(
    (acc, cur) => acc + cur.sumWorkerStopped,
    0
  );

  const difStartStop = totalStart - totalStop;

  const avg24Vscore5000 = hourlyStats.reduce(
    (acc, cur) => acc + cur.avgVscore24_5000,
    0
  );
  const avg24Vscore10000 = hourlyStats.reduce(
    (acc, cur) => acc + cur.avgVscore24_10000,
    0
  );
  const avg24Vscore15000 = hourlyStats.reduce(
    (acc, cur) => acc + cur.avgVscore24_15000,
    0
  );
  const avg24Vscore20000 = hourlyStats.reduce(
    (acc, cur) => acc + cur.avgVscore24_20000,
    0
  );
  const avg24Vscore20000gt = hourlyStats.reduce(
    (acc, cur) => acc + cur.avgVscore24_2000gt,
    0
  );

  const avgVscore5000 = hourlyStats.reduce(
    (acc, cur) => acc + cur.avgVscore_5000,
    0
  );
  const avgVscore10000 = hourlyStats.reduce(
    (acc, cur) => acc + cur.avgVscore_10000,
    0
  );
  const avgVscore15000 = hourlyStats.reduce(
    (acc, cur) => acc + cur.avgVscore_15000,
    0
  );
  const avgVscore20000 = hourlyStats.reduce(
    (acc, cur) => acc + cur.avgVscore_20000,
    0
  );
  const avgVscore20000gt = hourlyStats.reduce(
    (acc, cur) => acc + cur.avgVscore_2000gt,
    0
  );

  const setWorkerChartHandler = workerChartName => {
    dispatch(setWorkerChart(workerChartName));
  };

  return (
    <section className="leftCard">
      <div>
        {workerChart === 'startStop' && (
          <div className="col m-0 p-0">
            <WorkersStartStop />
          </div>
        )}
        {workerChart === 'response' && (
          <div className="col m-0 p-0">
            <ResponsiveByHour />
          </div>
        )}
        {workerChart === 'vScore1h' && (
          <div className="col m-0 p-0">
            <Vscore1h />
          </div>
        )}
        {workerChart === 'vScore24h' && (
          <div className="col m-0 p-0">
            <Vscore24h />
          </div>
        )}
      </div>
      <section className="justify-content-center pt-3 text-white ">
      <div className="d-flex justify-content-center pt-3 text-white">
          <div className="row leftCard-data-container pb-3 d-flex align-items-center text-subtle">
            <div className="d-flex align-items-center text-subtle">
            <h5 className="col pt-3 pb-2 ms-2 pe-3  text-subtle">Start/Stop</h5>
            <div className="col d-flex justify-content-end">
              <button
                className="btn btn-outline-none text-success d-flex align-items-center"
                style={{ borderRadius: '4px' }}
                onClick={() => setWorkerChartHandler('startStop')}
              >
                <h6 className="mb-0 me-2 text-success">View</h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1rem"
                  fill="currentColor"
                  class="bi bi-bar-chart-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z" />
                </svg>
              </button>
            </div>
          </div>
          <div className="col text-center">
            <h6 className="text-subtle">Start</h6>
            <h4>{Number(totalStart.toFixed(2)).toLocaleString('en-US')}</h4>
          </div>
          <div className="col text-center">
            <h6 className="text-subtle">Stop</h6>
            <h4>{Number(totalStop.toFixed(2)).toLocaleString('en-US')}</h4>
          </div>

          <div className="col text-center">
            <h6 className="text-subtle">Difference</h6>
            <h4>{Number(difStartStop.toFixed(2)).toLocaleString('en-US')}</h4>
          </div>
        </div>
          </div>
        <div className="d-flex justify-content-center pt-3 text-white">
          <div className="row leftCard-data-container pb-3 d-flex align-items-center text-subtle">
            <div className="d-flex align-items-center text-subtle">
            <h5 className="pt-3 pb-2 ms-2 pe-3  text-subtle">Worker Response</h5>
            <i class="fa-solid fa-chart-line"></i>
            <div className="col d-flex justify-content-end">
              <button
                className="btn btn-outline-none text-success d-flex align-items-center"
                style={{ borderRadius: '4px' }}
                onClick={() => setWorkerChartHandler('response')}
              >
                <h6 className="mb-0 me-2 text-success">View</h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1rem"
                  fill="currentColor"
                  class="bi bi-bar-chart-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z" />
                </svg>
              </button>
            </div>
          </div>

          <div className="col text-center">
            <h6 className="header-subtle">Responsive</h6>
            <h4>
              {Number(totalEnterResponsive.toFixed(2)).toLocaleString('en-US')}
            </h4>
            </div>

            <div className="col text-center">
              <h6 className="text-subtle">Unresponsive</h6>
              <h4>
                {Number(totalEnterUnresponsive.toFixed(2)).toLocaleString(
                  'en-US'
                )}
              </h4>
            </div>

            <div className="col text-center">
              <h6 className="text-subtle">Difference</h6>
              <h4>{Number(difResponse.toFixed(2)).toLocaleString('en-US')}</h4>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center pt-3 text-white">
          <div className="row leftCard-data-container pb-3 d-flex align-items-center text-subtle">
            <div className="d-flex align-items-center text-subtle">
              <h5 className="pt-3 pb-2 ms-2 pe-3  text-subtle">1H V-Score</h5>
              <i class="fa-solid fa-chart-line"></i>
              <div className="col d-flex justify-content-end">
                <button
                  className="btn btn-outline-none text-success d-flex align-items-center"
                  style={{ borderRadius: '4px' }}
                  onClick={() => setWorkerChartHandler('vScore1h')}
                >
                  <h6 className="mb-0 me-2 text-success">View</h6>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1rem"
                    fill="currentColor"
                    class="bi bi-bar-chart-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z" />
                  </svg>
                </button>
              </div>
            </div>

            <div className="col text-center">
              <h6 className="text-subtle">&lt;5,000</h6>
              <h4>
                {Number(avgVscore5000.toFixed(0)).toLocaleString('en-US')}
              </h4>
            </div>
            <div className="col text-center">
              <h6 className="text-subtle">5,000-10,000</h6>
              <h4>
                {Number(avgVscore10000.toFixed(0)).toLocaleString('en-US')}
              </h4>
            </div>
            <div className="col text-center">
              <h6 className="text-subtle">10,001-15,000</h6>
              <h4>
                {Number(avgVscore15000.toFixed(0)).toLocaleString('en-US')}
              </h4>
            </div>
            <div className="col text-center">
              <h6 className="text-subtle">15,001-20,000</h6>
              <h4>
                {Number(avgVscore20000.toFixed(0)).toLocaleString('en-US')}
              </h4>
            </div>

            <div className="col text-center">
              <h6 className="text-subtle">&gt;20,000</h6>
              <h4>
                {Number(avgVscore20000gt.toFixed(0)).toLocaleString('en-US')}
              </h4>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-3 text-white">
          <div className="row leftCard-data-container pb-3 d-flex align-items-center text-subtle">
            <div className="d-flex align-items-center text-subtle">
            <h5 className="pt-3 pb-2 text-subtle">24H V-Score</h5>
            <i class="fa-solid fa-chart-line"></i>
            <div className="col d-flex justify-content-end">
              <button
                className="btn btn-outline-none text-success d-flex align-items-center"
                style={{ borderRadius: '4px' }}
                onClick={() => setWorkerChartHandler('vScore24h')}
              >
                <h6 className="mb-0 me-2 text-success">View</h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1rem"
                  fill="currentColor"
                  class="bi bi-bar-chart-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z" />
                </svg>
              </button>
            </div>
          </div>
          <div className="row text-center">
            <div className="col text-center">
              <h6 className="text-subtle">&lt;5,000</h6>
              <h4>
                {Number(avg24Vscore5000.toFixed(0)).toLocaleString('en-US')}
              </h4>
            </div>
            <div className="col text-center">
              <h6 className="text-subtle">5,000-10,000</h6>
              <h4>
                {Number(avg24Vscore10000.toFixed(0)).toLocaleString('en-US')}
              </h4>
            </div>
            <div className="col text-center">
              <h6 className="text-subtle">10,001-15,000</h6>
              <h4>
                {Number(avg24Vscore15000.toFixed(0)).toLocaleString('en-US')}
              </h4>
            </div>
            <div className="col text-center">
              <h6 className="text-subtle">15,001-20,000</h6>
              <h4>
                {Number(avg24Vscore20000.toFixed(0)).toLocaleString('en-US')}
              </h4>
            </div>
            <div className="col text-center">
              <h6 className="text-subtle">&gt;20,000</h6>
              <h4>
                {Number(avg24Vscore20000gt.toFixed(0)).toLocaleString('en-US')}
              </h4>
            </div>
          </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Workers;
