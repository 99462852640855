import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../App.css";
import { Container, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { HasPrivilege } from "../user/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faCopy } from "@fortawesome/free-solid-svg-icons";
import { ToggleButton, ButtonGroup } from "react-bootstrap";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import {
  updateSetSendNotification,
  updateSetAutoRestart,
  updateSendRestartCommand,
  updateSendUpdateCommand,
  updateSendStopCommand,
  updateSendStartCommand,
  sendServerCommand,
} from "./phalaSlice";

const PhalaControl = () => {
  const { selPhala } = useSelector((store) => store.phala);
  const { currentUser } = useSelector((store) => store.user);
  const [restartValue, setRestartValue] = useState("0");
  const [notificationValue, setNotificationValue] = useState(false);
  const dispatch = useDispatch();

  const SetNotification = (tvalue) => {
    //setNotificationValue(e.currentTarget.checked)

    let dt = {
      token: currentUser.token,
      phala: {
        phala_server_id: selPhala.phala_server_id,
        send_down_notification: tvalue,
      },
    };
    dispatch(updateSetSendNotification(dt));
    //console.log(e.currentTarget.value);
  };
  const SetRestart = (tvalue) => {
    let dt = {
      token: currentUser.token,
      phala: {
        phala_server_id: selPhala.phala_server_id,
        auto_restart_on_sync: tvalue,
      },
    };
    dispatch(updateSetAutoRestart(dt));
    //console.log(e.currentTarget.value);
  };
  const SetRestartCommand = () => {
    
    let dt = {
      token: currentUser.token,
      phala: {
        orgId: selPhala.org_id,
        serviceName: selPhala.serviceName,
        command: "restart phala"
      },
    };
    dispatch(sendServerCommand(dt));
    
  };
  const SetUpdateCommand = () => {
    
    let dt = {
      token: currentUser.token,
      phala: {
        orgId: selPhala.org_id,
        serviceName: selPhala.serviceName,
        command: "update phala"
      },
    };
    dispatch(sendServerCommand(dt));
  };
  const SetStopCommand = () => {
    
    let dt = {
      token: currentUser.token,
      phala: {
        orgId: selPhala.org_id,
        serviceName: selPhala.serviceName,
        command: "stop phala"
      },
    };
    dispatch(sendServerCommand(dt));
  };

  const SetStartCommand = () => {
    
    let dt = {
      token: currentUser.token,
      phala: {
        orgId: selPhala.org_id,
        serviceName: selPhala.serviceName,
        command: "start phala"
      },
    };
    dispatch(sendServerCommand(dt));
  };

  const SetLogsCommand = () => {
    
    let dt = {
      token: currentUser.token,
      phala: {
        orgId: selPhala.org_id,
        serviceName: selPhala.serviceName,
        command: "phala logs"
      },
    };
    dispatch(sendServerCommand(dt));
  };

  const SetUpdateMonitorCommand = () => {
    
    let dt = {
      token: currentUser.token,
      phala: {
        orgId: selPhala.org_id,
        serviceName: selPhala.serviceName,
        command: "update monitor"
      },
    };
    dispatch(sendServerCommand(dt));
  };

  return (
    
        <Container>
          <div class='row'>
            <h6 class='col-7'>Text Notifications</h6>
            <div class='col-5'>
              <ButtonGroup>
                <ToggleButton
                  key="1"
                  id="notification1"
                  type="radio"
                  name="notificationbtn"
                  value="1"
                  className="btn-success"
                  checked={selPhala.send_down_notification}
                  onChange={(e) => SetNotification(true)}
                >
                  On
                </ToggleButton>
                <ToggleButton
                  key="0"
                  id="notification2"
                  type="radio"
                  name="notificationbtn"
                  value="0"
                  className="btn-danger"
                  checked={!selPhala.send_down_notification}
                  onChange={(e) => SetNotification(false)}
                >
                  Off
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div>
          <div class='row pt-3'>
             <h6 class='col-7'>Auto Restart</h6>
            <div class='col-5'>
              <ButtonGroup>
                <ToggleButton
                  key="1"
                  id="restart1"
                  type="radio"
                  name="restartbtn"
                  value="1"
                  className="btn-success"
                  checked={selPhala.auto_restart_on_sync}
                  onChange={(e) => SetRestart(true)}
                >
                  On
                </ToggleButton>
                <ToggleButton
                  key="0"
                  id="restart2"
                  type="radio"
                  name="restartbtn"
                  value="0"
                  className="btn-danger"
                  checked={!selPhala.auto_restart_on_sync}
                  onChange={(e) => SetRestart(false)}
                >
                  Off
                </ToggleButton>
              </ButtonGroup>
           </div>
          </div>
          <div class='row pt-3'>
            <h6 class='col-7'>Restart Phala</h6>
            <div class='col-5'>
              <ToggleButton
                key="0"
                id="sendreset"
                type="checkbox"
                name="sendreset"
                value="0"
                className="btn-danger"
                checked={selPhala.send_restart_command}
                onChange={(e) => SetRestartCommand()}
              >
                Send Reset
              </ToggleButton>
            </div>
          </div>
          <div class='row pt-3'>
            <h6 class='col-7'>Update Phala</h6>
            <div class='col-5'>
              <ToggleButton
                key="0"
                id="sendupdate"
                type="checkbox"
                name="sendupdate"
                value="0"
                className="btn-warning"
                checked={selPhala.send_update_command}
                onChange={(e) => SetUpdateCommand()}
              >
                Send Update
              </ToggleButton>
            </div>
          </div>
          <div class='row pt-3'>
            <h6 class='col-7'>Stop Phala</h6>
            <div class='col-5'>
              <ToggleButton
                key="0"
                id="sendstop"
                type="checkbox"
                name="sendstop"
                value="0"
                className="btn-danger"
                checked={selPhala.send_stop_command}
                onChange={(e) => SetStopCommand()}
              >
                Send Stop
              </ToggleButton>
           </div>
          </div>
          <div class='row pt-3'>
            <h6 class='col-7'>Start Phala</h6>
            <div class='col-5'>
              <ToggleButton
                key="0"
                id="sendstart"
                type="checkbox"
                name="sendstart"
                value="0"
                className="btn-success"
                checked={selPhala.send_start_command}
                onChange={(e) => SetStartCommand()}
              >
                Send Start
              </ToggleButton>
            </div>
          </div>
          <div class='row pt-3'>
            <h6 class='col-7'>Get Logs</h6>
            <div class='col-5'>
              <ToggleButton
                key="0"
                id="sendlogs"
                type="checkbox"
                name="sendlogs"
                value="0"
                className="btn-danger"
                
                onChange={(e) => SetLogsCommand()}
              >
                Send Logs
              </ToggleButton>
           </div>
          </div>

          <div class='row pt-3'>
            <h6 class='col-7'>Update Monitor</h6>
            <div class='col-5'>
              <ToggleButton
                key="0"
                id="updatemonitor"
                type="checkbox"
                name="updatemonitor"
                value="0"
                className="btn-danger"
                
                onChange={(e) => SetUpdateMonitorCommand()}
              >
                Update Monitor
              </ToggleButton>
           </div>
          </div>

          
          <div class='row pt-3'>
          <h6 class='col-7'>Reboot Server</h6>
          <h6 class='col-5'>Comming Soon</h6>
          </div>
        </Container>
      
  );
};

export default PhalaControl;
