import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../App.css";


import { addWorker } from "./phalaSlice";
import {closeModal} from "../modal/modalSlice"



const Add_Worker = () => {
  const {currentUser } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const [host_name,setHost_Name] = useState("");
  const [pool_account,setPool_Account] = useState("");
  const [pool_id,setPool_Id] = useState("");
  
  
  

  /* const handleFormChange = ({ currentTarget: input }) => {
    console.log(input.value);

    ph[input.name] = input.value;
    //console.log(this.state);
    //this.setState({ login });
    console.log("Phala: " + JSON.stringify(ph));
    //dispatch(updateSelUser(usr));
  }; */
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const ph = {
      host_name: host_name,
      pool_account: pool_account,
      pool_id: pool_id,
     
    }
    console.log("Before dispatch");
    const data = {
        token: currentUser.token,
        phala: ph
    }
    dispatch(addWorker(data));

    console.log(data);
    dispatch(closeModal());
    
    
  };
  
  return (
    <div className="app-header">
    
      <form onSubmit={handleFormSubmit}>
        <h5 class="pb-1">Enter Worker Information:</h5>
        <div class="pb-3">
          <div class='form-floating'>
          <input
                  value={host_name}
                  id="host_name floatingInput"
                  name="host_name"
                  onChange={(e) => setHost_Name(e.target.value)}
                  type="text"
                  class="form-control bg-secondary text-white"
                  placeholder="Worker_Name"
                  
                />
                <label for="floatingInput">Worker Name</label>
          </div>
        </div>
        <div class="pb-3">
          
          <div class='form-floating'>
          <input
                  value={pool_account}
                  id="pool_account floatingInput"
                  name="pool_account"
                  onChange={(e) => setPool_Account(e.target.value)}
                  type="text"
                  class="form-control bg-secondary text-white"
                  placeholder="Pool_Account bg-secondary text-white"
                />
                <label for="floatingInput ">Pool Account</label>
          </div>
        </div>
        <div class="pb-3">
          <div class='form-floating'>
          <input
                  value={pool_id}
                  id="pool_id floatingInput"
                  name="pool_id"
                  onChange={(e) => setPool_Id(e.target.value)}
                  type="text"
                  class="form-control bg-secondary text-white"
                  placeholder="Pool_ID"
                />
            <label>Pool ID</label>
          </div>
        </div>
        <div class="d-flex justify-content-center">
        <button class="btn btn-outline-success border-0">Submit</button>
      </div>
      </form>
    </div>
  );
};

export default Add_Worker;
