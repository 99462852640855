import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Header";
import "../../App.css";
import { Container,Col,Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { HasPrivilege } from "./util";

import {
  updateUser,
  updateSelUser,
  getAllUsers,
  getAllPrivileges,
} from "./userSlice";
import UserPrivileges from "./editUserPrivileges";
import { doChangePassword } from "../modal/modalSlice";

const EditUser = () => {
  const { selUser, currentUser } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const canChangePassword = HasPrivilege("changepassword");
  const canEditUser = HasPrivilege("edituser");
  const canSetPrivileges = HasPrivilege("setprivileges");

  useEffect(() => {
    dispatch(getAllPrivileges(currentUser.token));
  }, []);

  const handleFormChange = ({ currentTarget: input }) => {
    console.log(input.value);

    const usr = {
      user_id: selUser.user_id,
      email: selUser.email,
      first_name: selUser.first_name,
      last_name: selUser.last_name,
      phone: selUser.phone,
      address_1: selUser.address_1,
      address_2: selUser.address_2,
      city: selUser.city,
      state: selUser.state,
      zip: selUser.zip,
      user_status: selUser.user_status,
    };
    usr[input.name] = input.value;
    //console.log(this.state);
    //this.setState({ login });
    console.log(usr);
    dispatch(updateSelUser(usr));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Before dispatch");

    const usr = {
      user: selUser,
      token: currentUser.token,
    };
    dispatch(updateUser(usr));

    console.log(usr);
    dispatch(getAllUsers(currentUser.token));
    navigate("/users");
  };
  const handleStatusChange = (event) => {
    console.log(event.target.value);
    const usr = {
      user_id: selUser.user_id,
      email: selUser.email,
      first_name: selUser.first_name,
      last_name: selUser.last_name,
      phone: selUser.phone,
      address_1: selUser.address_1,
      address_2: selUser.address_2,
      city: selUser.city,
      state: selUser.state,
      zip: selUser.zip,
      user_status: event.target.value,
    };
    dispatch(updateSelUser(usr));
  };
  return (
    <div className="app-header text-light">
      <Header />
      <Container fluid="md">
      <Row>
        <Col>
      <form onSubmit={handleFormSubmit}>
        <table responsive>
          <tbody>
            <tr>
              <td>Email</td>
              <td>
                <input
                  value={selUser.email}
                  id="email"
                  name="email"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>First Name</td>
              <td>
                <input
                  value={selUser.first_name}
                  id="first_name"
                  name="first_name"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>
                <input
                  value={selUser.last_name}
                  id="last_name"
                  name="last_name"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>
                <input
                  value={selUser.phone}
                  id="phone"
                  name="phone"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                <input
                  value={selUser.address_1}
                  id="address_1"
                  name="address_1"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <input
                  value={selUser.address_2}
                  id="address_2"
                  name="address_2"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>City</td>
              <td>
                <input
                  value={selUser.city}
                  id="city"
                  name="city"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>State</td>
              <td>
                <input
                  value={selUser.state}
                  id="state"
                  name="state"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>Zip</td>
              <td>
                <input
                  value={selUser.zip}
                  id="zip"
                  name="zip"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                <select
                  id="user_status"
                  value={selUser.user_status}
                  onChange={handleStatusChange}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="Pending">Pending</option>
                </select>
                {canChangePassword ? (
                  <button
                    onClick={() => {
                      dispatch(doChangePassword());
                    }}
                  >
                    Password
                  </button>
                ) : (
                  ""
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {canEditUser ? <button>Submit</button> : ""}
      </form>
      </Col>
      <Col>
      {canSetPrivileges ? <UserPrivileges /> : ""}
      </Col>
      </Row></Container>
    </div>
    
  );
};

export default EditUser;
