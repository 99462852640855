import React from 'react';
import { useSelector } from "react-redux";

import { Card,Table,Row,Col} from 'react-bootstrap';
import ReactEChart from 'echarts-for-react';
import "../../../../App.css";
import AccountDetail from './../accountDetail';






const PhalaAccountSummary = () => {
   const { selAccount,accountDetails } = useSelector((store) => store.phala);
  function truncString(str) {
    return String(str).substring(0, 4) + " ... " + String(str).slice(-5);
  }
  const eChartsOption = {
    tooltip: {
        trigger: 'item'
      },
    
    series:{
        
        data: [
          { value: selAccount.miscFrozen, name: 'Locked' },
          { value: selAccount.free - selAccount.miscFrozen, name: 'Free' },
            
            
          ],
        type:"pie",
        radius: ['20%', '50%'],
  avoidLabelOverlap: false,
  itemStyle: {
    borderRadius: 5,
    borderColor: '#fff',
    borderWidth: 2

  },
    }
};
   
  return (

    <section className='card bg-dark text-white '>
      <div className='card-header'>
        <h2>Account: {selAccount.name}</h2>
        <div className='card-subtitle text-muted'>
           
            <h6>ID: {selAccount.account}</h6>
          </div>
      </div>
      <div class='card'>
          <h1></h1>
          </div>
      
        <table className='table text-white table-borderless'>
          
        <tr>
          <td><b>Delegated</b></td>
          <td>{Number(selAccount.delegated).toFixed(1)}</td>
        </tr>
        <tr>
          <td><b>Available</b></td>
          <td>{Number(selAccount.free).toFixed(1)}</td>
        </tr>  
        <tr className='border-top' >
          <td><b>Total</b></td>
          <td>{Number(selAccount.free + selAccount.delegated).toFixed(1)}</td>
        </tr>
        </table>

        <div>24 Hour Total Rewards: {Number(accountDetails.reward24).toFixed(0)}</div>
        <div><ReactEChart option={eChartsOption}/></div>
    </section>
  );
};


export default PhalaAccountSummary;
