import React from "react";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {useSelector} from 'react-redux'
import axios from 'axios';
import { useRoutes } from 'react-router-dom';



const baseUrl = process.env.REACT_APP_SENSORSERVER + '/helium';

const initialState = {
  helium: [],
  isLoading: false,
};

export const getAllHelium = createAsyncThunk(
    'helium/getAllHelium',
    async (name, thunkAPI) => {
      try {
        const url = baseUrl + "/getallrigs";
        
        const resp = await axios.get(url);
  
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
      }
    }
  );

  const heliumSlice = createSlice({
    name: 'helium',
    initialState,
    reducers: {
      
      
    },
    extraReducers: {
      [getAllHelium.pending]: (state) => {
        console.log("getAllHelim Pending");
        state.isLoading = true;
      },
      [getAllHelium.fulfilled]: (state, action) => {
        console.log("getAllHelim fulfilled");
     console.log(action);
        state.isLoading = false;
        state.helium = action.payload;

        
      },
      [getAllHelium.rejected]: (state, action) => {
        console.log("getAllHelim Rejected");
        console.log(action);
        state.isLoading = false;
      },


    },
  });
  
  // console.log(cartSlice);
  export const {} =
  heliumSlice.actions;
  
  export default heliumSlice.reducer;