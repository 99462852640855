import React, { useEffect, useState } from 'react';

import { Card, Dropdown, DropdownButton } from 'react-bootstrap';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix

import { useDispatch, useSelector } from 'react-redux';
import { getBlockTime } from '../phala/phalaSlice';
import '../../App.css';
import NetworkSection from "./networkSection";

const BlockTimeTest = () => {
  const dispatch = useDispatch();
  const [categoryArray, setCategory] = useState([]);
  const [avgBlockTimeArray, setAvgBlockTime] = useState([]);
  const [minBlockTimeArray, setMinBlockTime] = useState([]);
  const [maxBlockTimeArray, setMaxBlockTime] = useState([]);
  const [totalRewardsArray, setTotalRewards] = useState([]);
  const [titleText, setTitle] = useState('Hour');
  const [chartInterval, setChartInterval] = useState('hour');

  

  //let miningArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,24,24];

  const { blockTimeByHour, blockTimeIsLoading } = useSelector(
    store => store.phala
  );

  const eChartsOption = {
    tooltip: {
      trigger: 'axis',
      position: function (pt) {
        return [pt[0], '10%'];
      },

      backgroundColor: 'black',
      borderColor: '#c8e2f7',
      textStyle: {
        color: '#5d6f80'
      }
    },
    toolbox: {
      feature: {
        
      },
      iconStyle: {
        borderColor: 'white',
      }
    },
    textStyle: {
      color: '#fff'
    },
    dataZoom: [
      {
        type: 'inside',
        start: 50,
        end: 100
      },
      {
        start: 0,
        end: 100,
        show: false
      }
    ],

    legend: {
      data: ['Average', 'Low','High','Total Rewards'],
      top: '25',
      inactiveColor: '#555555',
      top:'auto',
      selected:{
        'Average': true,
        'Low': false,
        'High': false,
        'Total Rewards': true,
      },
      textStyle: {
        color: 'white'
      }
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      show: true,
      nameLocation: 'middle',
    },
    grid: [
      {
        left: '0%',
        right: '0%',
        height: '60%',
        top: '30%'
        
      },
      {
        left: '10%',
        right: '8%',
        top: '63%',
        height: '50%',
        
      }
    ],
    yAxis: [{
      name:'Block Time',
      
      position: 'left',
      type: 'value',
      show: false
    },
    {
      name:'Rewards',
      
      position: 'right',
      type: 'value',
      show: false
    }],
    series: [
      {
        name: 'Average',
        data: avgBlockTimeArray,
        type: 'line',
        smooth: false,
        showSymbol: false
      },
      {
        name: 'Low',
        data: minBlockTimeArray,
        type: 'line',
        smooth: true,
        showSymbol: false,
        
      },
      {
        name: 'High',
        data: maxBlockTimeArray,
        type: 'line',
        smooth: true,
        showSymbol: false
      },
      {
        name: 'Total Rewards',
        yAxisIndex: 1,
        grid:1,
        data: totalRewardsArray,
        color:'gray',
        
        type: 'bar',
        smooth: true,
        showSymbol: false,
       
        
        itemStyle: {
          opacity: .4,
          height: '20%'
        }
        
      }
    ]
  };
  useEffect(() => {
    console.log('data Changed');
    let tavg = [];
    let tmin = [];
    let tmax = [];
    let treward = [];


    let tcat = [];


    for (let m of blockTimeByHour) {
      //tcat.push(String(m._id))
      tavg.push(Number(m.avgBlockTime).toFixed(2));
      tmin.push(m.minBlockTime);
      tmax.push(m.maxBlockTime);
      treward.push(Number(m.totalRewards).toFixed(2));
      let dt = new Date(Math.floor(m.minTime));
      console.log(dt);
      //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
      if (chartInterval === "hour"){
      tcat.push(
        dt.toLocaleString('en-us', {
          hour12: true,

          hour: 'numeric',
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })
    
      );
    } else {
        tcat.push(
            dt.toLocaleString('en-us', {
              
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })
        
          );
    }
    }

    setCategory(tcat);
    
    setAvgBlockTime(tavg);
    setMinBlockTime(tmin)
    setMaxBlockTime(tmax)
    setTotalRewards(treward)
    
    
    
  }, [blockTimeByHour]);
  
  
  
  useEffect(() => {
    
    let end = Math.floor(new Date().getTime())
    let start = Math.floor(end - 1209600000); // 2 weeks
      let data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }
    dispatch(getBlockTime(data));
  }, []);




  const handleSelect = e => {
    console.log(e);
    setChartInterval(e);
    let data = {}
    let end = Math.floor(new Date().getTime())
    if (e === 'hour') {
      setTitle('Hour');
      let start = Math.floor(end - 1209600000); // 2 weeks
      data = {
        "start": start,
        "end": end,
        "interval": e
      }

    }
    if (e === 'day') {
      setTitle('Day');
      let start = Math.floor(end - 2629743000 *2 ); // 1 month
      data = {
        "start": start,
        "end": end,
        "interval": e
      }
      
    }
    if (e === 'week') {
      setTitle('Week');
      let start = Math.floor(end - (2629743000 * 6) ); // 6 months
      data = {
        "start": start,
        "end": end,
        "interval": e
      }
    }
    if (e === 'month') {
      setTitle('Month');
      
      let start = Math.floor(end - (2629743000 * 12)  ); // 6 months
      data = {
        "start": start,
        "end": end,
        "interval": e
      }
    }
    dispatch(getBlockTime(data));


  };

  return (



    
    <Card className="h-md-100 bg-dark text-light">
      <Card.Header className='row'>
        <div class="col-sm-1"></div>
        <h3 className='text-center col-9 col-sm-10'>Block Time</h3>
        <div className='postition-relative col-3 col-sm-1'>
        
        <DropdownButton className='position-absolute top-0 end-0 m-2' onSelect={handleSelect} id="chartSelection" title={titleText} variant="dark">
          <Dropdown.Item eventKey="hour" active>Hour</Dropdown.Item>
          <Dropdown.Item eventKey="day">Day</Dropdown.Item>
          <Dropdown.Item eventKey="week">Week</Dropdown.Item>
          <Dropdown.Item eventKey="month">Month</Dropdown.Item>
        </DropdownButton>
        </div>
      </Card.Header>
      <Card.Body class="p-0" styled="185px;">
        {blockTimeIsLoading === true ? (
          <div class="position-absolute bottom-50 end-50">
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        ) : (
          ''
        )}
        <div>
          <ReactEChart option={eChartsOption} style={{height: '185px'}}/>
          
        </div>
        <div class="d-flex justify-content-center pt-3">
         <div class="btn-group" role="group" aria-label="Button group to pick date range" onSelect={handleSelect}>
          <input type="radio" class="btn-check" eventKey="hour" name="24hr_btn" id="2btnradio1" autocomplete='off' checked="" />
          <label class="btn btn-outline-primary" for="24hr_btn"> 24hr</label>
          <input type="radio" class="btn-check" eventKey="day" name="7d_btn" id="btnradio2" autocomplete='off'/>
          <label class="btn btn-outline-primary" for="7d_btn"> 7d</label>
          <input type="radio" class="btn-check" name="30d_btn" id="btnradio3" autocomplete='off'/>
          <label class="btn btn-outline-primary" for="30d_btn"> 30d</label>
          <input type="radio" class="btn-check" name="60d_btn" id="btnradio4" autocomplete='off'/>
          <label class="btn btn-outline-primary" for="60d_btn"> 60d</label>
          <input type="radio" class="btn-check" name="90d_btn" id="btnradio5" autocomplete='off'/>
          <label class="btn btn-outline-primary" for="90d_btn"> 90d</label>
          <input type="radio" class="btn-check" name="all_btn" id="btnradio6" autocomplete='off'/>
          <label class="btn btn-outline-primary" for="all_btn"> all</label>
         </div>
        </div>
        
        <section class="row pt-3">
          <card class="col text-center ps-4">
            <h6>Average</h6>
            <h4>12.34<small>Sec</small></h4>
          </card>
          <card class="col text-center">
            <h6>High</h6>
            <h4>12.34</h4>
          </card>
          <card class="col text-center">
            <h6>Low</h6>
            <h4>12.34</h4>
          </card>
          <card class="col text-center pe-4">
            <h6>Rewards</h6>
            <h4>500K</h4>
          </card>
        </section>
        <section>
          <NetworkSection/>
        </section>
      </Card.Body>
    </Card> 
  );
};

export default BlockTimeTest;
