import React, { useEffect, useState } from 'react';

import { ButtonGroup, Button } from 'react-bootstrap';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBlockTime,
  setNetworkChartTitle,
  getResponsive,
  getHourlyStats
} from '../../phalaSlice';
import '../../../../App.css';

const Transfers = () => {
  const dispatch = useDispatch();
  const [categoryArray, setCategory] = useState([]);
  const [transfersArray, settransfers] = useState([0]);
  const [chartInterval, setChartInterval] = useState('24h');
  const { networkChartTitle, hourlyStats, blockTimeIsLoading } = useSelector(
    store => store.phala
  );
  const npm = hourlyStats.reduce(
    (acc, cur) => acc + cur.transfers,
    0
  );
  const transfers = hourlyStats.reduce((acc, cur) => acc + cur.transfers, 0);

  const eChartsOption = {
    tooltip: {
      trigger: 'axis',
      position: 'top', // set the tooltip position to top
      offset: [0, 10], // set the vertical offset to 10 pixels
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: '#c8e2f7',
      textStyle: {
        color: '#000'
      },
      formatter: function (params) {
        let result = '';
        params.forEach((param) => {
          // Assuming 'param' contains the series name and data value
          const roundedValue = Math.round(param.value).toLocaleString();
          result += `${param.marker}${param.seriesName}: ${roundedValue}<br/>`;
        });
        return result;
      },
    },
    toolbox: {
      feature: {},
      iconStyle: {
        borderColor: 'white'
      }
    },
    textStyle: {
      color: '#fff'
    },
    legend: {
      data: ['Responsive', 'Unresponsive'],
      top: '25',
      inactiveColor: '#555555',

      textStyle: {
        color: 'white'
      }
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },

    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          opacity: 0.08 // set the opacity of the line to 50%
        }
      },
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },
    grid: [
      {
        left: '10%',
        right: '5%',
        height: '70%'
      }
    ],
    series: [
      {
        name: 'transfers',
        data: transfersArray,
        type: 'bar',
        smooth: false,
        showSymbol: false,
        color: '#4DBD74'
      }
    ]
  };

  useEffect(() => {
    console.log('data Changed');
    let transf = [];

    let tcat = [];

    for (let m of hourlyStats) {
      //tcat.push(String(m._id))
      transf.push(m.transfers);
      let dt = new Date(Math.floor(m.minTime));
      console.log('Chart Interval: ' + chartInterval);
      //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
      if (chartInterval === 'hour') {
        tcat.push(
          dt.toLocaleString('en-us', {
            hour12: true,

            hour: 'numeric',
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      } else {
        tcat.push(
          dt.toLocaleString('en-us', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      }
    }

    setCategory(tcat);
    settransfers(transf);
  }, [hourlyStats]);

  useEffect(() => {
    let data = {};
    let end = Math.floor(new Date().getTime());
    if (networkChartTitle === '24h') {
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('24h');
    }
    if (networkChartTitle === '7d') {
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('7d');
    }
    if (networkChartTitle === '30d') {
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('30d');
    }
    if (networkChartTitle === '90d') {
      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('90d');
    }
    dispatch(getHourlyStats(data));
  }, []);

  const handleSelect = e => {
    console.log(e);
    setChartInterval(e);
    let data = {};
    let end = Math.floor(new Date().getTime());
    if (e === '24h') {
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('24h');
    }
    if (e === '7d') {
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('7d');
    }
    if (e === '30d') {
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('30d');
    }
    if (e === '90d') {
      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('90d');
    }
    dispatch(getHourlyStats(data));
  };
  return (
    <section className="leftCard">
      <div className="row">
        <h3 className="text-start col-10 ps-4"> Transfers </h3>

        <div className="col-2 d-flex justify-content-end">
          <ButtonGroup
            className="timeframe-btn-group active btn btn-group-sm-mobile"
            aria-label="Timeframe Selection"
          >
            <Button
              variant="outline-complementary "
              onClick={() => handleSelect('24h')}
              className={chartInterval === '24h' ? 'active' : ''}
            >
              24h
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('7d')}
              className={chartInterval === '7d' ? 'active' : ''}
            >
              7d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('30d')}
              className={chartInterval === '30d' ? 'active' : ''}
            >
              30d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('90d')}
              className={chartInterval === '90d' ? 'active' : ''}
            >
              90d
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div>
        {blockTimeIsLoading === true ? (
          <div class="position-absolute bottom-50 end-50">
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        ) : (
          ''
        )}
        <div>
          <ReactEChart option={eChartsOption} />
        </div>
        <div className="d-flex justify-content-center">
          <div className="row d-flex align-content-center  text-center pt-4 pb-4 mt-4 leftCard-data-container">
            <h5 class="header-subtle">New</h5>
            <h3>{Number(transfers.toFixed(2)).toLocaleString('en-US')}<sub className="text-subtle ">pha</sub></h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Transfers;
