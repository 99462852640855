import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Table } from 'react-bootstrap';
import TableScrollbar from 'react-table-scrollbar';
import Header from "../../Header";
import "../../App.css";
import { getWorkerLogs,setWorkerLog } from './phalaSlice';



const WorkerLogs= () => {
  const targetRef = useRef();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { workerLogs,selPhala } = useSelector((store) => store.phala);
  const { currentUser } = useSelector((store) => store.user);
  

  useEffect(() => {
      let dt = {
        token: currentUser.token,
        phala: {
          orgId: selPhala.org_id,
          serviceName: selPhala.serviceName
        }
      }
        
      dispatch(getWorkerLogs(dt));
      
    
    
  }, []);

  const handleRowClick = (wl) => {
    dispatch(setWorkerLog(wl));
    navigate('/logdetail');
  }


    return (
      <div>
        
        <Table className='text-white'>
          <thead>
            <tr>
              <th>Time</th>
              <th>Command</th>
              
            </tr>

          </thead>
          <tbody>
            
            {workerLogs.map(wl => (
              <tr key={wl._id} onClick={() => handleRowClick(wl)}>
                <td> {new Date(wl.timestamp).toLocaleString('en-us', {
          hour12: true,
          second: 'numeric',
          minute: 'numeric',
          hour: 'numeric',
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })}</td>
                <td> {wl.command}</td>
                
              </tr>

            ))}

          </tbody>
        </Table>
        
      </div>
    );
  
};

export default WorkerLogs;
