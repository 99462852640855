import React, { useEffect, useState } from 'react';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setAprChart } from '../../phalaSlice';
import AprByLevel from '../apr/phala.widget.AprByLevel';
import AprByStake from '../apr/phala.widget.AprByStake';
import PoolsWidget from '../pools/phala.widget.pools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import '../../../../App.css';

const Aprs = () => {
  const dispatch = useDispatch();
  const [Stake1Array, setStake1] = useState([]);
  const [Stake2Array, setStake2] = useState([]);
  const [Stake3Array, setStake3] = useState([]);
  const [Stake4Array, setStake4] = useState([]);
  const [Level1Array, setLevel1] = useState([]);
  const [Level2Array, setLevel2] = useState([]);
  const [Level3Array, setLevel3] = useState([]);
  const [Level4Array, setLevel4] = useState([]);
  const [Level5Array, setLevel5] = useState([]);
  const [AvgArray, setAvg] = useState([]);

  const [titleText, setTitle] = useState('24h');
  const [chartInterval, setChartInterval] = useState('24h');
  const [showAprByLevel, setShowAprByLevel] = useState(false);
  const {
    aprByStake,
    aprChart,
    aprByLevel,
    aprByStakeIsLoading,
    workerChartTitle
  } = useSelector(store => store.phala);

  const length = aprByStake?.length || 0;

  //Stake APR
  const stake1AprWidget =
    length > 0
      ? aprByStake.reduce((acc, cur) => acc + cur.apr_stake_lte5, 0) / length
      : 0;
  const stake2AprWidget =
    aprByStake?.length > 0
      ? aprByStake.reduce((acc, cur) => acc + cur.apr_stake_5_10, 0) /
        aprByStake.length
      : 0;
  const stake3AprWidget =
    aprByStake?.length > 0
      ? aprByStake.reduce((acc, cur) => acc + cur.apr_stake_10_15, 0) /
        aprByStake.length
      : 0;
  const stake4AprWidget =
    aprByStake?.length > 0
      ? aprByStake.reduce((acc, cur) => acc + cur.apr_stake_gt15, 0) /
        aprByStake.length
      : 0;

  //Level APR

  const level1AprWidget =
    aprByLevel?.length > 0
      ? aprByLevel.reduce((acc, cur) => acc + cur.level1, 0) / aprByLevel.length
      : 0;
  const level2AprWidget =
    aprByLevel?.length > 0
      ? aprByLevel.reduce((acc, cur) => acc + cur.level2, 0) / aprByLevel.length
      : 0;
  const level3AprWidget =
    aprByLevel?.length > 0
      ? aprByLevel.reduce((acc, cur) => acc + cur.level3, 0) / aprByLevel.length
      : 0;
  const level4AprWidget =
    aprByLevel?.length > 0
      ? aprByLevel.reduce((acc, cur) => acc + cur.level4, 0) / aprByLevel.length
      : 0;
  const level5AprWidget =
    aprByLevel?.length > 0
      ? aprByLevel.reduce((acc, cur) => acc + cur.level5, 0) / aprByLevel.length
      : 0;

  const setAprChartHandler = aprChartName => {
    dispatch(setAprChart(aprChartName));
  };

  return (
    <section className="leftCard">
      <div>
        {aprChart === 'Delegation' && (
          <div className="col m-0 p-0">
            <AprByStake />
          </div>
        )}
        {aprChart === 'Level' && (
          <div className="col m-0 p-0">
            <AprByLevel />
          </div>
        )}
      </div>

      <div className="d-flex justify-content-center  pt-4 mt-4 text-white">
        <div className="row leftCard-data-container pb-3">
          <div className="d-flex align-items-center text-subtle">
            <h5 className="pt-3 pb-2 ms-2 pe-3  text-subtle">Delegation APR</h5>
            <div className="col d-flex justify-content-end">
              <button
                className="btn btn-outline-none text-success d-flex align-items-center"
                style={{ borderRadius: '4px' }}
                onClick={() => setAprChartHandler('Delegation')}
              >
                <h6 className="mb-0 me-2 text-success">View</h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1rem"
                  fill="currentColor"
                  class="bi bi-bar-chart-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z" />
                </svg>
              </button>
            </div>
          </div>
          <div className="col-3 text-center ps-4">
            <h6 className="header-subtle"> &lt; 5K </h6>
            <h4>
              {Number(stake1AprWidget.toFixed(2)).toLocaleString('en-US') + '%'}
            </h4>
          </div>
          <div className="col-3 text-center">
            <h6 className="header-subtle"> 5K - 10K </h6>
            <h4>
              {Number(stake2AprWidget.toFixed(2)).toLocaleString('en-US') + '%'}
            </h4>
          </div>
          <div className="col-3 text-center">
            <h6 className="header-subtle">5K - 15K</h6>
            <h4>
              {Number(stake3AprWidget.toFixed(2)).toLocaleString('en-US') + '%'}
            </h4>
          </div>
          <div className="col-3 text-center pe-4">
            <h6 className="header-subtle"> &gt; 15K </h6>
            <h4>
              {Number(stake4AprWidget.toFixed(2)).toLocaleString('en-US') + '%'}
            </h4>
          </div>
          
        </div>
      </div>
      <div className="d-flex justify-content-center pt-3 text-white">
        <div className="row leftCard-data-container pb-3">
          <div className="d-flex align-items-center text-subtle">
            <h5 className="pt-3 pb-2 ms-2 pe-3  text-subtle">Level APR</h5>
            <div className="col d-flex justify-content-end">
              <button
                className="btn btn-outline-none text-success d-flex align-items-center"
                style={{ borderRadius: '4px' }}
                onClick={() => setAprChartHandler('Level')}
              >
                <h6 className="mb-0 me-2 text-success">View</h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1rem"
                  fill="currentColor"
                  class="bi bi-bar-chart-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z" />
                </svg>
              </button>
            </div>
          </div>
          <div className="col text-center ps-4">
            <h6 class="header-subtle">Level 1</h6>
            <h4>
              {Number(level1AprWidget.toFixed(2)).toLocaleString('en-US') + '%'}
            </h4>
          </div>
          <div className="col text-center">
            <h6 class="header-subtle">Level 2</h6>
            <h4>
              {Number(level2AprWidget.toFixed(2)).toLocaleString('en-US') + '%'}
            </h4>
          </div>
          <div className="col text-center">
            <h6 class="header-subtle">Level 3</h6>
            <h4>
              {Number(level3AprWidget.toFixed(2)).toLocaleString('en-US') + '%'}
            </h4>
          </div>
          <div className="col text-center">
            <h6 class="header-subtle">Level 4</h6>
            <h4>
              {Number(level4AprWidget.toFixed(2)).toLocaleString('en-US') + '%'}
            </h4>
          </div>
          <div className="col text-center pe-4">
            <h6 class="header-subtle">Level 5</h6>
            <h4>
              {Number(level5AprWidget.toFixed(2)).toLocaleString('en-US') + '%'}
            </h4>
          </div>
        </div>
      </div>
      <p className="small text-subtle p-4 pb-0">
        *APR numbers are calculated based on active workers NOT pools. Workers
        that are unresponsive or cooling down are not included in these
        calculations. Free delegation is not included in calculation.
        Calculations are directly aggregated from active workers based on past
        performance NOT future predictions.
      </p>
    </section>
  );
};

export default Aprs;
