import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { rightMenuHide } from './phalaSlice';
import '../../App.css';
import { Container, Col, Row, Accordion, Card } from 'react-bootstrap';
import Header from '../../Header';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { doPhalaDetail } from '../modal/modalSlice';
import PhalaControl from './phalaControl';
import { Table } from 'react-bootstrap';
import WorkerLogs from  './workerLogs'

const WorkerLogDetail = () => {
let navigate = useNavigate();
  const dispatch = useDispatch();
  const { workerLog,selPhala } = useSelector(store => store.phala);
  const [whichLog, setWhichLog] = useState(null);
  const [logButtons, setLogButtons] = useState(getButtons());
  const [text, setText] = useState('');
  const [contextRange, setContextRange] = useState(0);
  const [logContext, setLogContext] = useState([]);

  function getContext(currentText, currentContext)
  {
    let tempLogContext = [];
    if(currentText === '')
    {
      for( let index = 0; index < workerLog.dockerLogs[whichLog].length; index++)
      {
        tempLogContext.push(index + ": " + workerLog.dockerLogs[whichLog][index])
      }
    }
    else
    {
      //Search through the log, mark which lines match search or not.
      for( let index = 0; index < workerLog.dockerLogs[whichLog].length; index++)
      {
        //If we find a line that matches the search, add pre context lines, the line itself, and the post context lines
        if(workerLog.dockerLogs[whichLog][index].toLowerCase().includes(currentText.toLowerCase()))
        {
          for(let x = currentContext; x > 0; x--)
          {
            if(index - x >= 0)
            tempLogContext.push( (index - x) + ": " + workerLog.dockerLogs[whichLog][index-x])
          }
          tempLogContext.push(index + ": " + workerLog.dockerLogs[whichLog][index])
          for(let x = 1; x <= currentContext; x++)
          {
            if(index + x < workerLog.dockerLogs[whichLog].length)
            tempLogContext.push( (index + x) + ": " + workerLog.dockerLogs[whichLog][index+x])
          }
          tempLogContext.push(<br/>) //New Line
        }
      }
    }

    setLogContext(tempLogContext);
  }

  const onType = event => {
    setText(event.target.value);

    getContext(event.target.value, contextRange)
  }

  function getButtons()
  {
    let buttons = [];
    for( const log in workerLog.dockerLogs )
    {
      buttons.push(
        <button 
          onClick={ () => {
              setWhichLog(log);
              setText('');
              setContextRange(0);
              let tempLogContext = [];
              for( let index = 0; index < workerLog.dockerLogs[log].length; index++)
              {
                tempLogContext.push(index + ": " + workerLog.dockerLogs[log][index])
              }
              setLogContext(tempLogContext);
            }  
          }
          type="button" 
          class="btn btn-outline-warning me-2 border-0"
        > 
          <small class="ps-2">{log}</small>
        </button>
        )
    }
    return buttons;
  }
  
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  
  return ( 
    <div>
      <Header/>
      
      <section class="row g-3 ms-2 me-2" >
      <Col class="col-lg-6" style={{"margin-right": "10%"}}>
          <Card className='card bg-dark text-white'>
            <Card.Header>
              <h3>Log</h3>
            </Card.Header>
            <Card.Body>
                <Row>
                <Col>Name</Col>
                <Col>{selPhala.serviceName}</Col>
              </Row>
              <Row>
                <Col>Type</Col>
                <Col>{selPhala.monitorType}</Col>
              </Row>
              <Row>
                <Col>Time Stamp</Col>
                <Col>
                  {new Date(workerLog.timestamp).toLocaleString('en-us', {
                    hour12: true,
                    second: 'numeric',
                    minute: 'numeric',
                    hour: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                    })
                  }
                </Col>
              </Row>
              
              
            </Card.Body>
          </Card>
        </Col>

        <Col class="col-lg-6">
          <Card className='card bg-dark text-white'>
            <Card.Header>
              <h3>Worker</h3>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>IP Address</Col>
                <Col>{selPhala.linuxData.ip_address}</Col>
              </Row>
              <Row>
                <Col>CPU Cores</Col>
                <Col>{selPhala.linuxData.cores}</Col>
              </Row>
              <Row>
                <Col>Score</Col>
                <Col>{"pruntime" in selPhala ? (<p style={{"margin-bottom": 0}}>{selPhala.pruntime.score}</p>):(<p style={{"margin-bottom": 0}}>Unknown</p>)}</Col>
              </Row>
              
            </Card.Body>
          </Card>
        </Col>
        </section>

        <br/>
        
        <section class="row g-3 ms-2 me-2">
          <div style={{display: 'flex', justifyContent: "space-between"}}>
            <div style={{flex: "none"}}>
              {logButtons}
            </div>
            {whichLog && 
              <div style={{flex: "none"}}>
                <input onChange={onType} value={text} placeholder="Search" class="form-control ps-5 pe-5"/>  
              </div>
            }
            {whichLog &&
              <div style={{flex: "none", "margin-left": "10%", "margin-right": "20%"}}>
                <button onClick={ () => {setContextRange(contextRange + 1); getContext(text, contextRange + 1)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                  </svg>
                </button>
                <span style={{color: "white", }} > 
                  {" " + contextRange + " "}
                </span>
                <button onClick={ () => {setContextRange(Math.max( contextRange - 1, 0)); getContext(text, contextRange - 1) }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
                  </svg>
                </button>
              </div>
            }
          </div>
        
        {whichLog && <Col class="col-lg-12">
            <Card className='card text-white'>
                <Card.Header>
                  <h7>{whichLog}</h7>
                </Card.Header>
                <Card.Body>
                
                { logContext.map( (line) => (
                    <Row>
                      <Col>{line}</Col>
                    </Row>
                ))
                }

                </Card.Body>
            </Card>
        </Col>}
        </section>
      
    </div>
  );
};

export default WorkerLogDetail;
