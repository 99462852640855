import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import heliumReducer from '../features/helium/heliumSlice';
import phalaReducer from '../features/phala/phalaSlice';
import waterReducer from '../features/water/waterSlice';
import modalReducer from '../features/modal/modalSlice';

export * from '../features/user/userSlice';
export * from '../features/helium/heliumSlice';
export * from '../features/phala/phalaSlice';
export * from '../features/water/waterSlice';
export * from '../features/modal/modalSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    helium: heliumReducer,
    phala: phalaReducer,
    water: waterReducer,
    modal: modalReducer,
  },
});

