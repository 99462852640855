import React, { useEffect, useState } from 'react';

import {  useSelector, useDispatch } from "react-redux";
import { DropdownButton,Dropdown, Button, Offcanvas, Nav} from "react-bootstrap";
import { getOrgProfile } from "./features/phala/phalaSlice";
import { doAddOrgProfile, doLogin,doRegisterUser } from "./features/modal/modalSlice";
import { LinkContainer } from "react-router-bootstrap";
import OrgDropdown from './HeaderOrgProfileDropdown';


const HeaderOrgProfileDropdown = () => {
    const dispatch = useDispatch();
    const { orgProfile,orgProfiles } = useSelector(store => store.phala);
    const { currentUser } = useSelector((store) => store.user);
    const { showOrgs } = useSelector((store) => store.phala);
    useEffect(() => {
        let data = {
            "profile": {"org_id":currentUser.org_id},
            "token":currentUser.token
        }
        if (!orgProfile)
          dispatch(getOrgProfile(data))
      }, [currentUser.isLoggedIn]);
    const handleSelect = e => {
        console.log(e);
        if (e === "new"){
            dispatch(doAddOrgProfile())
        }
        else {
            let data = {
            "profile": {"org_id":e},
            "token":currentUser.token
        }
        dispatch(getOrgProfile(data))
    
      
        }
       };
       
        const [show, setShow] = useState(false);
      
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true); 
        
return (
    <>
    <Button variant="primary" onClick={handleShow}>
    {orgProfile.profileName}
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end" className="offcanvas-background">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Profile</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <h5 class='ps-3'><svg xmlns="http://www.w3.org/2000/svg" height="1.25rem" fill="currentColor" class="bi bi-person-lines-fill mb-1" viewBox="0 0 16 16">
  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
</svg>  Accounts</h5>
        <ul class='off-canvas-styles'>
            {
              orgProfiles.map((org) => (
                <li key={org.org_id} onClick={() => handleSelect(org.org_id)}>
                  <a href="#" class=''>{org.profileName}</a>
                </li>
              ))
            }
            <li onClick={() => handleSelect("new")}>
              <a href="#" class='text-success'> <svg xmlns="http://www.w3.org/2000/svg" height="1.25rem" fill="currentColor" class="bi bi-person-fill-add mb-1 me-1" viewBox="0 0 16 16">
  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
  <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z"/>
</svg>New Profile</a>
            </li>
          </ul>
          
        </Offcanvas.Body>
      </Offcanvas>
    
    </>
   
);
    }
export default HeaderOrgProfileDropdown;