import React, { useEffect, useState } from 'react';

import { ProgressBar } from 'react-bootstrap';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix
import {
  updateSelAccount,
  getAccountDetails,
  setAccountCharts,
  getPhalaPrice
} from '../phalaSlice';
import { useDispatch, useSelector } from 'react-redux';

const AccountListWidgets = () => {
  const dispatch = useDispatch();
  const { orgProfile, phalaPrice } = useSelector(store => store.phala);

  const totalAvailable = orgProfile.accounts.reduce(
    (acc, cur) => acc + cur.free,
    0
  );
  const totalDelegated = orgProfile.accounts.reduce(
    (acc, cur) => acc + cur.delegated,
    0
  );
  const accountTotal = orgProfile.accounts.reduce(
    (acc, cur) => acc + cur.delegated + cur.free,
    0
  );
  const totalPools = orgProfile.pools.reduce(acc => acc + 1, 0);
  const rewardsDollars = phalaPrice.price * orgProfile.rewards24;
  const totalDollars = phalaPrice.price * accountTotal;
  const delegatedDollars = phalaPrice.price * totalDelegated;
  const freeDollars = phalaPrice.price * totalAvailable;

  // Calculate percentages
  const delegatedPercentage = (totalDelegated / accountTotal) * 100;
  const availablePercentage = (totalAvailable / accountTotal) * 100;

  const setAccountWidgetHandler = accountWidgets => {
    dispatch(setAccountCharts(accountWidgets));
  };
  useEffect(() => {
    dispatch(getPhalaPrice());
  }, []);
  return (
    <>
      <section class="row p-3 ms-3 text-center d-flex justify-content-center">
        <section className="account-card text-start">
          <h4>Current Balance</h4>
          <h4>
            {Number(accountTotal.toFixed(0)).toLocaleString('en-US')}
            <sub className="text-subtle">
              <small>pha</small>
            </sub>
          </h4>
          <h6 className="small text-secondary">
            ${Number(totalDollars.toFixed(2)).toLocaleString('en-US')}
          </h6>
          <div className="col-11 mt-3">
            <div className="row">
              <div className="col-12">
                <h6>
                  Delegated:
                  <span className="ms-2">
                    {Number(totalDelegated.toFixed(0)).toLocaleString('en-US')}
                    <sub className="text-subtle">
                      <small>pha</small>
                    </sub>
                    <small className="text-subtle">
                      {' '}
                      $
                      {Number(delegatedDollars.toFixed(2)).toLocaleString(
                        'en-US'
                      )}
                    </small>
                  </span>
                </h6>
                <ProgressBar
                  now={delegatedPercentage}
                  label={`${delegatedPercentage.toFixed(2)}%`}
                />
              </div>
              <div className="col-12 mt-2">
                <h6>
                  Available:
                  <span className="ms-2">
                    {Number(totalAvailable.toFixed(0)).toLocaleString('en-US')}
                    <sub className="text-subtle">
                      <small>pha</small>
                    </sub>
                    <small className="text-subtle">
                      {' '}
                      ${Number(freeDollars.toFixed(2)).toLocaleString('en-US')}
                    </small>
                  </span>
                </h6>
                <ProgressBar
                  now={availablePercentage}
                  label={`${availablePercentage.toFixed(2)}%`}
                />
              </div>
            </div>
          </div>
        </section>

        <div
          class="card col-7 pt-3 standard-card"
          onClick={() => setAccountWidgetHandler('accountTable')}
        >
          <div class="row mt-2 ">
            <div class="col border-end">
              <h6>
                <small>Total</small>
              </h6>
              <h5>
                {Number(accountTotal.toFixed(0)).toLocaleString('en-US')}
                <sub class="ps-1">
                  <small>PHA</small>
                </sub>
              </h5>
              <h6 className="small text-secondary">
                ${Number(totalDollars.toFixed(2)).toLocaleString('en-US')}
              </h6>
            </div>
            <div class="col">
              <h6 className="small">Delegated</h6>
              <h5>
                {Number(totalDelegated.toFixed(0)).toLocaleString('en-US')}
                <sub class="ps-1">
                  <small>PHA</small>
                </sub>
              </h5>
              <h6 className="small text-secondary">
                ${Number(delegatedDollars.toFixed(2)).toLocaleString('en-US')}
              </h6>
            </div>
            <div class="col border-start">
              <h6>
                <small>Free</small>
              </h6>
              <h5>
                {Number(totalAvailable.toFixed(0)).toLocaleString('en-US')}
                <sub class="ps-1">
                  <small>PHA</small>
                </sub>
              </h5>
              <h6 className="small text-secondary">
                ${Number(freeDollars.toFixed(2)).toLocaleString('en-US')}
              </h6>
            </div>
          </div>
          <p class="m-0 d-flex justify-content-end  text-success">
            More
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.25rem"
              fill="currentColor"
              class="bi bi-arrow-right ms-1"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </p>
        </div>
        <div class="card standard-card col-4 ms-4 pt-3 pb-3">
          <div class="row">
            <h6>
              <small>24H Rewards</small>
            </h6>
            <div class="col border-end">
              <h5>
                {Number(orgProfile.rewards24).toFixed(0)}
                <sub class="ps-1">
                  <small>PHA</small>
                </sub>
              </h5>
              <h6 class="text-secondary">
                <small>${Number(rewardsDollars.toFixed(2))}</small>
              </h6>
            </div>
            <h5 class="col pt-3">
              {'apr24' in orgProfile ? (
                <p>{Number(orgProfile.apr24).toFixed(0)}%</p>
              ) : (
                <p>0</p>
              )}
            </h5>
          </div>
        </div>
        <div
          class="card col-3 mt-3 me-3 pt-3 standard-card"
          onClick={() => setAccountWidgetHandler('accountPools')}
        >
          <div class="row">
            <h6>
              <small>Pools</small>
            </h6>
            <h5 class="m-0">
              <small>
                {Number(totalPools.toFixed(0)).toLocaleString('en-US')}
              </small>
            </h5>
          </div>
          <p class="m-0 p-0 d-flex justify-content-end  text-success">
            More
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.25rem"
              fill="currentColor"
              class="bi bi-arrow-right ms-1"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </p>
        </div>
        <div
          class="card col-3 mt-3 pt-3 standard-card"
          onClick={() => setAccountWidgetHandler('accountPools')}
        >
          <div class="row">
            <h6>
              <small>PHA PRICE</small>
            </h6>
            <h5 class="m-0">
              <small>${phalaPrice.price}</small>
            </h5>
          </div>
          <p class="m-0 p-0 d-flex justify-content-end  text-success">
            More
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.25rem"
              fill="currentColor"
              class="bi bi-arrow-right ms-1"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </p>
        </div>
      </section>
    </>
  );
};
export default AccountListWidgets;
