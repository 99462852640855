import React from 'react';
import { Card,Table} from 'react-bootstrap';
import { useSelector } from "react-redux";
//import { Card,Table} from 'react-bootstrap';
import { MDBTooltip } from 'mdb-react-ui-kit';




const PhalaTransferToList = () => {
  const { accountDetails } = useSelector((store) => store.phala);
  const options = {
    
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  function truncString(str) {
    return String(str).substring(0, 4) + " ... " + String(str).slice(-5);
  }

  


    <section className='card bg-dark'>
      <div className='card-header text-white'>
        <h2>Transfer To</h2>
      </div>
      <table className='table table-dark table-striped table-hover' >
        <thead>
          <tr>
            <th scope='col'>Date</th>
            <th scope='col'>Account</th>
            <th scope='col'>Total</th>
          </tr>
        </thead>
        <tbody >
        {accountDetails.transferTo.map((tr) => (
          <tr key={tr._id}>
            <td>{new Date(tr.timestamp).toLocaleDateString("en-US",options)} { new Date(tr.timestamp).toLocaleTimeString("en-US")}</td>
            <td><MDBTooltip tag="a" title={tr.fromAccount}>{truncString(tr.fromAccount)}</MDBTooltip></td>
            <td>{Number(tr.pha).toLocaleString("en-US")}</td>
          </tr>))}
        </tbody>
      </table>
    </section>
};


export default PhalaTransferToList;
