import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../App.css';
import { Container, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { HasPrivilege } from '../user/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCopy } from '@fortawesome/free-solid-svg-icons';
import { ToggleButton, ButtonGroup } from 'react-bootstrap';
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import {
  updateSetSendNotification,
  updateSetAutoRestart,
  sendServerCommandList
} from './phalaSlice';
import { prbWorkerCmd } from './serverCmd';
const PhalaControl = () => {
  const { selPhala } = useSelector(store => store.phala);
  const { currentUser } = useSelector(store => store.user);
  const [restartValue, setRestartValue] = useState('0');
  const [notificationValue, setNotificationValue] = useState(false);
  const [selectedCommand, setSelectedCommand] = useState([]);
  const dispatch = useDispatch();

  const SetNotification = tvalue => {
    //setNotificationValue(e.currentTarget.checked)

    let dt = {
      token: currentUser.token,
      phala: {
        phala_server_id: selPhala.phala_server_id,
        send_down_notification: tvalue
      }
    };
    dispatch(updateSetSendNotification(dt));
    //console.log(e.currentTarget.value);
  };
  const SetRestart = tvalue => {
    let dt = {
      token: currentUser.token,
      phala: {
        serviceName: selPhala.serviceName,
        auto_restart_on_sync: tvalue
      }
    };
    dispatch(updateSetAutoRestart(dt));
    //console.log(e.currentTarget.value);
  };

  const handleSendCommandList = () => {
    let cmds = [];
    for (let x = 0; x < selectedCommand.length; x++) {
      let tcmd = selectedCommand[x];
      cmds.push(tcmd);
    }
    let dt = {
      token: currentUser.token,
      phala: {
        orgId: selPhala.org_id,
        serviceName: selPhala.serviceName,
        command: cmds
      }
    };
    console.log(dt);
    const updatedSelectedCommand = []

    dispatch(sendServerCommandList(dt));
    setSelectedCommand(updatedSelectedCommand)
  };

  const handleSelectCmd = r => {
    console.log(r);
    setSelectedCommand([...selectedCommand, r]);
  };
  const handleRemoveCmd = r => {
    const updatedSelectedCommand = selectedCommand.filter(cmd => cmd !== r);
    setSelectedCommand(updatedSelectedCommand);
  };

  return (
    <Container>
      <div class="row">
        <h6 class="col-7">Text Notifications</h6>
        <div class="col-5">
          <ButtonGroup>
            <ToggleButton
              key="1"
              id="notification1"
              type="radio"
              name="notificationbtn"
              value="1"
              className="btn-success"
              checked={selPhala.send_down_notification}
              onChange={e => SetNotification(true)}
            >
              On
            </ToggleButton>
            <ToggleButton
              key="0"
              id="notification2"
              type="radio"
              name="notificationbtn"
              value="0"
              className="btn-danger"
              checked={!selPhala.send_down_notification}
              onChange={e => SetNotification(false)}
            >
              Off
            </ToggleButton>
          </ButtonGroup>
        </div>
      </div>
      <div class="row pt-3">
        <h6 class="col-7">Auto Restart</h6>
        <div class="col-5">
          <ButtonGroup>
            <ToggleButton
              key="1"
              id="restart1"
              type="radio"
              name="restartbtn"
              value="1"
              className="btn-success"
              checked={selPhala.auto_restart_on_sync}
              onChange={e => SetRestart(true)}
            >
              On
            </ToggleButton>
            <ToggleButton
              key="0"
              id="restart2"
              type="radio"
              name="restartbtn"
              value="0"
              className="btn-danger"
              checked={!selPhala.auto_restart_on_sync}
              onChange={e => SetRestart(false)}
            >
              Off
            </ToggleButton>
          </ButtonGroup>
        </div>
      </div>
      <div class="row pt-3">
        <div class="row pt-3">
          <div class="col-6">
            {prbWorkerCmd.map(ph => (
              <Row>
                <Col onClick={() => handleSelectCmd(ph)}>{ph.name}</Col>
              </Row>
            ))}
          </div>
          <div class="col-6">
            {selectedCommand.map(ph => (
              <Row key={ph.name}>
                <Col onClick={() => handleRemoveCmd(ph)}>{ph.name}</Col>
              </Row>
            ))}
            {selectedCommand.length > 0 && (
              <Row>
                <Col>
                  <ToggleButton
                    key="0"
                    id="sendreset"
                    type="button"
                    name="sendreset"
                    value="0"
                    className="btn-danger"
                    onClick={e => handleSendCommandList()}
                  >
                    Send Commands
                  </ToggleButton>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PhalaControl;
