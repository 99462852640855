// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body {
 
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
  height: 100vh;
  overflow-x: hidden;
  

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  background-color: rgb(18, 18, 18);
  
  -webkit-backdrop-filter: blur(px);
  
          backdrop-filter: blur(px);
  padding: 20px 0 20px 0;
  
}
.content-wrap {
  flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;;EAEE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,yBAAyB;EACzB,aAAa;EACb,kBAAkB;;;AAGpB;;AAEA;EACE;aACW;AACb;;AAEA;EACE,iCAAiC;;EAEjC,iCAAyB;;UAAzB,yBAAyB;EACzB,sBAAsB;;AAExB;AACA;EACE,SAAO;AACT","sourcesContent":["\nbody {\n \n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #121212;\n  height: 100vh;\n  overflow-x: hidden;\n  \n\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.header {\n  background-color: rgb(18, 18, 18);\n  \n  backdrop-filter: blur(px);\n  padding: 20px 0 20px 0;\n  \n}\n.content-wrap {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
