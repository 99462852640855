import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rightMenuHide } from './phalaSlice';
import '../../App.css';
import { Container, Col, Row, Accordion, Card } from 'react-bootstrap';
import Header from '../../Header';
import WorkerLogs from  './workerLogs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { doPhalaDetail } from '../modal/modalSlice';
import PhalaControl from './phalaControl';
import { Table } from 'react-bootstrap';
import Footer from "../../footer";

const PhalaDetailSolo = ({ tPhala }) => {
  const dispatch = useDispatch();
  const { selPhala } = useSelector(store => store.phala);
  function truncString(str) {
    return String(str).substring(0, 4) + ' ... ' + String(str).slice(-5);
  }
  function diff_dates_sec(dt1, dt2) {
    var mdt = new Date(dt2);
    var mdt1 = new Date(dt1);
    var diff = mdt - mdt1; // / 1000;
    diff /= 3600;
    return Math.abs(Math.round(diff));
  }
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  const handleClick = tph => {
    dispatch(rightMenuHide());
  };
  const handleControlClick = () => {
    dispatch(doPhalaDetail(selPhala));
  };
  return (
    <div>
      
      <section class="row text-light mt-3">
        <div class="col ms-3">
        <h5>Type: {selPhala.monitorType}</h5>
          <h5>Name: {selPhala.serviceName}</h5>
          
        </div>
       
        <div class="col">
        <button
            onClick={() => {
              handleControlClick();
            }}
            
          class="btn btn-outline-warning float-end me-5"> <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-sliders mb-1 me-2" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"/>
        </svg>
            Settings
          </button>
        </div>
      </section>
      <section class="row g-3 ms-2 me-2">
      
        <Col class="col-lg-6">
          <Card className='card leftCard text-white'>
            <Card.Header>
              <h3>Worker</h3>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>IP Address</Col>
                <Col>{selPhala.linuxData.ip_address}</Col>
              </Row>
              <Row>
                <Col>CPU Cores</Col>
                <Col>{selPhala.linuxData.cores}</Col>
              </Row>
              <Row>
                <Col>Score</Col>
                <Col>{selPhala.pruntime.score}</Col>
              </Row>
              <Row>
                <Col>Level</Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>Pool Account</Col>
                <Col>{selPhala.soloEnv.operator}</Col>
              </Row>
              <Row>
                <Col>Gas Account</Col>
                <Col>{selPhala.soloEnv.gasAccount}</Col>
              </Row>
              <Row>
                <Col>Gas</Col>
                <Col>{selPhala.gas}</Col>
              </Row>
              <Row>
                <Col>Public Key</Col>
                <Col>{selPhala.pubkey}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col class="col-lg-6">
          <Card className='card leftCard text-white'>
            <Card.Header>
              <h3>Sync</h3>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>Khala Current</Col>
                <Col>{Number(selPhala.phalaData.currentBlock).toFixed(0)}</Col>
              </Row>
              <Row>
                <Col>Khala Sync</Col>
                <Col>
                  {Number(selPhala.phalaData.highestBlock - selPhala.phalaData.currentBlock).toFixed(
                    0
                  )}
                </Col>
              </Row>
             
              <Row>
                <Col>Kusama Current</Col>
                <Col>{Number(selPhala.polkadotData.currentBlock).toFixed(0)}</Col>
              </Row>
              <Row>
                <Col>Kusama Sync</Col>
                <Col>
                  {Number(
                    selPhala.polkadotData.highestBlock - selPhala.polkadotData.currentBlock
                  ).toFixed(0)}
                </Col>
              </Row>
              

              <Row>
                <Col>Khala Pherry</Col>
                <Col>
                  {Number(selPhala.deltaKhala).toFixed(
                    0
                  )}
                </Col>
              </Row>
              <Row>
                <Col>Kusama Pherry</Col>
                <Col>
                  {Number(
                    selPhala.deltaKusama 
                  ).toFixed(0)}
                </Col>
              </Row>

             
            </Card.Body>
          </Card>
        </Col>
        
        </section>
        <Col class="col mt-3">
          <Card className='card leftCard text-white'>
            <Card.Header>
              <h3>Logs</h3>
            </Card.Header>
            <Card.Body>
              <WorkerLogs/>
            </Card.Body>
          </Card>
        </Col>
        
    </div>
  );
};

export default PhalaDetailSolo;
