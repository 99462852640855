import React, { useEffect, useState } from 'react';
import ReactEChart from 'echarts-for-react';
import { ProgressBar } from 'react-bootstrap';
import {
  getPhalaPrice,
  getTokenHistory,
  setNetworkChartTitle
} from '../phalaSlice';
import { useDispatch, useSelector } from 'react-redux';

const PhalaWalletsSummary = () => {
  const dispatch = useDispatch();
  const [categoryArray, setCategory] = useState([]);
  const [twentyFourHourArray, setTwentyFourHourArray] = useState([0]);
  const [chartInterval, setChartInterval] = useState('hour');
  const { orgProfile, phalaPrice, tokenHistory } = useSelector(
    store => store.phala
  );

  const totalAvailable = orgProfile.accounts.reduce(
    (acc, cur) => acc + cur.free,
    0
  );
  const totalDelegated = orgProfile.accounts.reduce(
    (acc, cur) => acc + cur.delegated,
    0
  );
  const accountTotal = orgProfile.accounts.reduce(
    (acc, cur) => acc + cur.delegated + cur.free,
    0
  );
  const accountCount = orgProfile.accounts.reduce((acc, cur) => acc + 1, 0);
  const totalDollars = phalaPrice.price * accountTotal;
  const delegatedDollars = phalaPrice.price * totalDelegated;
  const freeDollars = phalaPrice.price * totalAvailable;

  // Calculate percentages
  const delegatedPercentage = (totalDelegated / accountTotal) * 100;
  const availablePercentage = (totalAvailable / accountTotal) * 100;
  useEffect(() => {
    dispatch(getPhalaPrice());
  }, []);

  function shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    const RR =
      R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
  }
  const generateShades = (baseColor, shadeCount) => {
    const shades = [];
    const maxLighten = 80; // Lighter shades
    const maxDarken = -40; // Moderately darker shades

    for (
      let i = maxDarken;
      i <= maxLighten;
      i += (maxLighten - maxDarken) / (shadeCount - 1)
    ) {
      shades.push(shadeColor(baseColor, i));
    }

    return shades;
  };

  const baseColor = '#4FB3BF';
  const colors = generateShades(baseColor, 5); // Generate 5 shades, for example

  const getOption = () => ({
    title: {
      text: `Wallets \n\n${accountCount}`,
      left: 'center',
      top: 'center',
      textStyle: {
        color: '#a8a8a8',
        fontSize: 25
        // Other styling as needed
      }
    },
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(50, 50, 50, 0.7)', // Dark grey with transparency
      textStyle: {
        color: '#E0E0E0'
      },
      formatter: function (params) {
        let value = parseFloat(params.value).toFixed(5);
        let formattedValue = Number(value).toLocaleString(); // Format with commas
        return `${params.name}: ${formattedValue} (${params.percent}%)`;
      },
      borderWidth: 0
    },
    legend: {
      show: false,
      top: 'bottom',
      left: 'center'
    },

    color: colors,
    series: [
      {
        name: 'Total Tokens',
        type: 'pie',
        radius: ['70%', '80%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: true,
        itemStyle: {
          borderRadius: 6,
          borderColor: 'none',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 20, // Customize as needed
            fontWeight: 'normal', // Customize as needed
            color: '#FFFFFF' // Customize as needed
          }
        },
        labelLine: {
          show: false
        },
        data: orgProfile.accounts
          .map(account => ({
            name: account.name,
            value: account.delegated + account.free
          }))
          .sort((a, b) => a.value - b.value)
      }
    ]
  });
  const startPrice = twentyFourHourArray[0];
  const endPrice = twentyFourHourArray[twentyFourHourArray.length - 1];
  const chartColor = endPrice > startPrice ? '#4DBD74' : '#FF4D4D';
  const calculatePercentageChange = () => {
    if (startPrice === 0) return 'N/A'; // Handle division by zero
    return (((endPrice - startPrice) / startPrice) * 100).toFixed(2); // Rounded to two decimal places
  };
  const eChartsOption = {
    tooltip: {
      show: false
    },
    legend: {
      data: ['Price'],
      show: false
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      show: false
    },
    grid: [
      {
        height: 'auto',
        bottom: '10%',
        top: '6%'
      }
    ],
    yAxis: [
      {
        name: 'Seconds',
        type: 'value',
        show: false
      },
      {
        name: 'Rewards',
        position: 'right',
        type: 'value',
        show: false
      }
    ],
    series: [
      {
        name: 'Price',
        data: twentyFourHourArray,
        type: 'line',
        smooth: false,
        showSymbol: false,
        color: chartColor
      }
    ]
  };

  useEffect(() => {
    console.log('data Changed');
    let twentyFourHour = [];

    let tcat = [];

    for (let m of tokenHistory) {
      //tcat.push(String(m._id))
      twentyFourHour.push(m.averagePrice);
      let dt = new Date(Math.floor(m._id));
      console.log('Chart Interval: ' + chartInterval);
      //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
      if (chartInterval === 'hour') {
        tcat.push(
          dt.toLocaleString('en-us', {
            hour12: true,

            hour: 'numeric',
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      } else {
        tcat.push(
          dt.toLocaleString('en-us', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      }
    }

    setCategory(tcat);
    setTwentyFourHourArray(twentyFourHour);
  }, [tokenHistory]);

  useEffect(() => {
    let end = Math.floor(new Date().getTime());
    let start = Math.floor(end - 86400000); // 24 Hours

    let data = {
      start: start,
      end: end,
      interval: 'hour'
    };

    setChartInterval('hour');
    dispatch(getTokenHistory(data));
  }, []);

  const handleSelect = e => {
    console.log(e);
    setChartInterval(e);
    let end = Math.floor(new Date().getTime());
    let start = Math.floor(end - 86400000); // 24 Hours
    let data = {
      start: start,
      end: end,
      interval: 'hour'
    };
    setChartInterval('24h');
    dispatch(getTokenHistory(data));
    dispatch(setNetworkChartTitle(e));
  };

  return (
    <>
      <section class="row pb-3 ms-3 mt-3 ">
        <section className="text-start col-7">
          <h4>Current Balance</h4>
          <h4>
            {Number(accountTotal.toFixed(0)).toLocaleString('en-US')}
            <sub className="text-subtle">
              <small>pha</small>
            </sub>
          </h4>
          <h6 className="small text-secondary">
            ${Number(totalDollars.toFixed(2)).toLocaleString('en-US')}
          </h6>
          <div className="col-11 mt-3">
            <div className="row">
              <div className="col-12 pb-2 pt-4">
                <h6 className='d-flex justify-content-between'>
                  Delegated
                  <div className="pt-1 ps-2">
                    <span>
                      {Number(totalDelegated.toFixed(0)).toLocaleString(
                        'en-US'
                      )}
                      <sub className="text-subtle">
                        <small>pha</small>
                      </sub>
                      <small className="text-subtle">
                        {' '}
                        $
                        {Number(delegatedDollars.toFixed(2)).toLocaleString(
                          'en-US'
                        )}
                      </small>
                    </span>
                  </div>
                </h6>
                <ProgressBar
                  now={delegatedPercentage}
                  label={`${delegatedPercentage.toFixed(2)}%`}
                />
              </div>
              <div className="col-12">
                <h6 className='d-flex justify-content-between'>
                  Available
                  <div className="pt-1 ps-2">
                    <span>
                      {Number(totalAvailable.toFixed(0)).toLocaleString(
                        'en-US'
                      )}
                      <sub className="text-subtle">
                        <small>pha</small>
                      </sub>
                      <small className="text-subtle">
                        {' '}
                        $
                        {Number(freeDollars.toFixed(2)).toLocaleString('en-US')}
                      </small>
                    </span>
                  </div>
                </h6>
                <ProgressBar
                  now={availablePercentage}
                  label={`${availablePercentage.toFixed(2)}%`}
                />
              </div>
            </div>
          </div>
          <section
            className="row leftCard-data-container mt-4 "
            style={{ width: '96%', height: '5em' }}
          >
            <div className="col-6 pt-3">
              <h6>PHA</h6>
              <h6>
                ${phalaPrice?.price}
                {twentyFourHourArray.length > 1 && (
                  <>
                    <sub>
                      {endPrice > startPrice ? (
                        <span style={{ color: '#4DBD74' }}>
                          {' '}
                          ▲ {calculatePercentageChange()}%
                        </span>
                      ) : (
                        <span style={{ color: '#FF4D4D' }}>
                          {' '}
                          ▼ {calculatePercentageChange()}%
                        </span>
                      )}
                    </sub>
                  </>
                )}
              </h6>
            </div>
            <div className="col-6">
              <ReactEChart option={eChartsOption} />
            </div>
          </section>
        </section>
        <div className="col-5 mt-5">
          <ReactEChart option={getOption()} />
        </div>
      </section>
    </>
  );
};
export default PhalaWalletsSummary;
