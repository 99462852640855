import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../Header";
import '../../App.css';
import AprStakeAmountTest from "./aprStakeAmountTest";
import BlockTimeTest from "./blockTimeTest";



const MobileTest = () => {
    
 
return (
 <>
 <section><Header/></section>
 <section><BlockTimeTest/></section>
 </>
);
};
export default MobileTest;