import React from 'react';
import { useSelector } from "react-redux";

import { Card,Table} from 'react-bootstrap';
import '../../../../App.css';


const PhalaDelegationList = () => {
  
  const { accountDetails } = useSelector((store) => store.phala);
  
  
   
  return (
  
    <section className='card bg-dark'>
      <div className='card-header text-white'>
        <h2 className=''>Delegation</h2>
      </div>
      <table className='table table-dark table-hover table-striped table-responsive'>
        <thead>
          <tr>
            <th scope='col'>PID</th>
            <th scope='col'>Tyoe</th>
            <th scope='col'>Total Stake</th>
            <th scope='col'>Free Stake</th>
            <th scope='col'>Cap</th>
            <th scope='col'>Delegated</th>
            <th scope='col'>24 Hr</th>
          </tr>
        </thead>
        <tbody>
        {accountDetails.delegation.map((tr) => (
          <tr key={tr.pid}>
            <td scope='row'>{tr.pid}</td>
            <td>{tr.isOwner}</td>
            <td>{Number(tr.totalStake).toFixed(1)}</td>
            <td>{Number(tr.freeStake).toFixed(1)}</td>
            <td>{Number(tr.cap).toFixed(1)}</td>
            <td>{Number(tr.currentPha).toFixed(1)}</td>
            <td>{Number(tr.reward24).toFixed(1)}</td>
          </tr> ))}
        </tbody>
      </table>

    </section>
    
  );
};


export default PhalaDelegationList;
