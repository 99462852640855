import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


//Components
const Footer = () => {
  

  return (
  <>
    <footer className="footer bg-primary mt-4">
  <div className="p-3 ">
    <span className="text-muted">&copy; 2023 Cyber Jungle LLC. All rights reserved.</span>
  </div>
    </footer>
    </>
    
  ); 
};

export default Footer;
