import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../App.css";


import { updateUser, updateSelUser, getAllUsers, registerUser } from "./userSlice";
import { closeModal,doLogin } from '../modal/modalSlice';

const RegisterUser = () => {
  const { selUser, currentUser } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  

  useEffect(() => {
    const usr = {
        user_id: "",
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zip: "",
        password: "",
        password1: "",
        user_status: "Pending",
      };
      dispatch(updateSelUser(usr));
  },[]);

  const handleFormChange = ({ currentTarget: input }) => {
    console.log(input.value);

    const usr = {
      user_id: selUser.user_id,
      email: selUser.email,
      first_name: selUser.first_name,
      last_name: selUser.last_name,
      phone: selUser.phone,
      address_1: selUser.address_1,
      address_2: selUser.address_2,
      city: selUser.city,
      state: selUser.state,
      zip: selUser.zip,
      password: selUser.password,
      password1: selUser.password1,
      
    };
    usr[input.name] = input.value;
    //console.log(this.state);
    //this.setState({ login });
    console.log(usr);
    dispatch(updateSelUser(usr));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Before dispatch");

    if(selUser.password === selUser.password1)
        dispatch(registerUser(selUser));
        dispatch(doLogin());

    console.log(selUser);
    
    
  };
  
  return (
    <div className="app-header">
    
      <form onSubmit={handleFormSubmit}>
        
          <div class="input-group mb-3 "> 
          
          <span class="input-group-text">
          <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="1.5rem" 
          fill="currentColor" 
          class="bi bi-envelope-fill" 
          viewBox="0 0 16 16">
          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
          </svg>
            </span>
            
          <input
                  value={selUser.email}
                  id="email"
                  name="email"
                  onChange={handleFormChange}
                  type="text"
                  class="form-control subtle-input pr-5"
                  placeholder="Email"
                />
          
          </div>

          <div class="input-group mb-3 ">
          
          <span class="input-group-text">
          <svg xmlns="http://www.w3.org/2000/svg"
               width="1.5rem" 
               fill="currentColor" 
               class="bi bi-person-fill" 
               viewBox="0 0 16 16">
          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
          </svg>
            </span>
            <input
                  value={selUser.first_name}
                  id="first_name"
                  name="first_name"
                  onChange={handleFormChange}
                  type="text"
                  class="form-control subtle-input pr-5"
                  placeholder="First Name"
                />
                <input
                  value={selUser.last_name}
                  id="last_name"
                  name="last_name"
                  onChange={handleFormChange}
                  type="text"
                  class="form-control subtle-input pr-5"
                  placeholder="Last Name"
                />
          </div>
          <div class="input-group mb-3 ">
          
          <span class="input-group-text">
          <svg xmlns="http://www.w3.org/2000/svg" 
               width="1.5rem"
               fill="currentColor" 
               class="bi bi-key-fill" 
               viewBox="0 0 16 16">
            <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
          </svg>
            </span>
            <input
                  value={selUser.password}
                  id="password"
                  name="password"
                  onChange={handleFormChange}
                  type="text"
                  class="form-control subtle-input pr-5"
                  placeholder="Password"
                />
                
          </div>
          <div class="input-group mb-3 ">
          
          <span class="input-group-text">
          <svg xmlns="http://www.w3.org/2000/svg" 
               width="1.5rem"
               fill="currentColor" 
               class="bi bi-key-fill" 
               viewBox="0 0 16 16">
            <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
          </svg>
            </span>
            <input
                  value={selUser.password1}
                  id="password1"
                  name="password1"
                  onChange={handleFormChange}
                  type="text"
                  class="form-control subtle-input pr-5"
                  placeholder="Confirm Password"
                />
          </div>
          <div className="d-flex justify-content-center mt-3 mb-1">
          <button type="submit" className="btn btn-success rounded-pill px-4 wide-button" disabled>Submit</button>
      </div>
      </form>
    </div>
  );
};

export default RegisterUser;
