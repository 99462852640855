import React, { useEffect, useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import ReactEChart from 'echarts-for-react';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import {
  setNetworkChartTitle,
  getBlockTime,
  getHourlyStats
} from '../../phalaSlice';
import '../../../../App.css';

const Vscore1h = () => {
  const dispatch = useDispatch();
  const [categoryArray, setCategory] = useState([]);
  const [fiveThousandArray, setFiveThousand] = useState([]);
  const [fiveToTenThousandArray, setFiveToTenThousand] = useState([]);
  const [tenToFifteenThousandArray, setTenToFifteenThousand] = useState([]);
  const [fifteenToTwentyThousandArray, setFifteenToTwentyThousand] = useState(
    []
  );
  const [greaterThanTwentyThousandArray, setGreaterThanTwentyThousand] =
    useState([]);
  const [chartType, setChartType] = useState('bar');

  const [chartInterval, setChartInterval] = useState('24h');

  //let miningArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,24,24];

  const { hourlyStats, responsiveIsLoading, networkChartTitle } = useSelector(
    store => store.phala
  );

  const eChartsOption = {
    tooltip: {
      trigger: 'axis',
      position: 'top', // set the tooltip position to top
      offset: [0, 10], // set the vertical offset to 10 pixels
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: '#c8e2f7',
      textStyle: {
        color: '#000'
      },
      formatter: function (params) {
        let result = '';
        params.forEach((param) => {
          // Assuming 'param' contains the series name and data value
          const roundedValue = Math.round(param.value).toLocaleString();
          result += `${param.marker}${param.seriesName}: ${roundedValue}<br/>`;
        });
        return result;
      },
    },
    toolbox: {
      feature: {},
      iconStyle: {
        borderColor: 'white'
      }
    },
    textStyle: {
      color: '#fff'
    },
    legend: {
      data: [
        '<5,000',
        '5,000-10,000',
        '10,001-15,000',
        '15,001-20,000',
        '>20,000'
      ],
      top: '25',
      inactiveColor: '#555555',

      textStyle: {
        color: 'white'
      }
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },

    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          opacity: 0.08 // set the opacity of the line to 50%
        }
      },
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },
    grid: [
      {
        left: '10%',
        right: '5%',
        height: '64%',
        top: '30%',
        

      }
    ],
    series: [
      {
        name: '<5,000',
        data: fiveThousandArray,
        type: 'bar',
        smooth: true,
        showSymbol: false,
        color: '#5C3ABD'
      },
      {
        name: '5,000-10,000',
        data: fiveToTenThousandArray,
        type: 'bar',
        smooth: true,
        showSymbol: false,
        color: '#4FB3BF'
      },
      {
        name: '10,001-15,000',
        data: tenToFifteenThousandArray,
        type: 'bar',
        smooth: true,
        showSymbol: false,
        color: '#D1BE65'
      },
      {
        name: '15,001-20,000',
        data: fifteenToTwentyThousandArray,
        type: 'bar',
        smooth: true,
        showSymbol: false,
        color: '#E88834'
      },
      {
        name: '>20,000',
        data: greaterThanTwentyThousandArray,
        type: 'bar',
        smooth: true,
        showSymbol: false,
        color: '#AD5CBD'
      }
    ]
  };

  const pieChartOption = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'vertical',
      right: '5%', // Adjust as needed for positioning
      top: 'middle',
      itemWidth: 20, // Larger symbol width
      itemHeight: 14, // Larger symbol height
      textStyle: {
        color: 'white',
        fontSize: 16 // Larger font size
      }
    },
    // Media queries
    media: [
      {
        // For smaller screens
        query: {
          maxWidth: 500 // adjust this value based on your needs
        },
        option: {
          legend: {
            orient: 'horizontal',
            bottom: '0%', // position at the bottom
            top: null, // remove the top property
            right: 'center' // center align the legend
          },
          series: [
            {
              // Adjust series properties if necessary for smaller screens
              radius: ['0%', '60%'], // adjust the radius
              center: ['50%', '40%'] // re-center the pie chart
            }
          ]
        }
      },
      {
        // For larger screens
        query: {
          minWidth: 501 // adjust this value based on your needs
        },
        option: {
          legend: {
            orient: 'vertical',
            right: '5%',
            top: 'middle'
          },
          series: [
            {
              // Reset series properties for larger screens if needed
              radius: ['0%', '70%'], // original radius
              center: ['40%', '50%'] // original center position
            }
          ]
        }
      }
    ],

    series: [
      {
        name: 'V-Score',
        type: 'pie',
        radius: ['0%', '70%'], // Adjust the outer radius to make the pie bigger
        center: ['40%', '50%'], // Adjust the center position as needed
        label: {
          show: false
        },
        data: [
          {
            value: fiveThousandArray.reduce((a, b) => a + b, 0),
            name: '<5,000',
            itemStyle: {
              color: '#5C3ABD' // Color for <5,000
            }
          },
          {
            value: fiveToTenThousandArray.reduce((a, b) => a + b, 0),
            name: '5,000-10,000',
            itemStyle: {
              color: '#4FB3BF' // Color for 5,000-10,000
            }
          },
          {
            value: tenToFifteenThousandArray.reduce((a, b) => a + b, 0),
            name: '10,001-15,000',
            itemStyle: {
              color: '#D1BE65' // Color for 10,001-15,000
            }
          },
          {
            value: fifteenToTwentyThousandArray.reduce((a, b) => a + b, 0),
            name: '15,001-20,000',
            itemStyle: {
              color: '#E88834' // Color for 15,001-20,000
            }
          },
          {
            value: greaterThanTwentyThousandArray.reduce((a, b) => a + b, 0),
            name: '>20,000',
            itemStyle: {
              color: '#AD5CBD' // Color for >20,000
            }
          }
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  const toggleChart = () => {
    setChartType(chartType === 'bar' ? 'pie' : 'bar');
  };

  useEffect(() => {
    console.log('data Changed');
    let lessFive = [];
    let fiveToTen = [];
    let tenToFifteen = [];
    let fifteenToTwenty = [];
    let greaterTwenty = [];

    let tcat = [];
    for (let m of hourlyStats) {
      //tcat.push(String(m._id))
      lessFive.push(m.avgVscore24_5000);
      fiveToTen.push(m.avgVscore24_10000);
      tenToFifteen.push(m.avgVscore24_15000);
      fifteenToTwenty.push(m.avgVscore24_20000);
      greaterTwenty.push(m.avgVscore24_2000gt);
      let dt = new Date(Math.floor(m.minTime));
      console.log(dt);
      //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
      if (chartInterval === 'hour') {
        tcat.push(
          dt.toLocaleString('en-us', {
            hour12: true,

            hour: 'numeric',
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      } else {
        tcat.push(
          dt.toLocaleString('en-us', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      }
    }

    setCategory(tcat);
    setFiveThousand(lessFive);
    setFiveToTenThousand(fiveToTen);
    setTenToFifteenThousand(tenToFifteen);
    setFifteenToTwentyThousand(fifteenToTwenty);
    setGreaterThanTwentyThousand(greaterTwenty);
  }, [hourlyStats]);

  useEffect(() => {
    console.log('getting responsive by hour (useEffect)');
    let data = {};
    let end = Math.floor(new Date().getTime());
    if (networkChartTitle === '24h') {
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('24h');
    }
    if (networkChartTitle === '7d') {
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('7d');
    }
    if (networkChartTitle === '30d') {
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('30d');
    }
    if (networkChartTitle === '90d') {
      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('90d');
    }
    dispatch(getHourlyStats(data));
  }, []);

  const handleSelect = e => {
    console.log(e);
    setChartInterval(e);
    let data = {};
    let end = Math.floor(new Date().getTime());
    if (e === '24h') {
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('24h');
    }
    if (e === '7d') {
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('7d');
    }
    if (e === '30d') {
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('30d');
    }
    if (e === '90d') {
      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('90d');
    }
    dispatch(getHourlyStats(data));
    dispatch(setNetworkChartTitle(e));
  };

  return (
    <section className="leftChartCard pb-0">
      <div className="row">
        <Tooltip
          title="V-Score calculated in 1 hour snapshots."
          placement="top"
        >
          <h3 className="text-start col-10 ps-4">
            1H V-Score
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              fill="currentColor"
              class="bi bi-info-circle mt-1 ms-1"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          </h3>
        </Tooltip>
        <div className="col-2 d-flex justify-content-end">
          <ButtonGroup
            className="timeframe-btn-group active btn btn-group-sm-mobile"
            aria-label="Timeframe Selection"
          >
            <Button
              variant="outline-complementary "
              onClick={() => handleSelect('24h')}
              className={chartInterval === '24h' ? 'active' : ''}
            >
              24h
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('7d')}
              className={chartInterval === '7d' ? 'active' : ''}
            >
              7d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('30d')}
              className={chartInterval === '30d' ? 'active' : ''}
            >
              30d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('90d')}
              className={chartInterval === '90d' ? 'active' : ''}
            >
              90d
            </Button>
          </ButtonGroup>
        </div>
        <div>
          {responsiveIsLoading === true ? (
            <div class="position-absolute bottom-50 end-50">
              <div class="spinner-border text-warning" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          ) : (
            ''
          )}
          <div style={{ height: '100%' }}>
            {chartType === 'bar' ? (
              <ReactEChart key="barChart" option={eChartsOption} />
            ) : (
              <ReactEChart key="pieChart" option={pieChartOption} />
            )}
          </div>
        </div>
      </div>
      <div className="chart-toggle-button d-flex justify-content-end pe-3 pt-3 ">
        <Button onClick={toggleChart}>
          Switch to {chartType === 'bar' ? 'Pie' : 'Bar'} Chart
        </Button>
      </div>
    </section>
  );
};

export default Vscore1h;
