import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Card, Col, Row,Table} from 'react-bootstrap';
import ReactEChart from 'echarts-for-react';






const WidgetPoolList = () => {
  
  const { orgProfile } = useSelector((store) => store.phala);

  const stakeSum = orgProfile.pools.reduce((acc, cur) => acc + cur.totalStake, 0);
  const freeStakeSum = orgProfile.pools.reduce((acc, cur) => acc + cur.freeStake, 0);
  const releasingStakeSum = orgProfile.pools.reduce((acc, cur) => acc + cur.releasingStake, 0);
  const workersSum = orgProfile.pools.reduce((acc, cur) => acc + cur.workers.length, 0);
  const totalApr = (orgProfile.pools.reduce((acc, cur) => acc + cur.averageApr, 0)) / orgProfile.pools.length;
 
   
  return (

    <section className="card standard-table">

      <div>
        <h3 className='p-3'>Pools</h3>
      </div>
      <table className='table text-white table-hover table-striped col m-0 container'>
        <thead>
          <tr>
            <th scope='col'>Id</th>
            <th scope='col'>Stake</th>
            <th scope='col'>Free</th>
            <th scope='col'>Releasing</th>
            <th scope='col'>APR</th>
            <th scope='col'>Workers</th>
          </tr>
        </thead>
        <tbody>
        {orgProfile.pools.map((op) => (
          <tr>
            <td>{op._id}</td>
            <td>{Number((op.totalStake).toFixed(2)).toLocaleString("en-US")}</td>
            <td>{Number((op.freeStake).toFixed(2)).toLocaleString("en-US")}</td>
            <td>{Number((op.releasingStake).toFixed(2)).toLocaleString("en-US")}</td>
            <td>{Number((op.averageApr).toFixed(2)).toLocaleString("en-US")}%</td>
            <td>{Number(op.workers.length).toLocaleString("en-US")}</td>
          </tr>))}
        </tbody>
        <tfoot className='mb-2'>
            <th></th>
            <td>{Number((stakeSum).toFixed(2)).toLocaleString("en-US")}</td>
            <td>{Number((freeStakeSum).toFixed(2)).toLocaleString("en-US")}</td>
            <td>{Number((releasingStakeSum).toFixed(2)).toLocaleString("en-US")}</td>
            <td>{Number((totalApr).toFixed(2)).toLocaleString("en-US")}</td>
            <td>{Number((workersSum).toFixed(2)).toLocaleString("en-US")}</td>
        </tfoot>
      </table>
    </section>
  );
};


export default WidgetPoolList;
