export const prbWorkerCmd = [
    {"name": "Restart Phala", "command": "restart phala","description":"Send Phala Stop and Phala Start to restart Phala Node"},
    {"name": "Stop Phala", "command": "stop phala","description":"Send Phala Stop"},
    {"name": "Start Phala", "command": "start phala","description":"Send Phala Start"},
    {"name": "Update Phala", "command": "update phala","description":"Stops the phala services and updates the docker containers"},
    {"name": "Phala Logs", "command": "phala logs","description":"captures the last 100 lines of the each service"},
    {"name": "Update Monitor", "command": "update monitor","description":"Pull the latest version of the monitor from github"},
    {"name": "Restart Prb", "command": "restartprbworker","description":"Resets the service on PRB"},
    

];

export const prbCmd = [
    {"name": "Restart Lifecycle", "command": "restart lifecycle","description":"Send Docker Stop and Docker Start to restart PRB lifecycle services"},
    {"name": "Stop Lifecycle", "command": "stop lifecycle","description":"Send Docker Lifecycle Stop"},
    {"name": "Start Lifecycle", "command": "start lifecycle","description":"Send Docker Lifecycle Start"},
    
    {"name": "Restart Data Provider", "command": "restart data provider","description":"Send Docker Stop and Docker Start to restart PRB Data Provider services"},
    {"name": "Stop Data Provider", "command": "stop data provider","description":"Send Docker Data Provider Stop"},
    {"name": "Start Data Provider", "command": "start data provider","description":"Send Docker Data Provider Start"},
    
    {"name": "Restart Node", "command": "restart node","description":"Send Docker Stop and Docker Start to restart PRB Node services"},
    {"name": "Stop Node", "command": "stop node","description":"Send Docker Node Stop"},
    {"name": "Start Node", "command": "start node","description":"Send Docker Node Start"},

    {"name": "Restart Prb", "command": "restart prb","description":"Send Docker Stop and Docker Start to restart PRB services"},
    {"name": "Stop Prb", "command": "stop prb","description":"Send Docker Node Stop"},
    {"name": "Start Prb", "command": "start prb","description":"Send Docker Node Start"},
    
    {"name": "Update Monitor", "command": "update monitor","description":"Pull the latest version of the monitor from github"},
    {"name": "Prb Logs", "command": "prb logs","description":"captures the last 100 lines of the each service"},
    

];