import React from "react";
import { createSlice } from '@reduxjs/toolkit';
import {useSelector} from 'react-redux'
import axios from 'axios';
import { useRoutes } from 'react-router-dom';





const initialState = {
  isModalOpen: false,
  headerText: "",
  modalComponentName: "",
  
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        doLogin: (state) => {
            state.headerText = "Login";
            state.modalComponentName = "Login"
            state.isModalOpen = true;
          },
          doBulkCommand: (state) => {
            state.headerText = "Commands";
            state.modalComponentName = "bulkCommand"
            state.isModalOpen = true;
          },
          doRegisterUser: (state) => {
            state.headerText = "Register User";
            state.modalComponentName = "registerUser"
            state.isModalOpen = true;
          },
          doChangePassword: (state) => {
            state.headerText = "Change Password";
            state.modalComponentName = "changePassword"
            state.isModalOpen = true;
          },
          doPhalaDetail: (state,action) => {
            state.headerText = "Server: " + action.payload.host_name;
            state.modalComponentName = "phalaDetail"
            state.isModalOpen = true;
          },
          doPhalaDetailWorker: (state,action) => {
            state.headerText = "Server: " + action.payload.host_name;
            state.modalComponentName = "phalaDetailWorker"
            state.isModalOpen = true;
          },
          doPhalaDetailPrb: (state,action) => {
            state.headerText = "Server: " + action.payload.host_name;
            state.modalComponentName = "phalaDetailPrb"
            state.isModalOpen = true;
          },
          doAddWorker: (state,action) => {
            state.headerText = "Add Worker";
            state.modalComponentName = "addWorker"
            state.isModalOpen = true;
          },
          doAddAccount: (state,action) => {
            state.headerText = "Add Account";
            state.modalComponentName = "addAccount"
            state.isModalOpen = true;
          },
          doAddOrgProfile: (state,action) => {
            state.headerText = "Add Org Profile";
            state.modalComponentName = "addOrgProfile"
            state.isModalOpen = true;
          },
          doAddOrgAccess: (state,action) => {
            state.headerText = "Add Org Access";
            state.modalComponentName = "addOrgAccess"
            state.isModalOpen = true;
          },
        closeModal: (state) => {
            state.headerText = "";
            state.modalComponentName = ""
            state.isModalOpen = false;

        },
      
    },
    
  });
  
  // console.log(cartSlice);
  export const {doBulkCommand,doPhalaDetailPrb,doPhalaDetailWorker,doLogin,closeModal,doRegisterUser,doChangePassword,doPhalaDetail,doAddWorker,doAddAccount,doAddOrgProfile,doAddOrgAccess} =
  modalSlice.actions;
  
  export default modalSlice.reducer;
