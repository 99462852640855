import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../../App.css";


import { addOrgAccess,getOrgProfile } from "../phalaSlice";
import {closeModal} from "../../modal/modalSlice"
import { Table } from "material-ui";




const AddAccess = () => {
  const {currentUser } = useSelector((store) => store.user);
  const {orgProfile } = useSelector((store) => store.phala);
  const dispatch = useDispatch();
  const [email,setEmail] = useState("");
  
  
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const ph = {
      org_id: orgProfile.org_id,
      email: email,
      privilege: "User",
      
     
    }
    console.log("Before dispatch");
    const data = {
        token: currentUser.token,
        phala: ph
    }
    dispatch(addOrgAccess(data));
    //dispatch(getOrgProfile(currentUser.token));
  

    console.log(data);
    dispatch(closeModal());
    
    
  };
  
  return (
    <div className="app-header">
        <table responsive>
            <thead>
                <th>User</th>
                <th>Privilege</th>
            </thead>
            <tbody>
            {orgProfile.access.map(ac => (
              <tr key={ac.userId}>
                <td> {ac.userName}</td>
                <td> {ac.privilege}</td>
                
              </tr>

            ))}
            </tbody>

        </table>
      <form onSubmit={handleFormSubmit}>
        <table responsive>
          <tbody>
            <tr>
              <td>Email</td>
              <td>
                <input
                  value={email}
                  id="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                />
              </td>
            </tr>
            
            
            
          </tbody>
        </table>

        <button>Submit</button>
      </form>
    </div>
  );
};

export default AddAccess;
