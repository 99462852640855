import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../../App.css";
import {Icons} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup,faWallet} from "@fortawesome/free-solid-svg-icons";
import { addAccount,getOrgProfile } from "../phalaSlice";
import {closeModal} from "../../modal/modalSlice"




const AddAccount = () => {
  const {currentUser } = useSelector((store) => store.user);
  const {orgProfile } = useSelector((store) => store.phala);
  const dispatch = useDispatch();
  const [accountName,setAccountName] = useState("");
  const [accountNumber,setAccountNumber] = useState("");
  
  
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const ph = {
      org_id: orgProfile.org_id,
      accountName: accountName,
      accountNumber: accountNumber,
      
     
    }
    console.log("Before dispatch");
    const data = {
        token: currentUser.token,
        phala: ph
    }
    dispatch(addAccount(data));
    dispatch(getOrgProfile(currentUser.token));
  

    console.log(data);
    dispatch(closeModal());
    
    
  };
  
  return (
    <div className="app-header">
    
      <form onSubmit={handleFormSubmit}>
      <div class="input-group mb-3">
      <span class="input-group-text">
      <FontAwesomeIcon icon={faWallet} size="lg"/>
          </span>
      <input
                  value={accountName}
                  id="accountName"
                  name="accountName"
                  onChange={(e) => setAccountName(e.target.value)}
                  type="text"
                  placeholder="Wallet Name"
                  class="form-control"
                />
        </div>
        <div class="input-group mb-3 input-group-secondary">
          <span class="input-group-text">
          <FontAwesomeIcon icon={faLayerGroup} size="lg"/>
          </span>
          <input
                  value={accountNumber}
                  id="accountNumber"
                  name="accountNumber"
                  onChange={(e) => setAccountNumber(e.target.value)}
                  type="text"
                  placeholder="Pool Address"
                  class="form-control"
                />
        </div>
        <div class="d-flex justify-content-center">
        <button  class="btn btn-outline-success border-0">
          Submit
        </button>
        </div>
      </form>
    </div>
  );
};

export default AddAccount;
