import React from "react";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useNavigate } from "react-router-dom";
import axios from 'axios';




const baseUrl = process.env.REACT_APP_USERSERVICE + '/user';

const initialState = {
  users: [],
  userLogin: {
    email:"",
    password:"",
  },
  privileges:[],
  currentUser:{
    user_id:"",
    token:"",
    session_id: "",
    org_id: "",
    first_name: "",
    last_name: "",
    phone: "",
    privileges:[],
    
    isLoggedIn:false,
  },
  selUser:{
    user_id: "",
    first_name: "",
    last_name: "",
    phone: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip:"",
    user_status:"",
    privileges:[],
  },
  userPrivileges:[],
  

  isLoading: true,
};
export const doUserLogin = createAsyncThunk(
  'user/doUserLogin',
  async (logindata, thunkAPI) => {
    console.log("before user selector")
    //const { userLogin } = useSelector((store) => store.user);
    console.log(logindata);
    try {
      console.log("before baseurl")
      const url = baseUrl + "/doUserLogin";
      console.log(url);
        
       //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url,logindata);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (userData, thunkAPI) => {
    console.log("updateUser before user selector")
    //const { userLogin } = useSelector((store) => store.user);
    const usr = userData.user;
    const token = userData.token;
    console.log(userData);
    try {
      console.log("before baseurl")
      const url = baseUrl + "/updateUser";
        
       //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url,usr,{'headers':{'authorization':token}});

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const registerUser = createAsyncThunk(
  'user/registerUser',
  async (userData, thunkAPI) => {
    console.log("registerUser before user selector")
    
    console.log(userData);
    try {
      console.log("before baseurl")
      const url = baseUrl + "/registerUser";
        
       //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url,userData);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (userData, thunkAPI) => {
    console.log("changePassword before user selector")
    const token = userData.token;
    const user = userData.user;

    console.log(userData);
    try {
      console.log("before baseurl")
      const url = baseUrl + "/changePassword";
        
       //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url,user,{'headers':{'authorization':token}});

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const getUserPrivileges = createAsyncThunk(
  'user/getUserPrivileges',
  async (userData, thunkAPI) => {
    //console.log("getUserPrivileges before user selector")
    const token = userData.token;
    const user = userData.user;

   // console.log(userData);
    try {
      //console.log("before baseurl")
      const url = baseUrl + "/getUserPrivileges";
        
       //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url,user,{'headers':{'authorization':token}});

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
export const getAllUsers = createAsyncThunk(
    'user/getAllUsers',
    async (tk, thunkAPI) => {
      if (tk === ""){
        console.log('Not Logged In')
        return thunkAPI.rejectWithValue('Not Logged In');
      }
      try {
        const url = baseUrl + "/getAllUsers";
         //console.log(tk);
         console.log(thunkAPI);
         console.log(thunkAPI.getState());
         //thunkAPI.dispatch(openModal());
        const resp = await axios.get(url,{'headers':{'authorization':tk}});
  
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
      }
    }
  );

  export const getAllPrivileges = createAsyncThunk(
    'user/getAllPrivileges',
    async (tk, thunkAPI) => {
      if (tk === ""){
        console.log('Not Logged In')
        return thunkAPI.rejectWithValue('Not Logged In');
      }
      try {
        const url = baseUrl + "/getAllPrivileges";
         //console.log(tk);
         //console.log(thunkAPI);
         //console.log(thunkAPI.getState());
         //thunkAPI.dispatch(openModal());
        const resp = await axios.get(url,{'headers':{'authorization':tk}});
  
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
      }
    }
  );
  export const sendUserPrivileges = createAsyncThunk(
    'user/sendUserPrivileges',
    async (data, thunkAPI) => {
      console.log("getUserPrivileges before user selector")
      const token = data.token;
      
  
      console.log(data);
      try {
        console.log("before baseurl")
        const url = baseUrl + "/setUserPrivileges";
          
         //thunkAPI.dispatch(openModal());
        const resp = await axios.post(url,data.privilegeData,{'headers':{'authorization':token}});
  
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
      }
    }
  );

  const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      updateUserLogin: (state,action) => {
        state.userLogin = action.payload;
      },
      updateSelUser: (state,action) => {
        state.selUser = action.payload;
      },
      setCurrentUser: (state,action) => {
        state.currentUser = action.payload;
      },
      userLogout: (state) => {
        state.currentUser.token = "";
        state.currentUser.user_id = "";
        state.currentUser.session_id = "";
        state.currentUser.isLoggedIn = false;
      },
      setUserPrivileges: (state,action) => {
        state.userPrivileges = action.payload;
      },
      updateUserPrivileges: (state,action) => {
        //state.userPrivileges = action.payload;

        let tpriv = state.userPrivileges.map(p => p.privilege === action.payload.privilege ? Object.assign({}, p, {isSelected: !p.isSelected}) : p);

        state.userPrivileges = tpriv;
        
      },
      clearUserPrivileges: (state) => {
        state.userPrivileges = [];
      },
      
    },
    extraReducers: {
      [sendUserPrivileges.pending]: (state) => {
        state.isLoading = true;
        
        
      },
      [sendUserPrivileges.fulfilled]: (state, action) => {
        // console.log(action);
        state.isLoading = false;
      
       
      },
      [sendUserPrivileges.rejected]: (state, action) => {
        
        state.isLoading = false;
      },


      [getUserPrivileges.pending]: (state) => {
        state.isLoading = true;
        
        
      },
      [getUserPrivileges.fulfilled]: (state, action) => {
        // console.log(action);
        state.isLoading = false;
        state.selPrivileges = action.payload;
       
      },
      [getUserPrivileges.rejected]: (state, action) => {
        //console.log(action);
        state.isLoading = false;
      },



      [getAllPrivileges.pending]: (state) => {
        state.isLoading = true;
        
        
      },
      [getAllPrivileges.fulfilled]: (state, action) => {
        // console.log(action);
        state.isLoading = false;
        state.privileges = action.payload;
        
      },
      [getAllPrivileges.rejected]: (state, action) => {
        //console.log(action);
        state.isLoading = false;
      },

      [registerUser.pending]: (state) => {
        state.isLoading = true;
        
        
      },
      [registerUser.fulfilled]: (state, action) => {
        // console.log(action);
        state.isLoading = false;
        
      },
      [registerUser.rejected]: (state, action) => {
        //console.log(action);
        state.isLoading = false;
      },

      [changePassword.pending]: (state) => {
        state.isLoading = true;
        
        
      },
      [changePassword.fulfilled]: (state, action) => {
        // console.log(action);
        state.isLoading = false;
        
      },
      [changePassword.rejected]: (state, action) => {
        console.log(action);
        state.isLoading = false;
      },




      [getAllUsers.pending]: (state) => {
        state.isLoading = true;
        state.users = [];
        
      },
      [getAllUsers.fulfilled]: (state, action) => {
        // console.log(action);
        state.isLoading = false;
        state.users = action.payload;

        
      },
      [getAllUsers.rejected]: (state, action) => {
        console.log(action);
        state.isLoading = false;
      },





      [doUserLogin.pending]: (state) => {
        //console.log("Pending")
        state.isLoading = true;
        state.currentUser.token = "";
        state.currentUser.user_id = "";
        state.currentUser.session_id = "";
        state.currentUser.privileges = [];
        state.currentUser.isLoggedIn = false;
        
        
        

        
      },
      [doUserLogin.fulfilled]: (state, action) => {
       console.log(action.payload);
        state.isLoading = false;
        
        if (action.payload.resultMessage === "Authenticated"){
          console.log(action.payload);
          state.currentUser.user_id = action.payload.user_id;
          state.currentUser.token = action.payload.token;
          state.currentUser.session_id = action.payload.session_id;
          state.currentUser.org_id = action.payload.org_id;

          state.currentUser.first_name = action.payload.first_name;
          state.currentUser.last_name = action.payload.last_name;
          state.currentUser.phone = action.payload.phone;
          state.currentUser.privileges = action.payload.privileges;
          
          
          state.currentUser.isLoggedIn = true;
        }
        
      },
      [doUserLogin.rejected]: (state, action) => {
        console.log(action);
        state.isLoading = false;
      },
    },
  });
  
  // console.log(cartSlice);
  export const {setCurrentUser,updateUserLogin,userLogout,updateSelUser,setUserPrivileges,clearUserPrivileges,updateUserPrivileges} =
  userSlice.actions;
  
  export default userSlice.reducer;