import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Container, Nav, Image, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { doLogin, doRegisterUser } from './features/modal/modalSlice';
import { getOrgProfiles, getOrgProfile } from './features/phala/phalaSlice';
import { setCurrentUser } from './features/user/userSlice';

import { HasPrivilege } from './features/user/util';
import OrgDropdown from './HeaderOrgProfileDropdown';

import LogoImage from './Logo_Head.svg';
import './index.css';
export default function Header() {
  const { currentUser } = useSelector(store => store.user);
  const { showOrgs, orgProfiles, orgProfile } = useSelector(
    store => store.phala
  );
  const dispatch = useDispatch();
  const displayUser = HasPrivilege('viewuser');
  const displayPhala = HasPrivilege('phala');
  const displayAccountHome = HasPrivilege('accounthome');
  const [isScrolled, setIsScrolled] = useState(false);
  

  useEffect(() => {
    if (currentUser.isLoggedIn === true) {
      dispatch(getOrgProfiles(currentUser.token));
    }
    storeCurrentUser(currentUser);
  }, [currentUser.isLoggedIn]);

  useEffect(() => {
    if (orgProfile.org_id === '' && orgProfiles.length > 0) {
      let data = {
        profile: { org_id: orgProfiles[0].org_id },
        token: currentUser.token
      };
      console.log('getOrgProfile: ' + JSON.stringify(data));
     
        dispatch(getOrgProfile(data));
    }
  }, [orgProfiles]);
  const storeCurrentUser = (user) => {
    try {
      localStorage.setItem('currentUser', JSON.stringify(user));
      console.log('currentUser stored in local storage');
    } catch (error) {
      // Handle possible errors here
      console.error('Error storing currentUser in local storage:', error);
    }
  };
  const retrieveCurrentUser = () => {
    try {
      const user = localStorage.getItem('currentUser');
      return user ? JSON.parse(user) : null;
    } catch (error) {
      // Handle possible errors here
      console.error('Error retrieving currentUser from local storage:', error);
      return null;
    }
  };

  const displayHeader = (currentUser, displayUser) => {
    return (
      <Navbar
        expand="lg"
        className={`header navbar-dark`}
      >
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <Image r src={LogoImage} alt="logo" width="40" height="34" />{' '}
              Cyber Jungle
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {currentUser.isLoggedIn ? (
              <Nav className="me-auto">
                <LinkContainer to="/">
                  <Nav.Link>Home</Nav.Link>
                </LinkContainer>
                {displayAccountHome ? (
                  <LinkContainer to="/accounts">
                    <Nav.Link>Accounts </Nav.Link>
                  </LinkContainer>
                ) : (
                  ''
                )}
                {displayPhala ? (
                  <LinkContainer to="/phala">
                    <Nav.Link>Workers </Nav.Link>
                  </LinkContainer>
                ) : (
                  ''
                )}

                {displayUser ? (
                  <LinkContainer to="/users">
                    <Nav.Link>Users</Nav.Link>
                  </LinkContainer>
                ) : (
                  ''
                )}
              </Nav>
            ) : (
              <Nav className="me-auto"></Nav>
            )}
            <Nav pullRight>
              {currentUser.isLoggedIn === false ? (
                <Nav className="me-auto">
                  <Nav.Link
                    type="button"
                    className=""
                    onClick={() => {
                      dispatch(doLogin());
                    }}
                  >
                    Login
                  </Nav.Link>

                  <Nav.Link
                    onClick={() => {
                      dispatch(doRegisterUser());
                    }}
                  >
                    Register
                  </Nav.Link>
                </Nav>
              ) : (
                <LinkContainer to="/logout">
                  <Nav.Link>Logout</Nav.Link>
                </LinkContainer>
              )}
              {showOrgs === true ? <OrgDropdown /> : ''}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  };

  return <header>{displayHeader(currentUser, displayUser)}</header>;
}
