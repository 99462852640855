import React, {useEffect} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getHalving } from '../phalaSlice';
import Tooltip from '@mui/material/Tooltip';

import "../../../App.css";

const HalvingEstimate = () => {
    const { halving} = useSelector(
        store => store.phala
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getHalving())
    }, []);


    const secPerDay = 86400;
    const avgBlockTime = Number((halving.averageBlockTime).toFixed(2));
    const halvingProgress =( ( halving.nextBlock - halving.lastHalfBlock ) / (halving.nextHalvingBlock - halving.lastHalfBlock) * 100).toFixed(2);
    //const altCheck = (100 - (halving.nextHalvingBlock - halving.nextBlock) / halving.halvingInterval * 100).toFixed(2);

    return (
        <section>
            <Tooltip title="Calculated using average block time and updated every 6 hours." placement="top">
                <h1 className='text-center mb-3'>
                    Estimated Halving Progress
                    <svg xmlns="http://www.w3.org/2000/svg" width="10"fill="currentColor" class="bi bi-info-circle mt-3 ms-1" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                </h1>
            </Tooltip>
            
            <div class="progress">
                <div class="progress-bar progress-bar-striped" role="progressbar" style={{width: halvingProgress + '%'}} aria-valuenow={halvingProgress} aria-valuemin="0" aria-valuemax="100">
                    {halvingProgress + '%'}
                </div>
            </div>
            <div >
                <h5 style={{float: 'left'}}>{"Last Halving Block: " + halving.lastHalfBlock}  </h5>
                <h5 style={{float: 'right'}}>{"Next Halving Block: " + halving.nextHalvingBlock}</h5>
            </div>

            <br/>

            <div className='row d-flex justify-content-center border border-2 rounded border-secondary pt-4 pb-4 ms-1 mt-4' >
                <div className='col text-center'>
                    <h5 class='header-subtle'>60 Day Average Block Time</h5>
                    <h3>{avgBlockTime.toLocaleString("en-US")}<sub class="ps-1 text-secondary"><small>Sec</small></sub></h3>
                </div>
                <div className='col text-center'>
                    <h5 class='header-subtle'>Current Block</h5>
                    <h3>{halving.nextBlock} </h3>
                </div>
                <div className='col text-center'>
                    <h5 class='header-subtle'>Est Halving Date and Time</h5>
                    <h3>{new Date(halving.halvingDate).toLocaleDateString('en-US', {month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'})} </h3>
                </div>
                <div className='col text-center'>
                    <h5 class='header-subtle'>Number of blocks between halvings</h5>
                    <h3>{halving.halvingInterval} </h3>
                </div>
                <div className='col text-center'>
                    <h5 class='header-subtle'>Number of blocks to next halving</h5>
                    <h3>{halving.nextHalvingBlock - halving.nextBlock} </h3>
                </div>
                <div className='col text-center'>
                    <h5 class='header-subtle'>Est number of days between halvings</h5>
                    <h3>{ (avgBlockTime * halving.halvingInterval) / secPerDay} </h3>
                </div>
                <div className='col text-center'>
                    <h5 class='header-subtle'>Est number of days to next halving</h5>
                    <h3>{ ( ( halving.halvingDate -  Math.floor(new Date().getTime()) )/(secPerDay * 1000) ).toFixed(0) } </h3>
                </div>
            </div>
        </section>
    );
}

export default HalvingEstimate;