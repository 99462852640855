import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../App.css";

import { Modal } from "react-bootstrap";

import Login from "../user/Login";
import RegisterUser from "../user/registerUser";
import ChangePassword from "../user/password";
import BulkCommand from "../phala/bulkControlWorker";
import PhalaControlWorker from "../phala/phalaControlWorker";
import PhalaControlPrb from "../phala/phalaControlPrb";
import AddWorker from "../phala/addWorker";
import AddAccount from "../phala/org/addAccount";
import AddOrgProfile from "../phala/org/addOrgProfile";
import AddOrgAccess from "../phala/org/addAccess";
import { closeModal } from "./modalSlice";
import AddAccess from "../phala/org/addAccess";

const ModalForm = () => {
  const { isModalOpen, headerText, modalComponentName } = useSelector(
    (store) => store.modal
  );
  const dispatch = useDispatch();
  //let navigate = useNavigate();
  const handleClose = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    //if (currentUser.isLoggedIn){
    //  navigate("/")
    // }
  });
  return (
    <Modal show={isModalOpen} onHide={handleClose}>
      <Modal.Header closeButton  className='text-white'>
      <Modal.Title className="text-center">{headerText}</Modal.Title>

      </Modal.Header>
      {modalComponentName === "Login" ? (
        <Modal.Body>
          <Login />
        </Modal.Body>
      ) : (
        ""
      )}

      {modalComponentName === "registerUser" ? (
        <Modal.Body className="text-center">
          <RegisterUser />
        </Modal.Body>
      ) : (
        ""
      )}

      {modalComponentName === "changePassword" ? (
        <Modal.Body>
          <ChangePassword />
        </Modal.Body>
      ) : (
        ""
      )}
      
      {modalComponentName === "phalaDetailWorker" ? (
        <Modal.Body className='bg-dark text-white'>
          <PhalaControlWorker />
        </Modal.Body>
      ) : (
        ""
      )}
      {modalComponentName === "phalaDetailPrb" ? (
        <Modal.Body className='bg-dark text-white'>
          <PhalaControlPrb />
        </Modal.Body>
      ) : (
        ""
      )}
      {modalComponentName === "addWorker" ? (
        <Modal.Body className='bg-dark text-white'>
          <AddWorker />
        </Modal.Body>
      ) : (
        ""
      )}
      {modalComponentName === "bulkCommand" ? (
        <Modal.Body className='bg-dark text-white'>
          <BulkCommand />
        </Modal.Body>
      ) : (
        ""
      )}
      {modalComponentName === "addAccount" ? (
        <Modal.Body className='bg-dark text-white'>
          <AddAccount />
        </Modal.Body>
      ) : (
        ""
      )}
      {modalComponentName === "addOrgAccess" ? (
        <Modal.Body className='bg-dark text-white'>
          <AddAccess />
        </Modal.Body>
      ) : (
        ""
      )}


{modalComponentName === "addOrgProfile" ? (
        <Modal.Body className='bg-dark text-white'>
          <AddOrgProfile />
        </Modal.Body>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default ModalForm;
