// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SplashPage.css */
body {
    background-color: #121212;
    font-family: 'IBM Plex Mono', monospace;
    color: #E0E0E0;
    margin: 0; /* Ensure no extra margin */
    padding: 0; /* Ensure no extra padding */
    height: 100%; /* Ensure full height */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .splash-page {
    background-color: #121212;
    height: 100%;
  }
  
  .custom-carousel .carousel-item {
    background-color: #3D3D3D;
    border-radius: 15px;
    overflow: hidden;
  }
  
  .custom-carousel .carousel-caption {
    background-color: transparent; /* Remove the mask */
    border-radius: 10px;
    padding: 10px;
    color: #E0E0E0; /* Ensure the text is still visible */
  }
  
  .custom-carousel .carousel-indicators li {
    background-color: #33B5B4;
  }
  
  .custom-carousel .carousel-control-prev,
  .custom-carousel .carousel-control-next {
    display: none; /* Make the controls invisible */
  }
  
  img.rounded {
    border-radius: 15px;
    display: block;
    margin: 0 auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/features/home/SplashPage.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,yBAAyB;IACzB,uCAAuC;IACvC,cAAc;IACd,SAAS,EAAE,2BAA2B;IACtC,UAAU,EAAE,4BAA4B;IACxC,YAAY,EAAE,uBAAuB;IACrC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,6BAA6B,EAAE,oBAAoB;IACnD,mBAAmB;IACnB,aAAa;IACb,cAAc,EAAE,qCAAqC;EACvD;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;;IAEE,aAAa,EAAE,gCAAgC;EACjD;;EAEA;IACE,mBAAmB;IACnB,cAAc;IACd,cAAc;EAChB","sourcesContent":["/* SplashPage.css */\nbody {\n    background-color: #121212;\n    font-family: 'IBM Plex Mono', monospace;\n    color: #E0E0E0;\n    margin: 0; /* Ensure no extra margin */\n    padding: 0; /* Ensure no extra padding */\n    height: 100%; /* Ensure full height */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .splash-page {\n    background-color: #121212;\n    height: 100%;\n  }\n  \n  .custom-carousel .carousel-item {\n    background-color: #3D3D3D;\n    border-radius: 15px;\n    overflow: hidden;\n  }\n  \n  .custom-carousel .carousel-caption {\n    background-color: transparent; /* Remove the mask */\n    border-radius: 10px;\n    padding: 10px;\n    color: #E0E0E0; /* Ensure the text is still visible */\n  }\n  \n  .custom-carousel .carousel-indicators li {\n    background-color: #33B5B4;\n  }\n  \n  .custom-carousel .carousel-control-prev,\n  .custom-carousel .carousel-control-next {\n    display: none; /* Make the controls invisible */\n  }\n  \n  img.rounded {\n    border-radius: 15px;\n    display: block;\n    margin: 0 auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
