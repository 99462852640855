import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Card, Container, Col, Row } from 'react-bootstrap';

import PhalaAccountSummary from './accountdetail/accountSummary';
import PhalaTransferToList from './accountdetail/transferToList';
import PhalaTransferFromList from './accountdetail/transferFromList';
import PhalaDelegationList from './accountdetail/delegationList';
import PhalaPoolList from './accountdetail/poolList';
import Header from '../../../Header';
import '../../../App.css';
import { getAccountDetails } from '../phalaSlice';
const AccountDetail = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(store => store.user);
  const { selAccount } = useSelector(store => store.phala);
  useEffect(() => {
    dispatch(getAccountDetails(selAccount.account));
  }, []);
  return (
    
<section> 
  <Header/>
  <div className='container-fluid'>
   <div className='row'> 
    <div className='col'>
      <PhalaAccountSummary />
    </div>
    
    <div className='col'>
      <PhalaPoolList />
    </div>
    <div className='col'>
      <PhalaDelegationList />
    </div>
    <div className='container-fluid row g-3'>
    <div className='col'>
      
    </div>
    <div className='col'>
     
    </div>
    </div>
   </div>
  </div>
</section>

  );
};

export default AccountDetail;
