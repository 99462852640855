import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import Header from "../../Header";
import "../../App.css";
import { getAllWater } from "./waterSlice";
import { StyledEngineProvider } from "@mui/material";
//import { doPhalaDetail } from "../modal/modalSlice";
import { MDBTooltip } from 'mdb-react-ui-kit';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faGear } from '@fortawesome/free-solid-svg-icons';
//import YellowCheck from '/images/check-yellow.svg';



const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
function truncString(str){
  
  return String(str).substring(0,4) + " ... " + String(str).slice(-5);
}
function diff_dates_sec(dt1, dt2) {
  var mdt = new Date(dt2);
  var mdt1 = new Date(dt1);
  var diff = mdt - mdt1; // / 1000;
  diff /= 3600;
  return Math.abs(Math.round(diff));
}
function diff_seconds(dt1) {
  var now = new Date();
  var dt = new Date(dt1);
  var diff = now - dt; // / 1000;
  diff /= 3600;
  return Math.abs(Math.round(diff));
}

const WaterList = (sz) => {
    const targetRef = useRef();
  
    const dispatch = useDispatch();
    const { water, isLoading, hasLoaded } = useSelector((store) => store.water);
    const { currentUser } = useSelector((store) => store.user);
    const updateWater = () => {
      if (isLoading === false) {
        console.log("jsx Current token: " + currentUser.token);
        dispatch(getAllWater(currentUser));
      }
    };
    const columns = [
    {
      dataField: 'Device_Name',
      text: 'Name'
    },
    
    
    {
      dataField: 'Water_Height',
      text: 'Water Height'
    },
    {
      dataField: 'percentage',
      isDummyField: true,
      text: 'Percentage',
      formatter: (cell, wl) => {
      
        return(<div>{(wl.Water_Height / wl.Container_Height)*100}%</div>)}
    },
    
    {
      dataField: 'Last_Reading_formatted',
      isDummyField: true,
      text: 'Updated',
      formatter: (cell, wl) => {
        const options = {
          weekday: "long",
          year: "numeric",
          month:"long",
          day:"numeric"
     };
        return(<div>{new Date(wl.Last_Reading).toLocaleDateString("en-US",options)} {new Date(wl.Last_Reading).toLocaleTimeString("en-US")}</div>)}
    },
    
  ];
  
  const expandRow = {
    renderer: row => (
      <div>
        <p>Device Name: {row.Device_Name}</p>
        <p>Device Id: {row.Device_Id}</p>
        <p>Container Height: {row.Contaier_Height}</p>
        <p>Sensor Reading: {row.Sensor_Reading}</p>
        <p>Offset: {row.Sensor_Offset}</p>
        <p>Conversion factor: {row.Conversion_Factor_Inch}</p>
        <p>Battery Volt: {row.Battery_Volt}</p>
        <p>Solar Volt: {row.Solar_Volt}</p>
        <p>Helium Node: {row.Helium_Device_Name}</p>
        <p>Container Type: {row.Container_Type}</p>  
        <p>Description: {row.Description}</p>  
        <p>Status: {row.Status}</p>
      </div>
    )
  };
    useEffect(() => {
      //console.log("useEffect");
      console.log(isLoading);
  
      //let currentTime = new Date().getTime();
      //let timeDiff = currentTime - lastTime;
  
      if (hasLoaded === false) dispatch(getAllWater(currentUser));
  
      if (isLoading === false)
        sleep(10000).then(() => {
          dispatch(getAllWater(currentUser));
        });
  
      //dispatch(getAllHelium('random'));
      //dispatch(refreshHelium());
  
      //    setInterval(updatePhala(), 30000);
    });
  
    
    return (
      <div className="app-header">
        <Header />
        <BootstrapTable keyField='water_level_id' data={ water } columns={ columns } expandRow={ expandRow } striped
    hover  bordered={ false } bodyStyle={ { wordBreak: 'break-all' }}/>
        
      </div>
    );
  };
  
  export default WaterList;
  