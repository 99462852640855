import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card, Table, Icons } from 'react-bootstrap';
import {
  updateSelAccount,
  getAccountDetails,
  setShowOrgs
} from '../phalaSlice';
import { doAddAccount, doAddOrgAccess } from '../../modal/modalSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faUsers,
  faFileCirclePlus,
  faUserPlus,
  faWallet
} from '@fortawesome/free-solid-svg-icons';
import AccountListWidgets from '../widgets/accountListWidgets';

const PhalaWalletsTable = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { orgProfile, orgProfiles } = useSelector(store => store.phala);

  const handleRowClick = acct => {
    dispatch(updateSelAccount(acct));
    dispatch(getAccountDetails(acct.account));
    navigate('/phala/accountDetail');
  };

  const addAccountClick = () => {
    dispatch(doAddAccount());
  };

  const addAccessClick = () => {
    dispatch(doAddOrgAccess());
  };

  useEffect(() => {
    //dispatch(refreshOrgProfile())
  }, [orgProfile]);

  useEffect(() => {
    // This code will run only when the component is mounted
    dispatch(setShowOrgs(true));

    return () => {
      // This code will run only when the component is unmounted
      dispatch(setShowOrgs(false));
    };
  }, []);

  const totalAvailable = orgProfile.accounts.reduce(
    (acc, cur) => acc + cur.free,
    0
  );
  const totalDelegated = orgProfile.accounts.reduce(
    (acc, cur) => acc + cur.delegated,
    0
  );
  const accountTotal = orgProfile.accounts.reduce(
    (acc, cur) => acc + cur.delegated + cur.free,
    0
  );

  return (
    <section className="standard-table text-light pt-2">
      <section class="row">
        <table className="table table-hover table-striped col me-3 ms-3 container">
          <thead>
            <tr>
              <th scope="col">Account</th>
              <th scope="col">Delegated</th>
              <th scope="col">Free</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {orgProfile.accounts.map(op => (
              <tr key={op.account} onClick={() => handleRowClick(op)}>
                <th>{op.name}</th>
                <td>
                  {Number(op.delegated.toFixed(2)).toLocaleString('en-US')}
                </td>
                <td>{Number(op.free.toFixed(2)).toLocaleString('en-US')}</td>
                <td>
                  {Number((op.free + op.delegated).toFixed(2)).toLocaleString(
                    'en-US'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <th></th>
            <td>
              {Number(totalDelegated.toFixed(2)).toLocaleString('en-US')}{' '}
            </td>
            <td>{Number(totalAvailable.toFixed(2)).toLocaleString('en-US')}</td>
            <td>{Number(accountTotal.toFixed(2)).toLocaleString('en-US')}</td>
          </tfoot>
        </table>
      </section>
    </section>
  );
};

export default PhalaWalletsTable;
