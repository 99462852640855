import React, { useEffect, useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBlockTime,
  setNetworkChartTitle,
  getResponsive
} from '../phalaSlice';
import '../../../App.css';

const BlockTimeByHour = () => {
  const dispatch = useDispatch();
  const [categoryArray, setCategory] = useState([]);
  const [avgBlockTimeArray, setAvgBlockTime] = useState([0]);
  const [minBlockTimeArray, setMinBlockTime] = useState([0]);
  const [maxBlockTimeArray, setMaxBlockTime] = useState([0]);
  const [totalRewardsArray, setTotalRewards] = useState([0]);

  const [chartInterval, setChartInterval] = useState('24h');

  const { blockTimeByHour, blockTimeIsLoading, networkChartTitle } =
    useSelector(store => store.phala);
  const avgBlockTimeWidget =
    blockTimeByHour.reduce((acc, cur) => acc + cur.avgBlockTime, 0) /
    avgBlockTimeArray.length;
  const totalRewardsWidget = blockTimeByHour.reduce(
    (acc, cur) => acc + cur.totalRewards,
    0
  );
  const maxBlockTimeWidget = blockTimeByHour.reduce((acc, cur) => {
    return acc < cur.maxBlockTime ? cur.maxBlockTime : acc;
  }, 0);
  //const minBlockTimeWidget = Math.min(minBlockTimeArray);
  const minBlockTimeWidget = blockTimeByHour.reduce((acc, cur) => {
    return acc < cur.minBlockTime ? acc : cur.minBlockTime;
  }, 10000);

  //let miningArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,24,24];

  const eChartsOption = {
    tooltip: {
      trigger: 'axis',
      position: 'bottom', // set the tooltip position to top
      offset: [0, 10], // set the vertical offset to 10 pixels
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: '#c8e2f7',
      textStyle: {
        color: '#000'
      }
    },
    toolbox: {
      feature: {},
      iconStyle: {
        borderColor: 'white'
      }
    },
    textStyle: {
      color: '#fff'
    },

    legend: {
      data: ['Average', 'Low', 'High', 'Total Rewards'],
      top: '25',
      inactiveColor: '#555555',
      top: 'auto',
      selected: {
        Average: true,
        Low: false,
        High: false,
        'Total Rewards': true
      },
      textStyle: {
        color: 'white'
      }
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      show: true,
      nameLocation: 'middle',
      splitLine: {
        // set the splitLine attribute for the x-axis
        color: 'transparent' // set line color to transparent
      },
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },
    grid: [
      {
        left: '10%',
        right: '5%',
        height: '70%'
      }
    ],
    yAxis: [
      {
        name: 'Seconds',
        nameTextStyle: {
          opacity: 0.5 // set the opacity of the y-axis name to 50%
        },
        position: 'left',
        type: 'value',
        show: true,
        splitLine: {
          lineStyle: {
            opacity: 0.08 // set the opacity of the line to 50%
          }
        },
        axisLabel: {
          opacity: 0.5 // set the opacity of the axis labels to 50%
        }
      },
      {
        name: 'Rewards',
        position: 'right',
        type: 'value',
        show: false
      }
    ],
    series: [
      {
        name: 'Average',
        data: avgBlockTimeArray,
        type: 'line',
        smooth: false,
        showSymbol: false,
        color: '#0ACC77'
      },
      {
        name: 'Low',
        data: minBlockTimeArray,
        type: 'line',
        smooth: true,
        showSymbol: false,
        color: '#0B89D6'
      },
      {
        name: 'High',
        data: maxBlockTimeArray,
        type: 'line',
        stack: 'Total',
        smooth: true,
        showSymbol: false,
        color: '#BF230A'
      },
      {
        name: 'Total Rewards',
        yAxisIndex: 1,
        grid: 1,
        data: totalRewardsArray,
        color: 'gray',

        type: 'bar',
        smooth: true,
        showSymbol: false,

        itemStyle: {
          opacity: 0.2
        }
      }
    ]
  };
  useEffect(() => {
    console.log('data Changed');
    let tavg = [];
    let tmin = [];
    let tmax = [];
    let treward = [];

    let tcat = [];

    for (let m of blockTimeByHour) {
      //tcat.push(String(m._id))
      tavg.push(Number(m.avgBlockTime).toFixed(2));
      tmin.push(m.minBlockTime);
      tmax.push(m.maxBlockTime);
      treward.push(Number(m.totalRewards).toFixed(2));
      let dt = new Date(Math.floor(m.minTime));
      console.log('Chart Interval: ' + chartInterval);
      //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
      if (chartInterval === 'hour') {
        const month = String(dt.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        const day = String(dt.getDate()).padStart(2, '0');
        const year = dt.getFullYear();

        let hours = dt.getHours();
        const minutes = String(dt.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = String(hours).padStart(2, '0');

        tcat.push(`${month}/${day}/${year}\n${hours}:${minutes} ${ampm}`);
      } else {
        tcat.push(
          dt.toLocaleString('en-us', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      }
    }

    setCategory(tcat);

    setAvgBlockTime(tavg);
    setMinBlockTime(tmin);
    setMaxBlockTime(tmax);
    setTotalRewards(treward);
  }, [blockTimeByHour]);

  useEffect(() => {
    let data = {};
    let end = Math.floor(new Date().getTime());
    if (networkChartTitle === '24h') {
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('24h');
    }
    if (networkChartTitle === '7d') {
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('7d');
    }
    if (networkChartTitle === '30d') {
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('30d');
    }
    if (networkChartTitle === '90d') {
      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('90d');
    }
    dispatch(getBlockTime(data));
  }, []);

  const handleSelect = e => {
    console.log(e);
    setChartInterval(e);
    let data = {};
    let end = Math.floor(new Date().getTime());
    if (e === '24h') {
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('24h');
    }
    if (e === '7d') {
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('7d');
    }
    if (e === '30d') {
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('30d');
    }
    if (e === '90d') {
      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('90d');
    }
    dispatch(getBlockTime(data));

    dispatch(setNetworkChartTitle(e));
  };

  return (
    <section className="leftCard">
      <div className="row">
        <h3 className="text-start col-10 ps-4"> Block Time </h3>

        <div className="col-2 d-flex justify-content-end">
          <ButtonGroup
            className="timeframe-btn-group active btn btn-group-sm-mobile"
            aria-label="Timeframe Selection"
          >
            <Button
              variant="outline-complementary "
              onClick={() => handleSelect('24h')}
              className={chartInterval === '24h' ? 'active' : ''}
            >
              24h
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('7d')}
              className={chartInterval === '7d' ? 'active' : ''}
            >
              7d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('30d')}
              className={chartInterval === '30d' ? 'active' : ''}
            >
              30d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('90d')}
              className={chartInterval === '90d' ? 'active' : ''}
            >
              90d
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div>
        {blockTimeIsLoading === true ? (
          <div class="position-absolute bottom-50 end-50">
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        ) : (
          ''
        )}
        <div>
          <ReactEChart option={eChartsOption} />
        </div>
        <div className="d-flex justify-content-center">
          <div className="row d-flex align-content-center  pt-4 pb-4 mt-4 leftCard-data-container">
            <div className="text-center ms-2 col">
              <h6 class="header-subtle">Average</h6>
              <h4>
                {Number(avgBlockTimeWidget.toFixed(2)).toLocaleString('en-US')}
                <sub class="ps-1 text-secondary">
                  <small>Sec</small>
                </sub>
              </h4>
            </div>
            <div className="col text-center">
              <h6 class="header-subtle">High</h6>
              <h4>
                {maxBlockTimeWidget}
                <sub class="ps-1 text-secondary">
                  <small>Sec</small>
                </sub>
              </h4>
            </div>
            <div className="col text-center">
              <h6 class="header-subtle">Low</h6>
              <h4>
                {minBlockTimeWidget}
                <sub class="ps-1 text-secondary">
                  <small>Sec</small>
                </sub>
              </h4>
            </div>
            <div className="col text-center pe-4 ">
              <Tooltip
                title="Cumulative rewards for selected range."
                placement="top"
                className="header-subtle"
              >
                <h6>
                  Rewards
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    fill="currentColor"
                    class="bi bi-info-circle mt-1 ms-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                  </svg>
                </h6>
              </Tooltip>
              <h4>
                {Number(totalRewardsWidget.toFixed(0)).toLocaleString('en-US')}
                <sub class="ps-1 text-secondary">
                  <small>PHA</small>
                </sub>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlockTimeByHour;
