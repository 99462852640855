import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SplashPage.css'; // Import custom CSS file

import Dashboard_editor from './Dashboard_editor.png';
import Dashboard from './Dashboard.png';
import Tile_Editor from './Tile_Editor.png';

const SplashPage = () => {
  return (
    <Container fluid className="d-flex flex-column align-items-center justify-content-center splash-page">
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <h1 className="text-center mb-5">Subconnect - Coming Soon</h1>
          <Carousel className="custom-carousel">
            <Carousel.Item>
              <img
                className="d-block w-100 rounded"
                src={Dashboard_editor}
                alt="Dashboard Editor"
              />
              <Carousel.Caption>
                {/* Optional: Add captions here */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 rounded"
                src={Dashboard}
                alt="Dashboard"
              />
              <Carousel.Caption>
                {/* Optional: Add captions here */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 rounded"
                src={Tile_Editor}
                alt="Tile Editor"
              />
              <Carousel.Caption>
                {/* Optional: Add captions here */}
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default SplashPage;
