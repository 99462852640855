import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../../App.css';
import Header from "../../Header";
import Footer from "../../footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck, faMoneyCheckDollar, faCashRegister } from '@fortawesome/free-solid-svg-icons';


const HomePage = () => {
    return (
    <>
        <Container fluid className="p-3">
          <Header/>
            <Row className="mb-3">
            
            <div className="hero-banner text-white text-center py-5">
      <Container>
        <Row>
          <Col>
            <h1 className="display-4">Welcome to Our Website!</h1>
            <p className="lead">Here is our amazing hero banner text.</p>
          </Col>
        </Row>
      </Container>
    </div>
            </Row>

          <Row className="mb-3 pt-3 ms-5 me-5">
          <h1 class='pb-5 pt-1'>An application suite built for blockchain</h1>
          <Col md={4} className="d-flex flex-column justify-content-center align-items-start">
          <FontAwesomeIcon icon={faMoneyCheckDollar} size='2xl' color='white'/>
          <h3 class='pt-2 pb-2'>Track Portfolio Growth</h3>
          <p class='text-subtle'>Harness the power of comprehensive crypto management. Add multiple wallets, view in-depth reward charts, and receive transaction notifications, all from one dashboard – take control, stay informed, and watch your portfolio thrive.</p>
        </Col>
        <Col md={4} className="d-flex flex-column justify-content-center align-items-start">
        <FontAwesomeIcon icon={faListCheck} size='2xl' color='white' />
          <h3 class='pt-2 pb-2'>Manage Workers</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
        </Col>
        <Col md={4} className="d-flex flex-column justify-content-center align-items-start">
        <FontAwesomeIcon icon={faCashRegister} size='2xl' color='white'/>
          <h3 class='pt-2 pb-2'>Stake for Service</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
        </Col>
            </Row>

           {/*   <Row className="mb-3">
                <Col>
                    <h2>Our Services</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper eget eros sit amet tristique.</p>
                </Col>
                <Col>
                    <h2>Our Products</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper eget eros sit amet tristique.</p>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <h2>Contact Us</h2>
                    <p>You can reach us via email at info@ourwebsite.com or call us at 555-555-5555.</p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button variant="primary" size="lg">Learn More</Button>
                </Col>
            </Row>*/}
            
        </Container>
        <Footer/>
        </>
    );
};

export default HomePage;
