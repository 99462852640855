import React, { useState } from "react";
import { ApiPromise } from "@polkadot/api";
import { Keyring } from "@polkadot/keyring";

const TestSign = () => {
    const [message, setMessage] = useState("");
  const [signature, setSignature] = useState("");

  const signMessage = async () => {
    try {
      const api = await ApiPromise.create();
      const keyring = new Keyring({ type: "sr25519" });

      // Replace 'alice' with the desired account name
      const account = keyring.addFromUri("//DevTest1");

      const signedMessage = account.sign(message);
      setSignature(signedMessage.signature);
    } catch (error) {
      console.error("Error signing the message:", error);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Enter a message to sign"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button onClick={signMessage}>Sign message</button>
      {signature && <pre>{signature}</pre>}
    </div>
  );
};
export default TestSign;
