import React from "react";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {useSelector} from 'react-redux'
import axios from 'axios';
import { useRoutes } from 'react-router-dom';



const baseUrl = process.env.REACT_APP_WATERSERVER + '/water';

const initialState = {
  water: [],
  
  lastTime: 0,
  isLoading: false,
  hasLoaded: false, // set if the phala array has been loaded atleast once.
  selWater: {},
};

export const getAllWater = createAsyncThunk(
    'water/getAllWater',
    async (cUser, thunkAPI) => {
      
      try {
        const url = baseUrl + "/getallwaterlevel";
        //console.log(cUser.token);
         //thunkAPI.dispatch(openModal());
        const resp = await axios.get(url,{'headers':{'authorization':cUser.token}});
  
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue('something went wrong');
      }
    }
  );



const waterSlice = createSlice({
    name: 'water',
    initialState,
    reducers: {
      updateSelWater: (state,action) => {
        
        state.selWater = action.payload;
        
      },
      
    },
    extraReducers: {
      

      [getAllWater.pending]: (state) => {
        //console.log("getAllHelim Pending");
        state.isLoading = true;
      },
      [getAllWater.fulfilled]: (state, action) => {
        //console.log("getAllHelim fulfilled");
        console.log(action);
        state.isLoading = false;
        state.water = action.payload;
        state.lastTime = new Date().getTime();
        state.hasLoaded = true;

        
      },
      [getAllWater.rejected]: (state, action) => {
        //console.log("getAllHelim Rejected");
        //console.log(action);
        state.isLoading = false;
      },

    },
  });
  
  // console.log(cartSlice);
  export const {updateSelWater} =
  waterSlice.actions;
  
  export default waterSlice.reducer;