import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import Header from "../../Header";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { getAllUsers, updateSelUser,getAllPrivileges,clearUserPrivileges } from "./userSlice";

const UserList = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {  users,currentUser } = useSelector((store) => store.user);

  useEffect(() => {
    // if (loaded === false)
    console.log("token: " );
   
    dispatch(getAllUsers(currentUser.token));
    dispatch(getAllPrivileges(currentUser.token));
  }, []);
  const handleRowClick = (usr) =>{
    dispatch(clearUserPrivileges());
    dispatch(updateSelUser(usr));
    navigate("/editUser");
    }
  return (
    <div className="app-header">
      <Header />
      <div class='card m-4 standard-card'>
      <Table responsive className="table table-hover table-striped text-white">
        <thead>
          <tr>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone</th>
            <th>City</th>
            <th>State</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {users.map((usr) => (
            <tr key={usr.user_id}  onClick={() => handleRowClick(usr)}>
              <td> {usr.email}</td>
              <td> {usr.first_name}</td>
              <td> {usr.last_name}</td>
              <td> {usr.phone}</td>
              <td> {usr.city}</td>
              <td> {usr.state}</td>
              <td> {usr.user_status}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </div>
    </div>
  );
};

export default UserList;
