import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../App.css";


import { changePassword } from "./userSlice";
import { closeModal } from '../modal/modalSlice';

const ChangePassword = () => {
  const { selUser, currentUser } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const user = {
    user_id: "",
        
    password: "",
    password1: "",
  };

  

  const handleFormChange = ({ currentTarget: input }) => {
    console.log(input.value);

    
    user[input.name] = input.value;
    //console.log(this.state);
    //this.setState({ login });
    console.log(user);
    //dispatch(updateSelUser(usr));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Before dispatch");

    if(user.password === user.password1){
        user.user_id = selUser.user_id;
        const chpwd = {
            token: currentUser.token,
            user: user,
        }
        dispatch(changePassword(chpwd));
    }

        

    dispatch(closeModal());

    console.log(selUser);
    
    
  };
  
  return (
    <div className="app-header">
    
      <form onSubmit={handleFormSubmit}>
        <table responsive>
          <tbody>
            
            <tr>
              <td>Password</td>
              <td>
                <input
                  value={selUser.password}
                  id="password"
                  name="password"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
              <tr>
              <td>Re-enter Password</td>
              <td>
                <input
                  value={selUser.password1}
                  id="password1"
                  name="password1"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>



            
          </tbody>
        </table>

        <button>Submit</button>
      </form>
    </div>
  );
};

export default ChangePassword;
