import { MDBTooltip } from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import {
  getAllPhala,
  updateSelPhala,
  rightMenuShow,
  rightMenuHide
} from './phalaSlice';

import { doPhalaDetail, doAddWorker } from '../modal/modalSlice';
import { CheckBox } from '@material-ui/icons';
function getdockerlist(objects) {
  // Create an empty string
  let string = '';

  // Iterate over the objects in the array
  for (const object of objects) {
    // Append the object's name and status to the string, on a new line
    string += `
      ${object.name} \n
      `;
  }

  // Return the string
  return string;
}
function formatDecimal(value, decimals) {
  if (value === null) {
    return 0;
  } else {
    return value.toFixed(decimals);
  }
}

function diff_dates_sec(dt1, dt2) {
  var mdt = new Date(dt2);
  var mdt1 = new Date(dt1);
  var diff = mdt - mdt1; // / 1000;
  diff /= 3600;
  return Math.abs(Math.round(diff));
}
function diff_seconds(dt1) {
  var now = new Date();
  var dt = new Date(dt1);
  var diff = now - dt; // / 1000;
  diff /= 3600;
  return Math.abs(Math.round(diff));
}

// **************************************************************
// **************************************************************
export const phalaListColumns = [
  {
    dataField: 'selected',
    isDummyField: true,
    text: 'Selected',
    formatter: (cell, ph) => {
      // return a checkbox with phala selected
      return (
        <input
          id="selectedCheck"
          type="checkbox"
          defaultChecked={ph.selected}
          data-field="selected"
        />
      );
    }
  },
  {
    dataField: 'monitorType',
    text: 'Type'
  },
  {
    dataField: 'serviceName',
    text: 'Name'
  },
  {
    dataField: 'state',
    text: 'State'
  },
  {
    dataField: 'prb.status',
    text: 'PRB'
  },
  {
    dataField: 'stake',
    isDummyField: true,
    text: 'Stake',
    formatter: (cell, ph) => {
      let data = '';
      if (ph.registered) {
        data = formatDecimal(Number(ph.stake), 0);
      }
      return <div>{data}</div>;
    }
  },
  {
    dataField: 'totalReward',
    isDummyField: true,
    text: 'Rewards',
    formatter: (cell, ph) => {
      let data = '';
      if (ph.registered) {
        data = formatDecimal(Number(ph.totalReward), 0);
      }
      return <div>{data}</div>;
    }
  },
  {
    dataField: 'pruntime.score',
    isDummyField: true,
    text: 'Score',
    formatter: (cell, ph) => {
      let data = '';
      if (ph.registered && ph.pruntime) {
        data = formatDecimal(Number(ph.pruntime.score), 0);
      }
      return <div>{data}</div>;
    }
  },
  {
    dataField: 'apr',
    isDummyField: true,
    text: 'APR',
    formatter: (cell, ph) => {
      let data = '';
      if (ph.registered) {
        data = formatDecimal(Number(ph.apr), 0) + '%';
      }
      return <div>{data}</div>;
    }
  },
  {
    dataField: 'gas',
    isDummyField: true,
    text: 'Gas',
    formatter: (cell, ph) => {
      let data = '';
      if (ph.monitorType === 'solo') {
        data = formatDecimal(Number(ph.gas), 2);
      }
      return <div>{data}</div>;
    }
  },
  {
    dataField: 'cpu_load',
    isDummyField: true,
    text: 'CPU',
    formatter: (cell, ph) => {
      let data = '';

      data = formatDecimal(Number(ph.linuxData.CPULoad), 0) + '%';

      return <div>{data}</div>;
    }
  },
  {
    dataField: 'CPU_Temp',
    isDummyField: true,
    text: 'temp',
    formatter: (cell, ph) => {
      let data = '';
      if (ph.linuxData.Critical_Temp > 0)
        data =
          formatDecimal(Number(ph.linuxData.CPU_Temp), 0) +
          '/' +
          formatDecimal(Number(ph.linuxData.Critical_Temp), 0);

      return <div>{data}</div>;
    }
  },
  {
    dataField: 'total_ram',
    isDummyField: true,
    text: 'Ram',
    formatter: (cellContent, row) => {
      return (
        <div>
          {formatDecimal(
            Number(
              (row.linuxData.total_ram - row.linuxData.used_ram) / 1000000000
            ),
            1
          )}{' '}
          /{formatDecimal(Number(row.linuxData.total_ram / 1000000000), 1)}
        </div>
      );
    }
  },
  {
    dataField: 'services',
    isDummyField: true,
    text: 'Services',
    formatter: (cellContent, ph) => {
      return (
        <div>
          {ph.dockerServicesRunning === true ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#0CE887"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#E83517"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          )}
        </div>
      );
    }
  },
  {
    dataField: 'Sync',
    isDummyField: true,
    text: 'Sync',
    formatter: (cellContent, ph) => {
      if (ph.monitorType === 'solo') {
        return (
          <div>
            <MDBTooltip
              tag="a"
              title={'Khala: ' + formatDecimal(Number(ph.deltaKhala), 0)}
            >
              {ph.deltaKhala < 20 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#0CE887"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#E83517"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
              )}
            </MDBTooltip>
            <MDBTooltip
              tag="a"
              title={'Kusama: ' + formatDecimal(Number(ph.deltaKusama), 0)}
            >
              {ph.deltaKusama < 20 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#0CE887"
                  class="bi bi-circle-fill m-1"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#E83517"
                  class="bi bi-circle-fill m-1"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
              )}
            </MDBTooltip>
            <MDBTooltip
              tag="a"
              title={
                'Pherry: ' +
                formatDecimal(Number(ph.deltaKhalaPherry), 0) +
                ':' +
                formatDecimal(Number(ph.deltaKusamaPherry), 0)
              }
            >
              {ph.deltaKhalaPherry < 20 && ph.deltaKusamaPherry < 20 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#0CE887"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#E83517"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
              )}
            </MDBTooltip>
          </div>
        );
      } else {
        if (ph.monitorType === 'prbworker') {
          return (
            <>
              <MDBTooltip
                tag="a"
                title={
                  'Pherry: ' +
                  formatDecimal(Number(ph.deltaKhalaPherry), 0) +
                  ':' +
                  formatDecimal(Number(ph.deltaKusamaPherry), 0)
                }
              >
                {ph.deltaKhalaPherry < 20 && ph.deltaKusamaPherry < 20 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#0CE887"
                    class="bi bi-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#E83517"
                    class="bi bi-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>
                )}
              </MDBTooltip>
            </>
          );
        } else {
          if (ph.monitorType === 'prb') {
            return (
              <>
                <MDBTooltip
                  tag="a"
                  title={'Khala: ' + formatDecimal(Number(ph.deltaKhala), 0) + "\n" + 'Kusama: ' + formatDecimal(Number(ph.deltaKusama), 0)}
                >
                  {ph.deltaKhala < 20 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#0CE887"
                      class="bi bi-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#E83517"
                      class="bi bi-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" />
                    </svg>
                  )}
                </MDBTooltip>
              </>
            );
          } else {
            if (ph.monitorType === 'phalanode') {
              return (
                <>
                  <MDBTooltip
                    tag="a"
                    title={'Khala: ' + formatDecimal(Number(ph.deltaKhala), 0) + "\n" + 'Kusama: ' + formatDecimal(Number(ph.deltaKusama), 0)}
                  >
                    {ph.deltaKhala < 20 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#0CE887"
                        class="bi bi-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <circle cx="8" cy="8" r="8" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#E83517"
                        class="bi bi-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <circle cx="8" cy="8" r="8" />
                      </svg>
                    )}
                  </MDBTooltip>
                </>
              );
            }
          }
        }
        return <></>;
      }
    }
  },
  {
    dataField: 'update',
    isDummyField: true,
    text: 'Update',
    formatter: (cellContent, ph) => {
      return <div>{diff_seconds(ph.updateTime)}</div>;
    }
  }
];

// **************************************************************
// **************************************************************

export const phalaListColumnsOld = [
  {
    dataField: 'Sync',
    isDummyField: true,
    text: 'Sync',
    formatter: (cellContent, ph) => {
      return (
        <div>
          <MDBTooltip
            tag="a"
            title={
              'Khala: ' +
              formatDecimal(Number(ph.khala_high - ph.khala_current), 0) +
              ':' +
              diff_dates_sec(ph.last_khala_change_time, ph.update_time)
            }
          >
            {ph.khala_high - ph.khala_current < 20 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#0CE887"
                class="bi bi-circle-fill"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#E83517"
                class="bi bi-circle-fill"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
            )}
          </MDBTooltip>
          <MDBTooltip
            tag="a"
            title={
              'Kusama: ' +
              formatDecimal(Number(ph.kusama_high - ph.kusama_current), 0) +
              ':' +
              diff_dates_sec(ph.last_kusama_change_time, ph.update_time)
            }
          >
            {ph.kusama_high - ph.kusama_current < 20 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#0CE887"
                class="bi bi-circle-fill m-1"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#E83517"
                class="bi bi-circle-fill m-1"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
            )}
          </MDBTooltip>
          <MDBTooltip
            tag="a"
            title={
              'Pherry: ' +
              formatDecimal(Number(ph.khala_high - ph.khala_pherry), 0) +
              ':' +
              formatDecimal(Number(ph.kusama_high - ph.kusama_pherry), 0)
            }
          >
            {ph.khala_high - ph.khala_pherry < 20 &&
            ph.kusama_high - ph.kusama_pherry < 20 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#0CE887"
                class="bi bi-circle-fill"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#E83517"
                class="bi bi-circle-fill"
                viewBox="0 0 16 16"
              >
                <circle cx="8" cy="8" r="8" />
              </svg>
            )}
          </MDBTooltip>
        </div>
      );
    }
  },

  {
    dataField: 'update',
    isDummyField: true,
    text: 'Update',
    formatter: (cellContent, ph) => {
      return <div>{diff_seconds(ph.update_time)}</div>;
    }
  }
];

//********************************************************************************************************************
//********************************************************************************************************************
//********************************************************************************************************************

export const phalaListColumnMd = [
  {
    dataField: 'host_name',
    text: 'Name'
  },
  {
    dataField: 'miner.state',
    text: 'State'
  },

  {
    dataField: 'miner.totalReward',
    text: 'Rewards',
    formatter: (cell, row) => formatDecimal(Number(cell), 2)
  },
  {
    dataField: 'APR',
    isDummyField: true,
    text: 'APR',
    formatter: (cell, ph) => {
      return (
        <div className="text-light">
          {formatDecimal(Number(ph.miner.apr), 0)}%
        </div>
      );
    }
  },

  {
    dataField: 'services',
    isDummyField: true,
    text: 'Srvs',
    formatter: (cellContent, ph) => {
      let tSrv = false;
      if (
        ph.pruntime === 'running' &&
        ph.phala_node === 'running' &&
        ph.pherry === 'running'
      ) {
        tSrv = true;
      }
      return (
        <div>
          {tSrv === true ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#0CE887"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#E83517"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          )}
        </div>
      );
    }
  },

  {
    dataField: 'khala',
    isDummyField: true,
    text: 'Sync',
    formatter: (cellContent, ph) => {
      const tkhala = ph.khala_high - ph.khala_current;
      const tkhalaSync = diff_dates_sec(
        ph.last_khala_change_time,
        ph.update_time
      );
      const tkusama = ph.kusama_high - ph.kusama_current;
      const tkusamaSync = diff_dates_sec(
        ph.last_kusama_change_time,
        ph.update_time
      );
      const khalaPherry = ph.khala_high - ph.khala_pherry;
      const kusamaPherry = ph.kusama_high - ph.kusama_pherry;
      let tSync = false;
      if (
        tkhala < 20 &&
        tkhalaSync < 20 &&
        tkusama < 20 &&
        tkusamaSync < 20 &&
        khalaPherry < 20 &&
        kusamaPherry < 20
      ) {
        tSync = true;
      }
      return (
        <div>
          {tSync === true ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#0CE887"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#E83517"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          )}
        </div>
      );
    }
  },

  {
    dataField: 'update',
    isDummyField: true,
    text: 'Update',
    formatter: (cellContent, ph) => {
      return <div>{diff_seconds(ph.update_time)}</div>;
    }
  }
];

// ******************************************************************************************
// ******************************************************************************************

export const phalaListColumnSm = [
  {
    dataField: 'host_name',
    text: 'Name'
  },

  {
    dataField: 'services',
    isDummyField: true,
    text: 'Services',
    formatter: (cellContent, ph) => {
      let tSrv = false;
      if (
        ph.pruntime === 'running' &&
        ph.phala_node === 'running' &&
        ph.pherry === 'running'
      ) {
        tSrv = true;
      }
      return (
        <div>
          {tSrv === true ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#0CE887"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#E83517"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          )}
        </div>
      );
    }
  },
  {
    dataField: 'khala',
    isDummyField: true,
    text: 'Sync',
    formatter: (cellContent, ph) => {
      const tkhala = ph.khala_high - ph.khala_current;
      const tkhalaSync = diff_dates_sec(
        ph.last_khala_change_time,
        ph.update_time
      );
      const tkusama = ph.kusama_high - ph.kusama_current;
      const tkusamaSync = diff_dates_sec(
        ph.last_kusama_change_time,
        ph.update_time
      );
      const khalaPherry = ph.khala_high - ph.khala_pherry;
      const kusamaPherry = ph.kusama_high - ph.kusama_pherry;
      let tSync = false;
      if (
        tkhala < 20 &&
        tkhalaSync < 20 &&
        tkusama < 20 &&
        tkusamaSync < 20 &&
        khalaPherry < 20 &&
        kusamaPherry < 20
      ) {
        tSync = true;
      }
      return (
        <div>
          {tSync === true ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#0CE887"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#E83517"
              class="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
          )}
        </div>
      );
    }
  },

  {
    dataField: 'update',
    isDummyField: true,
    text: 'Update',
    formatter: (cellContent, ph) => {
      return <div>{diff_seconds(ph.update_time)}</div>;
    }
  }
];
