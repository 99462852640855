import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import Header from "../../Header";
import { userLogout } from './userSlice';


const Logout = () => {
    
    const { currentUser } = useSelector((store) => store.user);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    
    
    useEffect(() => {
      if (currentUser.isLoggedIn){
        dispatch(userLogout())}
      else{
        navigate("/")
      }
  
    })
    return (
        <div className="app-header">
          <Header />
          
        </div>
      );
    
  };
export default Logout;