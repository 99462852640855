import React, { useEffect, useState } from 'react';

import { Card, Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix

import { useDispatch, useSelector } from 'react-redux';
import {
  getAprByLevel,
  getResponsive,
  getBlockTime,
  setNetworkChart,
  getHalving,
  getPhalaPrice,
  getHourlyStats,
  getCurrentStats
} from '../phalaSlice';
import { CardHeader } from 'material-ui';
import Tooltip from '@mui/material/Tooltip';
import { MDBTooltip } from 'mdb-react-ui-kit';
//import PhalaPrice from '../financialModels/phalaPrice';

const NetworkWidgets = () => {
  const dispatch = useDispatch();
  const [avgBlockTimeArray, setAvgBlockTime] = useState([0]);
  const [totalRewardsArray, setTotalRewards] = useState([0]);

  const {
    aprByLevel,
    rewardsByHour,
    responsiveByHour,
    responsiveIsLoading,
    blockTimeByHour,
    blockTimeIsLoading,
    averageDenominator,
    halving,
    phalaPrice,
    hourlyStats,
    currentStats
  } = useSelector(store => store.phala);

  const responsiveCount = responsiveByHour.reduce(
    (acc, cur) => acc + cur.responsive,
    0
  );
  const unresponsiveCount = responsiveByHour.reduce(
    (acc, cur) => acc + cur.unresponsive,
    0
  );
  const avgBlockTimeWidget =
    blockTimeByHour.reduce((acc, cur) => acc + cur.avgBlockTime, 0) /
    blockTimeByHour.length;
  const poolCreatedCount = hourlyStats.reduce(
    (acc, cur) => acc + cur.sumPoolCreated,
    0
  );
  const workerAddedCount = hourlyStats.reduce(
    (acc, cur) => acc + cur.sumPoolWorkerAdded,
    0
  );
  const workerRemovedCount = hourlyStats.reduce(
    (acc, cur) => acc + cur.sumPoolWorkerRemoved,
    0
  );
  const killedAccountsCount = hourlyStats.reduce(
    (acc, cur) => acc + cur.sumKilledAccount,
    0
  );
  const workerStartedCount = hourlyStats.reduce(
    (acc, cur) => acc + cur.sumWorkerStarted,
    0
  );
  const workerStoppedCount = hourlyStats.reduce(
    (acc, cur) => acc + cur.sumWorkerStopped,
    0
  );
  const eventCount = hourlyStats.reduce(
    (acc, cur) => acc + cur.sumEventCountSum,
    0
  );
  const transfers = hourlyStats.reduce((acc, cur) => acc + cur.transfers, 0);
  const avgAprLvlWidget =
    aprByLevel.reduce((acc, cur) => acc + cur.averageApr, 0) /
    aprByLevel.length;
  const [nextHalvingDate, setNextHalvingDate] = useState(
    new Date('2023-06-12T12:00:00')
  );

  //daily avg awards
  const currentTime = new Date().getTime();
  const twentyFourHoursAgo = currentTime - 24 * 60 * 60 * 1000; // 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
  const last24HoursRewards = rewardsByHour.filter(
    entry => entry.minTime >= twentyFourHoursAgo
  );
  const lvl1RewardWidget = last24HoursRewards.reduce(
    (acc, cur) => acc + cur.rewardsLevel1,
    0
  );
  const lvl2RewardWidget = last24HoursRewards.reduce(
    (acc, cur) => acc + cur.rewardsLevel2,
    0
  );
  const lvl3RewardWidget = last24HoursRewards.reduce(
    (acc, cur) => acc + cur.rewardsLevel3,
    0
  );
  const lvl4RewardWidget = last24HoursRewards.reduce(
    (acc, cur) => acc + cur.rewardsLevel4,
    0
  );
  const lvl5RewardWidget = last24HoursRewards.reduce(
    (acc, cur) => acc + cur.rewardsLevel5,
    0
  );

  //currentStats
  const dailyRewards =
    (currentStats?.to_Owner24hour || 0) + (currentStats?.to_Staker24hour || 0);

  //halving
  const calculateNextHalving = fromDate => {
    // 180 days in milliseconds (180 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    const halfPeriod = 180 * 24 * 60 * 60 * 1000;

    // Calculate the next halving time
    const nextHalvingTime = new Date(fromDate.getTime() + halfPeriod);

    // Set the time to midday
    nextHalvingTime.setHours(12, 0, 0, 0);

    return nextHalvingTime;
  };

  useEffect(() => {
    // Function to update the next halving date if it has been reached
    const updateHalvingDate = () => {
      const currentTime = new Date();
      if (currentTime >= nextHalvingDate) {
        setNextHalvingDate(calculateNextHalving(nextHalvingDate));
      }
    };

    // Update the halving date when the component mounts
    updateHalvingDate();

    // Check every minute if the halving date has been reached
    const intervalId = setInterval(updateHalvingDate, 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [nextHalvingDate]);

  const setChartHandler = chartName => {
    dispatch(setNetworkChart(chartName));
  };
  useEffect(() => {
    dispatch(getPhalaPrice());
  }, []);

  return (
    <div className="text-white text-start m-0">
      <Row>
        <Col xs={6} sm={4} md={4} lg={4} className="p-2">
          <Card className="standard-card h-100">
            <Card.Body>
              <h6 className="text-subtle">PHA</h6>
              <h5>${phalaPrice?.price}</h5>
              <p className="cg-branding m-0 pb-1 text-secondary text-start">
                {new Date(phalaPrice?.timestamp).toLocaleString('en-us', {
                  hour12: true,
                  second: 'numeric',
                  minute: 'numeric',
                  hour: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric'
                })}
              </p>
              <p className="cg-branding m-0 text-subtle">
                Data from{' '}
                <a
                  href="https://coingecko.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CoinGecko
                </a>
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} sm={4} md={4} lg={4} className="p-2">
          <Card className="standard-card h-100">
            <Card.Body>
              <Row>
                <Col xs={8}>
                  <Tooltip
                    title="Top value represents delegator APR. Bottom value represents worker APR."
                    placement="top"
                  >
                    <h6 className="text-subtle">
                      APR
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        fill="currentColor"
                        class="bi bi-info-circle ms-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                      </svg>
                    </h6>
                  </Tooltip>
                </Col>
                <Col xs={4} className="d-flex justify-content-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5rem"
                    fill="currentColor"
                    class="bi bi-percent"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0M4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                  </svg>
                </Col>
              </Row>
              <h5>
                {currentStats?.delegationApr24hour
                  ? Number(
                      currentStats.delegationApr24hour.toFixed(2)
                    ).toLocaleString('en-US')
                  : 'N/A'}
                %
              </h5>
              <h6 className="text-subtle">
                {currentStats?.workerApr24hour
                  ? Number(
                      currentStats.workerApr24hour.toFixed(2)
                    ).toLocaleString('en-US')
                  : 'N/A'}
                %
              </h6>

              <div
                class="svg-container m-0 d-flex justify-content-end text-success"
                style={{ cursor: 'pointer' }}
                onClick={() => setChartHandler('Apr')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5rem"
                  fill="currentColor"
                  class="bi bi-arrow-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} sm={4} md={4} lg={4} className=" p-2">
          <Card className="standard-card h-100">
            <Card.Body>
              <Row>
                <Col xs={8}>
                  <h6 className="text-subtle">Online Workers</h6>
                </Col>
                <Col className="d-flex justify-content-end" xs={4}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5rem"
                    fill="currentColor"
                    class="bi bi-broadcast"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707m2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708m5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708m2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
                  </svg>
                </Col>
              </Row>
              <h5>
                {currentStats?.onlineWorkers
                  ? Number(
                      currentStats.onlineWorkers.toFixed(2)
                    ).toLocaleString('en-US')
                  : 'N/A'}
              </h5>

              <div
                class="svg-container m-0 d-flex justify-content-end text-success"
                style={{ cursor: 'pointer' }}
                onClick={() => setChartHandler('Worker')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5rem"
                  fill="currentColor"
                  class="bi bi-arrow-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={6} sm={4} md={4} lg={4} className="p-2">
          <Card className="standard-card h-100">
            <Card.Body>
              <Row>
                <Col xs={8}>
                  <h6 className="text-subtle">Transfers</h6>
                </Col>
                <Col xs={4} className="d-flex justify-content-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5rem"
                    fill="currentColor"
                    class="bi bi-arrow-left-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5"
                    />
                  </svg>
                </Col>
              </Row>
              <h5>
                {Number(transfers.toFixed(0)).toLocaleString('en-US')}
                <sub className="text-subtle">pha</sub>
              </h5>

              <div
                class="svg-container m-0 d-flex justify-content-end text-success"
                style={{ cursor: 'pointer' }}
                onClick={() => setChartHandler('Transfers')}
              >
                 <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5rem"
                  fill="currentColor"
                  class="bi bi-arrow-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} sm={4} md={4} lg={4} className="p-2">
          <Card className="standard-card h-100">
            <Card.Body>
              <Row>
                <Col xs={8}>
                  <h6 className="text-subtle">Block Time</h6>
                </Col>
                <Col xs={4} className="d-flex justify-content-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5rem"
                    fill="currentColor"
                    class="bi bi-heart-pulse"
                    viewBox="0 0 16 16"
                  >
                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053.918 3.995.78 5.323 1.508 7H.43c-2.128-5.697 4.165-8.83 7.394-5.857.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17c3.23-2.974 9.522.159 7.394 5.856h-1.078c.728-1.677.59-3.005.108-3.947C13.486.878 10.4.28 8.717 2.01zM2.212 10h1.315C4.593 11.183 6.05 12.458 8 13.795c1.949-1.337 3.407-2.612 4.473-3.795h1.315c-1.265 1.566-3.14 3.25-5.788 5-2.648-1.75-4.523-3.434-5.788-5Z" />
                    <path d="M10.464 3.314a.5.5 0 0 0-.945.049L7.921 8.956 6.464 5.314a.5.5 0 0 0-.88-.091L3.732 8H.5a.5.5 0 0 0 0 1H4a.5.5 0 0 0 .416-.223l1.473-2.209 1.647 4.118a.5.5 0 0 0 .945-.049l1.598-5.593 1.457 3.642A.5.5 0 0 0 12 9h3.5a.5.5 0 0 0 0-1h-3.162z" />
                  </svg>
                </Col>
              </Row>
              <h5>
                {Number(avgBlockTimeWidget.toFixed(2)).toLocaleString('en-US')}
                <sub className="text-subtle">sec</sub>
              </h5>
              <div
                class="svg-container m-0 d-flex justify-content-end text-success"
                style={{ cursor: 'pointer' }}
                onClick={() => setChartHandler('Block')}
              >
                 <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5rem"
                  fill="currentColor"
                  class="bi bi-arrow-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} sm={4} md={4} lg={4} className="p-2">
          <Card className="standard-card h-100">
            <Card.Body>
              <Row>
                <Col xs={8}>
                  <h6 className="text-subtle"> Daily Rewards</h6>
                </Col>
                <Col xs={4} className="d-flex justify-content-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5rem"
                    fill="currentColor"
                    class="bi bi-piggy-bank"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0m1.138-1.496A6.613 6.613 0 0 1 7.964 4.5c.666 0 1.303.097 1.893.273a.5.5 0 0 0 .286-.958A7.602 7.602 0 0 0 7.964 3.5c-.734 0-1.441.103-2.102.292a.5.5 0 1 0 .276.962" />
                    <path
                      fill-rule="evenodd"
                      d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069c0-.145-.007-.29-.02-.431.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a.95.95 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.735.735 0 0 0-.375.562c-.024.243.082.48.32.654a2.112 2.112 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595zM2.516 6.26c.455-2.066 2.667-3.733 5.448-3.733 3.146 0 5.536 2.114 5.536 4.542 0 1.254-.624 2.41-1.67 3.248a.5.5 0 0 0-.165.535l.66 2.175h-.985l-.59-1.487a.5.5 0 0 0-.629-.288c-.661.23-1.39.359-2.157.359a6.558 6.558 0 0 1-2.157-.359.5.5 0 0 0-.635.304l-.525 1.471h-.979l.633-2.15a.5.5 0 0 0-.17-.534 4.649 4.649 0 0 1-1.284-1.541.5.5 0 0 0-.446-.275h-.56a.5.5 0 0 1-.492-.414l-.254-1.46h.933a.5.5 0 0 0 .488-.393zm12.621-.857a.565.565 0 0 1-.098.21.704.704 0 0 1-.044-.025c-.146-.09-.157-.175-.152-.223a.236.236 0 0 1 .117-.173c.049-.027.08-.021.113.012a.202.202 0 0 1 .064.199z"
                    />
                  </svg>
                </Col>
              </Row>
              <h5>
                {Number(dailyRewards.toFixed(0)).toLocaleString('en-US')}
                <sub className="text-subtle">pha</sub>
              </h5>
              <div
                class="svg-container m-0 d-flex justify-content-end text-success"
                style={{ cursor: 'pointer' }}
                onClick={() => setChartHandler('Rewards')}
              >
                 <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5rem"
                  fill="currentColor"
                  class="bi bi-arrow-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={6} sm={4} md={4} lg={4} className="p-2">
          <Card className="standard-card h-100">
            <Card.Body>
              <Row>
                <Col xs={8}>
                  <h6 className="text-subtle">Pools</h6>
                </Col>
                <Col xs={4} className="d-flex justify-content-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5rem"
                    fill="currentColor"
                    class="bi bi-database"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313ZM13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 5.698M14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 8.698m0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525" />
                  </svg>
                </Col>
              </Row>
              <h5>
                {currentStats?.poolDelegations
                  ? Number(
                      currentStats.poolDelegations.toFixed(0)
                    ).toLocaleString('en-US')
                  : 'N/A'}
                <sub className="text-subtle">pha</sub>
              </h5>
              <h6 className="text-subtle">
                {currentStats?.poolCountGtZero
                  ? Number(
                      currentStats.poolCountGtZero.toFixed(0)
                    ).toLocaleString('en-US')
                  : 'N/A'}
              </h6>

              <div
                class="svg-container m-0 d-flex justify-content-end text-success"
                style={{ cursor: 'pointer' }}
                onClick={() => setChartHandler('Pools')}
              >
                 <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5rem"
                  fill="currentColor"
                  class="bi bi-arrow-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} sm={4} md={4} lg={4} className="p-2">
          <Card className="standard-card h-100">
            <Card.Body>
              <Row>
                <Col xs={8}>
                  <h6 className="text-subtle">Vaults</h6>
                </Col>
                <Col xs={4} className="d-flex justify-content-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5rem"
                    fill="currentColor"
                    class="bi bi-bank"
                    viewBox="0 0 16 16"
                  >
                    <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72l-.25-1Z" />
                  </svg>
                </Col>
              </Row>
              <h5>
                {currentStats?.vaultDelegations
                  ? Number(
                      currentStats.vaultDelegations.toFixed(0)
                    ).toLocaleString('en-US')
                  : 'N/A'}
                <sub className="text-subtle ">pha</sub>
              </h5>
              <Tooltip title="Total Vaults" placement="top">
                <h6 className="text-subtle">
                  {currentStats?.vaultCountGtZero
                    ? Number(
                        currentStats.vaultCountGtZero.toFixed(0)
                      ).toLocaleString('en-US')
                    : 'N/A'}
                </h6>
              </Tooltip>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} sm={4} md={4} lg={4} className="p-2">
          <Card className="standard-card h-100">
            <Card.Body>
              <Row>
                <Col xs={8}>
                  <h6 className="text-subtle">Halving</h6>
                </Col>
                <Col xs={4} className="d-flex justify-content-end mb-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5rem"
                    fill="currentColor"
                    class="bi bi-clock-history"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483m.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535m-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                    <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                    <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                  </svg>
                </Col>
              </Row>
              <h5>{nextHalvingDate.toDateString()}</h5>
              <div
                class="svg-container m-0 d-flex justify-content-end text-success"
                style={{ cursor: 'pointer' }}
                onClick={() => setChartHandler('Halving')}
              >
                 <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5rem"
                  fill="currentColor"
                  class="bi bi-arrow-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={6} sm={4} md={4} lg={4} className="p-2">
          <Card className="standard-card h-100">
            <Card.Body>
              <Row>
                <Col xs={8}>
                  <h6 className="text-subtle">Events</h6>
                </Col>
                <Col xs={4} className="d-flex justify-content-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5rem"
                    fill="currentColor"
                    class="bi bi-calendar3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                    <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                  </svg>
                </Col>
              </Row>
              <h5>
                {currentStats?.eventCount
                  ? Number(currentStats.eventCount.toFixed(0)).toLocaleString(
                      'en-US'
                    )
                  : 'N/A'}
              </h5>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} sm={4} md={4} lg={4} className="p-2">
          <Card className="standard-card h-100 mb-3">
            <Card.Body>
              <Row>
                <Col xs={8}>
                  <h6 className="text-subtle">Wallets</h6>
                </Col>
                <Col xs={4} className="d-flex justify-content-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5rem"
                    fill="currentColor"
                    class="bi bi-wallet"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1" />
                  </svg>
                </Col>
              </Row>
              <h5>
                {currentStats?.accountCount
                  ? Number(currentStats.accountCount.toFixed(0)).toLocaleString(
                      'en-US'
                    )
                  : 'N/A'}
              </h5>
              <div
                class="svg-container m-0 d-flex justify-content-end text-success"
                style={{ cursor: 'pointer' }}
                onClick={() => setChartHandler('Wallets')}
              >
                 <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5rem"
                  fill="currentColor"
                  class="bi bi-arrow-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                </svg>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

{
  /*<section class='ms-5'>
         <Tooltip title="Select card for additional data." placement="top"><h1 >Network Data</h1></Tooltip>
        
        <div className='row text-center d-flex justify-content-start'>
        <div className='col-6 card p-0 col-md-4 col-sm-4 mb-3 m-0 me-3 standard-card'>
                
                <div className='card-body row'>
                    <div className='col'>
                        
                        <h6 class="pt-3 header-subtle text-center"><small>PHA Price</small></h6>
                         <h3>${phalaPrice.price}</h3>
                         <p className="cg-branding m-0 pb-1 text-secondary text-center">
                {new Date(phalaPrice.timestamp).toLocaleString('en-us', {
          hour12: true,
          second: 'numeric',
          minute: 'numeric',
          hour: 'numeric',
          month: 'numeric',
          day: 'numeric',
          year: 'numeric'
        })}
        </p>      
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-end'> {/* Add this wrapper div 
                
        <p className="cg-branding m-0 ps-3 pb-1">
            Data from <a href="https://coingecko.com" target="_blank" rel="noopener noreferrer">CoinGecko</a>
        </p>


        {/* <p className='m-0 text-success pe-3 pb-1 text-success'>
            More<svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" fill="currentColor" class="bi bi-arrow-right ms-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg>
    </p> 
    </div></div>
                  
            <div className='col-12 card p-0 mb-3 col-sm-7 m-0 standard-card' onClick={() => setChartHandler("Worker")}>
                
            <div className='row card-body '>
                
                <Tooltip title="Instances of workers entering responsive and unresponsive states." placement="top" className="header-subtle">
                    <h5 class="pb-2">Worker Response
                    <svg xmlns="http://www.w3.org/2000/svg" width="10"fill="currentColor" class="bi bi-info-circle mt-1 ms-1" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                    </h5>
                    </Tooltip>
                    <div className='col'>
                    <h6><small>Responsive</small></h6>
                    <h3>{Number(responsiveCount).toLocaleString("en-US")}</h3>
                </div>
                <div className='col'>
                        <h6><small>Unresponsive</small></h6>
                        <h3>{Number(unresponsiveCount).toLocaleString("en-US")}</h3>
                    </div>
                </div>
                <p class='m-0 d-flex justify-content-end pe-3 pb-1 text-success'>More<svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" fill="currentColor" class="bi bi-arrow-right ms-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></p>
            </div>
            <div className='col-6 card p-0 col-md-4 col-sm-4 mb-3 m-0 me-3 standard-card' onClick={() => setChartHandler("Block")}>
                
            <div className='card-body row '>
                <div className='col'>
                    
                    <h6 class="pt-3 header-subtle"><small>Average Block</small></h6>
                    <h3>{Number((avgBlockTimeWidget).toFixed(2)).toLocaleString("en-US")}<sub class="ps-1 text-secondary"><small>Sec</small></sub></h3>
                </div>
            </div>
            <p class='m-0 d-flex justify-content-end pe-3 text-success pb-1'>More<svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" fill="currentColor" class="bi bi-arrow-right ms-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></p>
            </div>  
            {/* <div className='col-12 card me-4 p-0 mb-3 col-sm-7 btn m-0' onClick={() => setChartHandler("Halving")} >
                
            <div className='card-body row '>
                <div className='col'>
                    
                <Tooltip title="Calculated using average block time and updated every 6 hours." placement="top" className="header-subtle"><h6 class="pt-3"><small>EST. Halving<svg xmlns="http://www.w3.org/2000/svg" width="10"fill="currentColor" class="bi bi-info-circle mt-1 ms-1" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg></small></h6></Tooltip>
                    <h3>{new Date(halving.halvingDate).toLocaleString('en-us', {
          hour12: true,

          hour: 'numeric',
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })} </h3>
                </div>
            </div>
            <p class='m-0 d-flex justify-content-end pe-3 pb-1 text-success'>More<svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" fill="currentColor" class="bi bi-arrow-right ms-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></p> 
    </div> 
    <div className='col-6 card p-0 col-md-4 col-sm-4 mb-3 m-0 me-3 standard-card' onClick={() => setChartHandler("Pools")}>
                
                <div className='card-body row text-center'>
                    
                        
                        <h5 class=" text-center text-subtle">Pools</h5>
                        <div className='col'>
                        <h6><small>Total</small></h6>
                         <h3>N/A</h3>
                        </div>
                        <div className='col'>
                        <h6><small>New</small></h6>
                         <h3>{Number(poolCreatedCount).toLocaleString("en-US")}</h3>
                        </div>
                    
                </div>
                
                
        


                <p class='m-0 d-flex justify-content-end pe-3 pb-1 text-success'>More<svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" fill="currentColor" class="bi bi-arrow-right ms-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></p>
    </div>
    <div className='col-6 card p-0 col-md-4 col-sm-4 mb-3 m-0 me-3 standard-card' onClick={() => setChartHandler("Workers")}>
                
                <div className='card-body row'>
                  
                        
                        <h5 class="header-subtle text-center">Workers</h5>
                         <div className="col">
                         <h6>Added</h6>
                         <h3>{Number(workerAddedCount).toLocaleString("en-US")}</h3>
                         <h6>Removed</h6>
                         <h3>{Number(workerRemovedCount).toLocaleString("en-US")}</h3>
                         </div>
                         <div className="col">
                         <h6>Started</h6>
                         <h3>{Number(workerStartedCount).toLocaleString("en-US")}</h3>
                         <h6>Stopped</h6>
                         <h3>{Number(workerStoppedCount).toLocaleString("en-US")}</h3>
                         </div>
                    
                </div>
                <p class='m-0 d-flex justify-content-end pe-3 pb-1 text-success'>More<svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" fill="currentColor" class="bi bi-arrow-right ms-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></p></div>
    <div className='col-6 card p-0 col-md-4 col-sm-4 mb-3 m-0 me-3 standard-card' onClick={() => setChartHandler("Events")}>
    <div className='card-body row text-center'>
                    
                        
                    <h5 class=" text-center text-subtle">Events</h5>
                    <div className='col'>
                    <h6><small>Total</small></h6>
                     <h3>N/A</h3>
                    </div>
                    <div className='col'>
                    <h6><small>New</small></h6>
                     <h3>{Number(eventCount).toLocaleString("en-US")}</h3>
                    </div>
                
            </div>
                
                <p class='m-0 d-flex justify-content-end pe-3 pb-1 text-success'>More<svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" fill="currentColor" class="bi bi-arrow-right ms-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></p></div>
    <div className='col-6 card p-0 col-md-4 col-sm-4 mb-3 m-0 me-3 standard-card ' onClick={() => setChartHandler("Wallets")}>
    <div className='card-body row text-center'>
                    
                        
                    <h5 class=" text-center text-subtle">Accounts</h5>
                    <div className='col'>
                    <h6><small>Total</small></h6>
                     <h3>N/A</h3>
                    </div>
                    <div className='col'>
                    <h6><small>Killed</small></h6>
                     <h3>{Number(killedAccountsCount).toLocaleString("en-US")}</h3>
                    </div>
                
            </div>
               
                <p class='m-0 d-flex justify-content-end pe-3 pb-1 text-success'>More<svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" fill="currentColor" class="bi bi-arrow-right ms-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></p></div>
        </div>
</section>*/
}

export default NetworkWidgets;
