import React, { useEffect, useState } from 'react';

import { Card, Dropdown, DropdownButton } from 'react-bootstrap';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { getBlockTime, setNetworkChartTitle, getResponsive, getHourlyStats } from '../phalaSlice';
import "../../../App.css";

const PoolsCreated = () => {
    const dispatch = useDispatch();
  const [categoryArray, setCategory] = useState([]);
  const [poolsCreatedArray, setPoolsCreated] = useState([0]);
  const [chartInterval, setChartInterval] = useState('hour');
  const { networkChartTitle, hourlyStats, blockTimeIsLoading} = useSelector(
    store => store.phala
  );
    
  const eChartsOption = {
    tooltip: {
      trigger: 'axis',
      position: 'top', // set the tooltip position to top
      offset: [0, 10], // set the vertical offset to 10 pixels
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: '#c8e2f7',
      textStyle: {
        color: '#000'
    }
    },
    toolbox: {
      feature: {
        
      },
      iconStyle: {
        borderColor: 'white',
      }
    },
    textStyle: {
      color: '#fff'
    },
    legend: {
      data: ['Responsive', 'Unresponsive'],
      top: '25',
      inactiveColor: '#555555',
      
      textStyle: {
        color: 'white'
      }
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      axisLabel: {
        opacity: 0.5  // set the opacity of the axis labels to 50%
      },
    },

    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
            opacity: 0.08  // set the opacity of the line to 50%
        }
    },
      axisLabel: {
            opacity: 0.5  // set the opacity of the axis labels to 50%
    },
    },
    grid: [
      {
        left: '10%',
        right: '5%',
        height: '70%'
      }
    ],
    series: [
        {
          name: 'Pools Created',
          data: poolsCreatedArray,
          type: 'bar',
          smooth: true,
          showSymbol: false,
          color: '#0ACC77'
        },
        ]
      };

      useEffect(() => {
        console.log('data Changed');
        let pcreated = [];
        
        let tcat = [];
    
    
        for (let m of hourlyStats) {
          //tcat.push(String(m._id))
          pcreated.push(m.sumPoolCreated);
          let dt = new Date(Math.floor(m.minTime));
          console.log("Chart Interval: " + chartInterval);
          //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
          if (chartInterval === "hour"){
          tcat.push(
            dt.toLocaleString('en-us', {
              hour12: true,
    
              hour: 'numeric',
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })
        
          );
        } else {
            tcat.push(
                dt.toLocaleString('en-us', {
                  
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric'
                })
            
              );
        }
        }
    
        setCategory(tcat);
        setPoolsCreated(pcreated);
        
        
        
        
      }, [hourlyStats]);
      
      
      
      useEffect(() => {
        
        let data = {}
        let end = Math.floor(new Date().getTime())
        if (networkChartTitle === '24h') {
          
          let start = Math.floor(end - 86400000); // 24 Hours
          data = {
            "start": start,
            "end": end,
            "interval": "hour"
          }
          setChartInterval("hour")
    
        }
        if (networkChartTitle === '7d') {
          
          let start = Math.floor(end - 604800000); // 7 days
          data = {
            "start": start,
            "end": end,
            "interval": "day"
          }
          setChartInterval("day")
        }
        if (networkChartTitle === '30d') {
         
          let start = Math.floor(end - (2629743000) ); // 30.44 days 
          data = {
            "start": start,
            "end": end,
            "interval": "day"
          }
          setChartInterval("day")
        }
        if (networkChartTitle === '90d') {
          
          
          let start = Math.floor(end - (2629743000 * 3)  ); // 91.32 days
          data = {
            "start": start,
            "end": end,
            "interval": "day"
          }
          setChartInterval("day")
        }
        dispatch(getHourlyStats(data));
        dispatch(getBlockTime(data));
        
    
        
      }, []);
    
    
    
    
      const handleSelect = e => {
        console.log(e);
        setChartInterval(e);
        let data = {}
        let end = Math.floor(new Date().getTime())
        if (e === '24h') {
          
          let start = Math.floor(end - 86400000); // 24 Hours
          data = {
            "start": start,
            "end": end,
            "interval": "hour"
          }
          setChartInterval("hour")
        }
        if (e === '7d') {
          
          let start = Math.floor(end - 604800000); // 7 days
          data = {
            "start": start,
            "end": end,
            "interval": "day"
          }
          setChartInterval("day")
        }
        if (e === '30d') {
         
          let start = Math.floor(end - (2629743000) ); // 30.44 days 
          data = {
            "start": start,
            "end": end,
            "interval": "day"
          }
          setChartInterval("day")
        }
        if (e === '90d') {
         
          
          let start = Math.floor(end - (2629743000 * 3)  ); // 91.32 days
          data = {
            "start": start,
            "end": end,
            "interval": "day"
          }
          setChartInterval("day")
        }
        dispatch(getHourlyStats(data));
        
    
    
      };
return(
<section>
      <div className='row'>
        <div class="col-sm-1"></div>
        
        <h3 className='text-start col-10'> Pools </h3>
       
        <div className='col-1 d-flex justify-content-end'>
        
        <DropdownButton className='' onSelect={handleSelect} id="chartSelection" title={networkChartTitle} variant="dark">
          <Dropdown.Item eventKey="24h">24h</Dropdown.Item>
          <Dropdown.Item eventKey="7d">7d</Dropdown.Item>
          <Dropdown.Item eventKey="30d">30d</Dropdown.Item>
          <Dropdown.Item eventKey="90d">90d</Dropdown.Item>
        </DropdownButton>
        </div>
      </div>
      <div>
        {blockTimeIsLoading === true ? (
          <div class="position-absolute bottom-50 end-50">
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        ) : (
          ''
        )}
        <div>
          <ReactEChart option={eChartsOption} />
        </div>
       
      </div>
    </section> 
);

};

export default PoolsCreated;
