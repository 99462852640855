import React from 'react';
import { useSelector } from "react-redux";

import { Card,Table} from 'react-bootstrap';



const PhalaPoolList = () => {
  
  const { accountDetails } = useSelector((store) => store.phala);
  
  
   
  return (

    <section className='card bg-dark text-white'>
      <div className='card-header'>
        <h2>Pools</h2>
      </div>
      <table className='table table-dark table-striped table-hover'>
        <thead>
          <tr>
            <th scope='col'>PID</th>
            <th scope='col'>Total Stake</th>
            <th scope='col'>Free Stake</th>
            <th scope='col'>Cap</th>
          </tr>
        </thead>
        <tbody>
        {accountDetails.pools.map((tr) => (
         <tr key={tr._id}>
          <td>{tr._id}</td>
          <td>{Number(tr.totalStake).toLocaleString("en-US")}</td>
          <td>{Number(tr.freeStake).toLocaleString("en-US")}</td>
          <td>{Number(tr.cap).toLocaleString("en-US")}</td>    
         </tr>))} 
        </tbody>
      </table>
    </section>

  );
};


export default PhalaPoolList;
