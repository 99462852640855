
import {  useSelector } from "react-redux";


export function HasPrivilege(privilegeName) {
    console.log(privilegeName)
    const { privileges } = useSelector((store) => store.user.currentUser);
     let result = false;

     if (privileges.some(p=>p.privilege === privilegeName)){
        result = true;
     }

     if (privileges.some(p=>p.privilege === "superuser")){
        result = true;
     }
     console.log("hasPrivilege: " + result);
     return result

}
// gets privilige 
export function HasPrivilegeNoHook(privilegeName,privileges) {
   console.log(privilegeName)
   
    let result = false;

    if (privileges.some(p=>p.privilege === privilegeName)){
       result = true;
    }

    if (privileges.some(p=>p.privilege === "superuser")){
       result = true;
    }
    console.log("hasPrivilege: " + result);
    return result

}