import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rightMenuHide } from './phalaSlice';
import '../../App.css';
import { Container, Col, Row, Accordion, Card } from 'react-bootstrap';
import Header from '../../Header';
import Solo from './phalaDetailSolo'

import Worker from './phalaDetailWorker'
import Node from './phalaDetailNode'
import Prb from './phalaDetailPrb'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { doPhalaDetail } from '../modal/modalSlice';
import PhalaControl from './phalaControl';
import { Table } from 'react-bootstrap';

const PhalaDetail = () => {
  const { selPhala } = useSelector(store => store.phala);
  return (
    <div>
      <Header />
      <Col class="row text-light mt-3">
        <div class="col ms-3">
          {selPhala.monitorType == "solo"?(<Solo/>):""}
          {selPhala.monitorType == "prbworker"?(<Worker/>):""}
          {selPhala.monitorType == "prb"?(<Prb/>):""}
          {selPhala.monitorType == "phalanode"?(<Node/>):""}
          
        </div>
       
      </Col>
 
      
    </div>
  );
};

export default PhalaDetail;
