import React from "react";
import { BrowserRouter, BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./features/home/Home";
import HomePage from "./features/home/homePage";
import Login from "./features/user/Login";
import Logout from "./features/user/logout";
import Helium from "./features/helium/heliumlist";


import UserList from "./features/user/userList";
import EditUser from "./features/user/editUser";
import RegisterUser from "./features/user/registerUser";
import Phala from './features/phala/phalaList';
import WorkerLogDetail from "./features/phala/workerLogsDetail";
import Accounts from './features/phala/accountsHome';
import PhalaDetail from './features/phala/phalaDetail';
import Water from './features/water/waterList';
import AccountDetail from "./features/phala/org/accountDetail";
import MobileTest from "./features/test/mobileTest";
import TestSign from "./features/wallet/testsign";




import { useMediaQuery } from 'react-responsive'


function App() {
  
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })
  return (
    <BrowserRouter>
      <Routes>
          <Route path={"/"} element={<Home />} />
          <Route exact path={"/login"} element={<Login />} />
          <Route exact path={"/helium"} element={<Helium />} />
          <Route exact path={"/phala"} element={<Phala />} />
          <Route exact path={"/accounts"} element={<Accounts />} />
          <Route exact path={"/phaladetail"} element={<PhalaDetail />} />
          <Route exact path={"/logdetail"} element={<WorkerLogDetail />} />
          <Route exact path={"/water"} element={<Water />} />
          <Route exact path={"/users"} element={<UserList />} />
          <Route exact path={"/editUser"} element={<EditUser />} />
          <Route exact path={"/registerUser"} element={<RegisterUser />} />
          <Route exact path={"/logout"} element={<Logout />} />
          <Route exact path={"/phala/accountDetail"} element={<AccountDetail />} />
          <Route exact path={"/mobileTest"} element={<MobileTest />} />
          <Route exact path={"/testsign"} element={<TestSign />} />
          <Route exact path={"/homepage"} element={<HomePage />} />
          
        </Routes>
    </BrowserRouter>
  );
}

export default App;
