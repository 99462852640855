import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';



import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import "./index.css";

import App from './App'
import ModalForm from './features/modal/modal'
import { store } from "./app/store";
import { Provider } from "react-redux";

//import * as serviceWorker from "./serviceWorker";




ReactDOM.render(
  
   
      <Provider store={store}>
        <App />
        <ModalForm />
      </Provider>,
   
  
  document.getElementById("root")
);
