import React, { useEffect } from 'react';
import '../../App.css';
import { Card, Dropdown, DropdownButton } from 'react-bootstrap';


const NetworkSection = () => {
    
 
return (
    <>
    <h2 className='text-center mt-3'>Network Information</h2>
 <section className='row d-flex justify-content-center mb-2 mt-3'> 
 <Card className="col-5 bg-secondary text-center me-2 mb-2">
    <h6>Block Time</h6>
    <h4>Bye</h4>
 </Card>
 <Card className="col-5 bg-secondary text-center mb-2">
    <h6>Transactions</h6>
    <h4>Bye</h4>
 </Card>
 <Card className="col-5 bg-secondary text-center me-2 mb-2">
    <h6>Hi</h6>
    <h4>Bye</h4>
 </Card>
 <Card className="col-5 bg-secondary text-center mb-2">
    <h6>Hi</h6>
    <h4>Bye</h4>
 </Card>
 <Card className="col-5 bg-secondary text-center me-2 mb-2">
    <h6>Hi</h6>
    <h4>Bye</h4>
 </Card>
 <Card className="col-5 bg-dark border-0 mb-2">  
 </Card>
 </section>


</>
);
};
export default NetworkSection;