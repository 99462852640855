import React, { useEffect, useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import ReactEChart from 'echarts-for-react';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import {
  getResponsive,
  setNetworkChartTitle,
  getBlockTime
} from '../../phalaSlice';
import '../../../../App.css';

const ResponsiveByHour = () => {
  const dispatch = useDispatch();
  const [categoryArray, setCategory] = useState([]);
  const [responsiveArray, setResponsive] = useState([]);
  const [unresponsiveArray, setUnresponsive] = useState([]);

  const [chartInterval, setChartInterval] = useState('24h');

  //let miningArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,24,24];

  const { responsiveByHour, responsiveIsLoading, networkChartTitle } =
    useSelector(store => store.phala);

  const totalEnterResponsive = responsiveByHour.reduce(
    (acc, cur) => acc + cur.responsive,
    0
  );
  const totalEnterUnresponsive = responsiveByHour.reduce(
    (acc, cur) => acc + cur.unresponsive,
    0
  );
  const difResponse = totalEnterResponsive - totalEnterUnresponsive;

  const eChartsOption = {
    tooltip: {
      trigger: 'axis',
      position: 'top', // set the tooltip position to top
      offset: [0, 10], // set the vertical offset to 10 pixels
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
      borderColor: '#c8e2f7',
      textStyle: {
        color: '#000'
      }
    },
    toolbox: {
      feature: {},
      iconStyle: {
        borderColor: 'white'
      }
    },
    textStyle: {
      color: '#fff'
    },
    legend: {
      data: ['Responsive', 'Unresponsive'],
      top: '25',
      inactiveColor: '#555555',

      textStyle: {
        color: 'white'
      }
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },

    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          opacity: 0.08 // set the opacity of the line to 50%
        }
      },
      axisLabel: {
        opacity: 0.5 // set the opacity of the axis labels to 50%
      }
    },
    grid: [
      {
        left: '10%',
        right: '5%',
        height: '70%'
      }
    ],
    series: [
      {
        name: 'Responsive',
        data: responsiveArray,
        type: 'bar',
        smooth: true,
        showSymbol: false,
        color: '#4DBD74'
      },
      {
        name: 'Unresponsive',
        data: unresponsiveArray,
        type: 'bar',
        smooth: true,
        showSymbol: false,
        color: '#8B1C3D'
      }
    ]
  };
  useEffect(() => {
    console.log('data Changed');
    let tresponsive = [];
    let tunresponsive = [];

    let tcat = [];
    for (let m of responsiveByHour) {
      //tcat.push(String(m._id))
      tresponsive.push(m.responsive);
      tunresponsive.push(m.unresponsive);
      let dt = new Date(Math.floor(m.minTime));
      console.log(dt);
      //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
      if (chartInterval === 'hour') {
        tcat.push(
          dt.toLocaleString('en-us', {
            hour12: true,

            hour: 'numeric',
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      } else {
        tcat.push(
          dt.toLocaleString('en-us', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
        );
      }
    }

    setCategory(tcat);
    setResponsive(tresponsive);
    setUnresponsive(tunresponsive);
    console.log('responsive array');

    console.log(responsiveArray);
  }, [responsiveByHour]);

  useEffect(() => {
    console.log('getting responsive by hour (useEffect)');
    let data = {};
    let end = Math.floor(new Date().getTime());
    if (networkChartTitle === '24h') {
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('24h');
    }
    if (networkChartTitle === '7d') {
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('7d');
    }
    if (networkChartTitle === '30d') {
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('30d');
    }
    if (networkChartTitle === '90d') {
      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('90d');
    }
    dispatch(getResponsive(data));
  }, []);

  const handleSelect = e => {
    console.log(e);
    setChartInterval(e);
    let data = {};
    let end = Math.floor(new Date().getTime());
    if (e === '24h') {
      let start = Math.floor(end - 86400000); // 24 Hours
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('24h');
    }
    if (e === '7d') {
      let start = Math.floor(end - 604800000); // 7 days
      data = {
        start: start,
        end: end,
        interval: 'hour'
      };
      setChartInterval('7d');
    }
    if (e === '30d') {
      let start = Math.floor(end - 2629743000); // 30.44 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('30d');
    }
    if (e === '90d') {
      let start = Math.floor(end - 2629743000 * 3); // 91.32 days
      data = {
        start: start,
        end: end,
        interval: 'day'
      };
      setChartInterval('90d');
    }
    dispatch(getResponsive(data));
    dispatch(setNetworkChartTitle(e));
  };

  return (
    <section className="leftChartCard pb-0">
      <div className="row">
        <Tooltip
          title="Instances of workers entering responsive and unresponsive states."
          placement="top"
        >
          <h3 className="text-start col-10 ps-4">
            Worker Response
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              fill="currentColor"
              class="bi bi-info-circle mt-1 ms-1"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          </h3>
        </Tooltip>
        <div className="col-2 d-flex justify-content-end">
          <ButtonGroup
            className="timeframe-btn-group active btn btn-group-sm-mobile"
            aria-label="Timeframe Selection"
          >
            <Button
              variant="outline-complementary "
              onClick={() => handleSelect('24h')}
              className={chartInterval === '24h' ? 'active' : ''}
            >
              24h
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('7d')}
              className={chartInterval === '7d' ? 'active' : ''}
            >
              7d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('30d')}
              className={chartInterval === '30d' ? 'active' : ''}
            >
              30d
            </Button>
            <Button
              variant="outline-complementary"
              onClick={() => handleSelect('90d')}
              className={chartInterval === '90d' ? 'active' : ''}
            >
              90d
            </Button>
          </ButtonGroup>
        </div>
        <div>
          {responsiveIsLoading === true ? (
            <div class="position-absolute bottom-50 end-50">
              <div class="spinner-border text-warning" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          ) : (
            ''
          )}
          <div>
            <ReactEChart option={eChartsOption} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResponsiveByHour;
