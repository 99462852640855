import React, { useEffect, useState } from 'react';

import { Card, Dropdown, DropdownButton } from 'react-bootstrap';

import ReactEChart from 'echarts-for-react';
// Import charts, all with Chart suffix

import { useDispatch, useSelector } from 'react-redux';
import { getAprByStake } from '../phala/phalaSlice';
import { width } from '@mui/system';

const AprStakeAmountTest = () => {
  const dispatch = useDispatch();
  const [categoryArray, setCategory] = useState([]);
  const [AvgArray, setAvg] = useState([]);
  
  const [titleText, setTitle] = useState('Hour');
  const [chartInterval, setChartInterval] = useState('hour');

  //let miningArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,24,24];

  const { aprByStake,aprByStakeIsLoading } = useSelector(store => store.phala);

  const eChartsOption = {
    tooltip: {
      trigger: '',
      position: function (pt) {
        return [pt[0], '10%'];
      },
      backgroundColor: 'black',
      borderColor: '#c8e2f7',
      textStyle: {
        color: '#5d6f80'
      }
    },
    toolbox: {
      feature: {
        
      },
      iconStyle: {
        borderColor: 'white',
      }
    },

    dataZoom: [
      {
        type: 'slider',
        start: 0,
        end: 100,
        show: false
      },
      {
        start: 0,
        end: 100,
      }
    ],
  

    legend: {
      data: [''],
      top: '25',
      inactiveColor: '#555555',
      top:'auto',
      textStyle: {
        color: 'white'
      }
    },
    textStyle: {
      color: '#fff'
    },
    xAxis: {
      type: 'category',
      data: categoryArray,
      show: false,
      
    },

    yAxis: {
      type: 'value',
      show: false
    },
    series: [
      
      {
        name: 'Average',
        data: AvgArray,
        type: 'line',
        smooth: true,
        showSymbol: false
      },
    ],
    
  };
  useEffect(() => {
    console.log('data Changed');
    
    let avg = [];
    let tcat = [];
    for (let m of aprByStake) {
      
      avg.push(parseInt(m.averageApr));
     
      let dt = new Date(Math.floor(m.minTime));
      console.log(dt);
      //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
      if (chartInterval === "hour"){
        tcat.push(
          dt.toLocaleString('en-us', {
            hour12: true,
  
            hour: 'numeric',
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })
      
        );
      } else {
          tcat.push(
              dt.toLocaleString('en-us', {
                
                month: 'short',
                day: 'numeric',
                year: 'numeric'
              })
          
            );
      }
    }

    setCategory(tcat);
    
    setAvg(avg);
    
  }, [aprByStake]);
  useEffect(() => {
    let end = Math.floor(new Date().getTime())
    let start = Math.floor(end - 1209600000); // 2 weeks
      let data = {
        "start": start,
        "end": end,
        "interval": "hour"
      }
    dispatch(getAprByStake(data));
  }, []);




  const handleSelect = e => {
    console.log(e);
    setChartInterval(e);
    let data = {}
    let end = Math.floor(new Date().getTime())
    if (e === 'hour') {
      setTitle('Hour');
      let start = Math.floor(end - 1209600000); // 2 weeks
      data = {
        "start": start,
        "end": end,
        "interval": e
      }

    }
    if (e === 'day') {
      setTitle('Day');
      let start = Math.floor(end - 2629743000 *2 ); // 1 month
      data = {
        "start": start,
        "end": end,
        "interval": e
      }
      
    }
    if (e === 'week') {
      setTitle('Week');
      let start = Math.floor(end - (2629743000 * 6) ); // 6 months
      data = {
        "start": start,
        "end": end,
        "interval": e
      }
    }
    if (e === 'month') {
      setTitle('Month');
      
      let start = Math.floor(end - (2629743000 * 12)  ); // 6 months
      data = {
        "start": start,
        "end": end,
        "interval": e
      }
    }
    dispatch(getAprByStake(data));


  };

  return (
    <Card className="h-md-100 bg-dark text-center">
      <Card.Header className='row text-white'>
        
        <h3 className='col-9 col-sm-11' >APR - Stake Amount 
        
        </h3>
        <div className='postition-relative col-3 col-sm-1'>
        
        <DropdownButton className='position-absolute top-0 end-0 m-2' onSelect={handleSelect} id="chartSelection" title={titleText} variant="dark">
          <Dropdown.Item eventKey="hour" active>Hour</Dropdown.Item>
          <Dropdown.Item eventKey="day">Day</Dropdown.Item>
          <Dropdown.Item eventKey="week">Week</Dropdown.Item>
          <Dropdown.Item eventKey="month">Month</Dropdown.Item>
        </DropdownButton>
        </div>
        
      </Card.Header>
      <Card.Body>
        {aprByStakeIsLoading === true?
        (
          <div class="position-absolute bottom-50 end-50">
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
        ):("")}
        

        <div>
          <ReactEChart option={eChartsOption} />
        </div>
      </Card.Body>
    </Card>
    
  );
  
};

export default AprStakeAmountTest;
