import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../../App.css";


import { addOrgProfile,getOrgProfile } from "../phalaSlice";
import {closeModal} from "../../modal/modalSlice"




const AddNewOrgProfile = () => {
  const {currentUser } = useSelector((store) => store.user);
  
  const dispatch = useDispatch();
  const [name,setName] = useState("");
  
  
  
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const ph = {
      name: name,
      
    }
    console.log("Before dispatch");
    const data = {
        token: currentUser.token,
        profile: ph
    }
    dispatch(addOrgProfile(data));
   // dispatch(getOrgProfile(currentUser.token));
  

    console.log(data);
    dispatch(closeModal());
    
    
  };
  
  
  return (
    <div className="app-header">
    
      <form onSubmit={handleFormSubmit}>
        <table responsive>
          <tbody>
            <tr>
              <td>Org Name</td>
              <td>
                <input
                  value={name}
                  id="name"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                />
              </td>
            </tr>
            
            
            
          </tbody>
        </table>

        <button>Submit</button>
      </form>
    </div>
  );
};

export default AddNewOrgProfile;
