import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownButton} from 'react-bootstrap';
import ReactEChart from 'echarts-for-react';
import { getWorkerSettlement, setWorkertSettlement, setWorkerChartTitle } from '../phalaSlice';


const WorkerVscore = () => {
    const dispatch = useDispatch();
    const [categoryArray, setCategory] = useState([]);
    const [WorkerVscoreArray, setWorkerVscore] = useState([]);
    const [titleText, setTitle] = useState('7d');
    const [chartInterval, setChartInterval] = useState('hour');

    const { workerSettlements, workerChartTitle } = useSelector(store => store.phala);
    const eChartsOption = {
        tooltip: {
          trigger: 'axis',
          position: 'bottom', // set the tooltip position to top
          offset: [0, 10], // set the vertical offset to 10 pixels
          backgroundColor: 'rgba(255, 255, 255, 0.4)',
          borderColor: '#c8e2f7',
          textStyle: {
            color: '#000'
        }
        },
        toolbox: {
          feature: {
            
          },
          iconStyle: {
            borderColor: 'white',
          }
        },
    
        legend: {
          data: ['apr'],
          top: '25',
          inactiveColor: '#555555',
          
          textStyle: {
            color: 'white'
          }
        },
        textStyle: {
          color: '#fff'
        },
        xAxis: {
          type: 'category',
          data: categoryArray,
          axisLabel: {
            opacity: 0.5  // set the opacity of the axis labels to 50%
          },
        },
    
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
                opacity: 0.08  // set the opacity of the line to 50%
            }
        },
          axisLabel: {
                opacity: 0.5  // set the opacity of the axis labels to 50%
        },
        },
        grid: [
          {
            left: '10%',
            right: '5%',
            height: '70%'
          }
        ],
        series: [
          {
            name: 'Worker APR',
            data: WorkerVscoreArray,
            type: 'line',
            smooth: false,
            showSymbol: false,
            color: '#3E1FCC'
          },
          
        
        ]
    };
        useEffect(() => {
            console.log('data Changed');
            let workerVscore = [];
            let tcat = [];
            for (let m of workerSettlements) {
              workerVscore.push(parseInt(m.vscore));
             
             
              let dt = new Date(Math.floor(m.timestamp));
              console.log(dt);
              //tcat.push(dt.month.toString() + "/" + dt.day.toString() + " " + dt.hour.toString() + ":00")
              if (chartInterval === "hour"){
                tcat.push(
                  dt.toLocaleString('en-us', {
                    hour12: true,
          
                    hour: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                  })
              
                );
              } else {
                  tcat.push(
                      dt.toLocaleString('en-us', {
                        
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      })
                  
                    );
              }
            }
        
            setCategory(tcat);
            setWorkerVscore(workerVscore);
            
          }, [workerSettlements]);
          useEffect(() => {
            let end = Math.floor(new Date().getTime())
            let start = Math.floor(end - 604800000); // 1 week
            let data = {}
            if (workerChartTitle === '24h') {
              
              let start = Math.floor(end - 86400000); // 24 Hours
              data = {
                "start": start,
                "end": end,
                "interval": "hour"
              }
        
            }
            if (workerChartTitle === '7d') {
              
              let start = Math.floor(end - 604800000); // 7 days
              data = {
                "start": start,
                "end": end,
                "interval": "hour"
              }
              
            }
            if (workerChartTitle === '30d') {
             
              let start = Math.floor(end - (2629743000) ); // 30.44 days 
              data = {
                "start": start,
                "end": end,
                "interval": "hour"
              }
            }
            if (workerChartTitle === '90d') {
              
              
              let start = Math.floor(end - (2629743000 * 3)  ); // 91.32 days
              data = {
                "start": start,
                "end": end,
                "interval": "day"
              }
            }
            dispatch(getWorkerSettlement(data));
          }, []);
        
        
        
        
          const handleSelect = e => {
            console.log(e);
            setChartInterval(e);
            let data = {}
            let end = Math.floor(new Date().getTime())
            if (e === '24h') {
              setTitle('24h');
              let start = Math.floor(end - 86400000); // 24 Hours
              data = {
                "start": start,
                "end": end,
                "interval": "hour"
              }
              setChartInterval("hour")
            }
            if (e === '7d') {
              setTitle('7d');
              let start = Math.floor(end - 604800000); // 7 days
              data = {
                "start": start,
                "end": end,
                "interval": "hour"
              }
              setChartInterval("hour")
            }
            if (e === '30d') {
              setTitle('30d');
              let start = Math.floor(end - (2629743000) ); // 30.44 days 
              data = {
                "start": start,
                "end": end,
                "interval": "hour"
              }
              setChartInterval("hour")
            }
            if (e === '90d') {
              setTitle('90d');
              
              let start = Math.floor(end - (2629743000 * 3)  ); // 91.32 days
              data = {
                "start": start,
                "end": end,
                "interval": "day"
              }
              setChartInterval("day")
            }
            dispatch(getWorkerSettlement(data));
        
            dispatch(setWorkerChartTitle(e))
      };
  return (
   <>
   <section>
   <DropdownButton onSelect={handleSelect} id="chartSelection" title={workerChartTitle} variant="dark">
          <Dropdown.Item eventKey="24h">24h</Dropdown.Item>
          <Dropdown.Item eventKey="7d">7d</Dropdown.Item>
          <Dropdown.Item eventKey="30d">30d</Dropdown.Item>
          <Dropdown.Item eventKey="90d">90d</Dropdown.Item>
        </DropdownButton>
   <div class="mt-3">
          <ReactEChart option={eChartsOption} />
        </div>
  </section>
   </>
  );
};

export default WorkerVscore;
