import React, { useState, useEffect } from 'react';
import { setAccountCharts } from '../phalaSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Form, Button, Alert, Col, Container } from 'react-bootstrap';
import { subtract, multiply, divide, sum, sqrt } from 'mathjs';

const ProjectedRewards = () => {
    const dispatch = useDispatch();
    const { orgProfile } = useSelector((store) => store.phala);
    const [targetTotal, setTargetTotal] = useState('');
    const [timeToTarget, setTimeToTarget] = useState('');

    const accountTotal = orgProfile.accounts.reduce((acc, cur) => acc + cur.delegated + cur.free, 0);
    
    useEffect(() => {
        // Check if orgProfile and orgProfile.apr24 exist
        if (!orgProfile || !orgProfile.aprs) {
            return;
        }

        console.log('orgProfile.apr24:', orgProfile.apr24); // debug
        console.log('Type of orgProfile.apr24:', typeof orgProfile.apr24); // debug

        // Calculate the average APR
        orgProfile.avgAPR = sum(orgProfile.apr24.map(apr => apr.apr)) / orgProfile.apr24.length;

        dispatch(setAccountCharts(orgProfile));
    }, [dispatch, orgProfile]);

    const handleSubmit = (event) => {
        event.preventDefault();

        // calculate the daily rewards based on the average APR
        const dailyRewards = (orgProfile.avgAPR / 100 / 365) * accountTotal;

        // calculate the time it takes to reach the target total
        const time = (targetTotal - accountTotal) / dailyRewards;

        setTimeToTarget(time);
    };

    return (
      <Container>
          <Row>
              <Col>
                  <h1>Projected Rewards</h1>
                  <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="targetTotal">
                          <Form.Label>Target Total</Form.Label>
                          <Form.Control 
                              type="number" 
                              value={targetTotal} 
                              onChange={(e) => setTargetTotal(e.target.value)}
                          />
                      </Form.Group>
                      <Button variant="primary" type="submit">
                          Calculate
                      </Button>
                  </Form>
                  {timeToTarget && 
                      <Alert variant="success">
                          It will take approximately {timeToTarget} days to reach your target total.
                      </Alert>
                  }
              </Col>
          </Row>
      </Container>
  );
};

export default ProjectedRewards;